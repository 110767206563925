import { historyType, historyItemsType } from '../../constants/Types';

const GET_DEPLOY_HISTORY = 'deploy/GET_HISTORY' as const;

/**
 * 액션
 * @param deploy
 * */

export const getDeployHistory = (deploy: historyType) => ({
  type: GET_DEPLOY_HISTORY,
  payload: deploy,
});

interface DeployHistoryProps {
  lists: historyItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
};

type DeployHistoryAction = ReturnType<typeof getDeployHistory>;

function DeployHistory(state: DeployHistoryProps = initialState, action: DeployHistoryAction) {
  switch (action.type) {
    case GET_DEPLOY_HISTORY: {
      return {
        ...state,
        lists: action.payload.items.map((item: historyItemsType) => {
          return {
            _id: item._id,
            bot: item.bot ? item.bot : { _id: null, name: null, state: null },
            member: item.member ? item.member : { _id: null, email: null, name: null },
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            version: item.version,
            isCurrentVersion: item.isCurrentVersion,
            isBackup: item.isBackup,
            description: item.description,
            scenarioId: item.scenarioId,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default DeployHistory;
