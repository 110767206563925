import { workHistoryType, workHistoryItemsType } from 'constants/Types';

const GET_WORK_HISTORY = 'work/GET_HISTORY' as const;

/**
 * 액션
 * @param work
 * */

export const getWorkHistory = (work: workHistoryType) => ({
  type: GET_WORK_HISTORY,
  payload: work,
});

interface WorkistoryProps {
  lists: workHistoryItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
};

type WorkHistoryAction = ReturnType<typeof getWorkHistory>;

function WorkHistory(state: WorkistoryProps = initialState, action: WorkHistoryAction) {
  switch (action.type) {
    case GET_WORK_HISTORY: {
      return {
        ...state,
        lists: action.payload.items
          .filter((l: workHistoryItemsType) => 'action' in l.action && 'group' in l.action)
          .map((item: workHistoryItemsType) => {
            return {
              _id: item._id,
              memberId: item.memberId,
              ouput: item.output,
              action: item.action.action,
              group: item.action.group,
              request: item.request,
              useragent: item.useragent,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
            };
          }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default WorkHistory;
