import Http from '../modules/Http';

/**
 * SecondSecurity QRCODE
 */
export const qrCodeImage = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.get(`${process.env.REACT_APP_API_PATH}/2fa/qrcode`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    //console.log('result qrcode', result.data, result.data.data);
    return Promise.resolve(result.data.data);
  } catch (e) {
    console.log('Error, services/SecurityService.ts', e);
    return Promise.reject(e);
  }
};

/**
 * SecondSecurity QRCODE
 */
export const checkQRCode = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/2fa/checkcode`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    //console.log('result checkcode', result.data, result.data.data);
    return Promise.resolve(result.data.data);
  } catch (e) {
    console.log('Error, services/checkQRCode.ts', e);
    return Promise.reject(e);
  }
};

export const setQRCode = async (item: any): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/2fa/set2fa`, item);
    if (result.data.status !== 'success') return Promise.reject(result);
    //console.log('result checkcode', result.data, result.data.data);
    return Promise.resolve(result.data.data);
  } catch (e) {
    console.log('Error, services/checkQRCode.ts', e);
    return Promise.reject(e);
  }
};

/**
 * SecondSecurity disable
 */
export const disableQRCode = async (): Promise<any> => {
  try {
    const result: any = await Http.post(`${process.env.REACT_APP_API_PATH}/2fa/disableCode`);
    if (result.data.status !== 'success') return Promise.reject(result);
    return Promise.resolve(result.data.data);
  } catch (e) {
    console.log('Error, services/disableQRCode.ts', e);
    return Promise.reject(e);
  }
};
