/**
 * 자주 사용되는 타입 모음
 */
import { BaseModelOptions } from '@projectstorm/react-canvas-core';

/***************************************************************
 * 템플릿
 ***************************************************************/

/**
 * 템플릿 데이터 타입
 * TODO : 변경 예정
 */
export interface TemplateProps {
  /** 템플릿 아이디 */
  _id: string;
  /** 템플릿 네임 */
  name: string;
  categoryId: number;
  categoryName: string;
  poster: any;
  price: number;
}

/**************************************************************
 * 봇
 ***************************************************************/

/**
 * 봇 데이터 타입
 * TODO : 변경 예정
 */
export interface BotProps {
  /** 봇 아이디 */
  _id: string;
  /** 봇 네임 */
  name: string;
  /** 봇 사용유무 */
  use: boolean;
  provider?: string;
  state: string;
  isTemplate?: boolean;
}

/**
 * 봇 EditorData
 * 변경 예정
 */
export interface BotEditorDataProps {
  /** 시나리오 소팅 */
  scenarioSort: string[];
}

/***************************************************************
 * 시나리오
 ***************************************************************/

/**
 * 블럭 생성 카운트 기본값
 */
export const ItemNameCnts = {
  DEFAULT_TEXT: 0,
  DEFAULT_IMAGE: 0,
  DEFAULT_IMAGE_CARD: 0,
  DEFAULT_IMAGE_CARD_SQUARE: 0,
  DEFAULT_IMAGE_COMMERCE: 0,
  DEFAULT_IMAGE_COMMERCE_SQUARE: 0,
  DEFAULT_LIST: 0,
  DEFAULT_TEXT_API: 0,
  DEFAULT_TEXT_AUTH_API: 0,
  DEFAULT_SMS_AUTH_API: 0,
  DEFAULT_ADD_CHANNEL: 0,
  DEFAULT_POST_APPLY: 0,
  DEFAULT_ROOM_INFO_SEND: 0,
  DEFAULT_ROOM_INFO_GET: 0,
  CAROUSEL_TEXT: 0,
  CAROUSEL_IMAGE_CARD: 0,
  CAROUSEL_IMAGE_CARD_SQUARE: 0,
  CAROUSEL_IMAGE_COMMERCE: 0,
  CAROUSEL_IMAGE_COMMERCE_SQUARE: 0,
  CAROUSEL_LIST: 0,
  BUTTON_LINK: 0,
  BUTTON_LIST: 0,
  BUTTON_CONDITION: 0,
  BUTTON_VARIABLES_INIT: 0,
  BUTTON_VARIABLES_MATCH: 0,
  FUNC_RATING: 0,
  FUNC_WEB_CERTIFICATION: 0,
  FUNC_SMS_CERTIFICATION: 0,
  FUNC_KAKAO_SYNC: 0,
  FUNC_API_WEATHER: 0,
  FUNC_API_MAP: 0,
  FUNC_ONEDIR: 0,
  //상담콜
  DEFAULT_CALL_TEXT: 0,
  DEFAULT_CALL_TEXT_UR: 0,
  DEFAULT_CALL_SEND_MSG: 0,
  DEFAULT_CALL_CONNECT: 0,
  DEFAULT_CALL_RESERVATION: 0,
  DEFAULT_CALL_OPTION: 0,
  BUTTON_MULTI_CONDITION: 0,
  BUTTON_WORK_CONDITION: 0,
  BUTTON_TASK_CONDITION: 0,
  DEFAULT_CALL_END: 0,
  /** 외부 연동 API */
  DEFAULT_INTEGRATION_API: 0,
  DEFAULT_KAKAO_PROFILE_AUTH: 0,
};

/**
 * 시나리오 > ediforData > startBlock 데이터 타입
 */
export interface ScenarioStartBlockProps {
  /** 시작대화 대상 블럭 아이디 */
  startBlockId: string | undefined;
  /** x 값 */
  x: number;
  /** y 값 */
  y: number;
}

/**
 * 시나리오 > ediforData > startBlock 데이터 초기값
 */
export const ScenarioStartBlockOptions: ScenarioStartBlockProps = {
  /** 시작대화 대상 블럭 아이디 */
  startBlockId: '',
  /** x 값 */
  x: 150,
  /** y 값 */
  y: 150,
};

/**
 * 시나리오 editorData 데이터 타입
 */
export interface ScenarioEditorDataProps {
  /** 시나리오 설명 */
  desc?: string | undefined;
  /**시나리오 블럭별 추가 카운트 */
  item_name_cnts: any | undefined;
  /** 시작대화 정보 */
  startBlock: ScenarioStartBlockProps | undefined;
  /** 모듈 시나리오인 경우 모듈정보 */
  // module: ScenarioModuleDataProps | undefined;
}

/**
 * 시나리오 editorData 데이터 초기값
 */
export const ScenarioEditorDataOptions: ScenarioEditorDataProps = {
  /** 시나리오 설명 */
  // desc: '',
  /** 시나리오 블럭별 생성 카운트 */
  item_name_cnts: ItemNameCnts,
  /** 시작대화 정보 */
  startBlock: ScenarioStartBlockOptions,
};

/**
 * 시나리오 데이터 타입
 */
export interface ScenarioProps {
  /** 시나리오 서버 아이디 */
  _id?: string;
  /** 시나리오명 */
  name: string;
  /** 봇 아이디 */
  botId: string;
  /** 시나리오 상태 */
  state: string;
  /** 사용/미사용 */
  use: boolean;
  /** 시나리오 에디터 데이터 */
  editorData: ScenarioEditorDataProps | any;
  /** 시나리오 타입 */
  scenarioType: 'default' | 'welcome' | 'failed' | 'home' | 'module' | 'flowEnd';
  /** 템플릿 타입 */
  templateType?: string;

  flowEndScenarioId?: string;
}

/**
 * 시나리오 추가 및 업데이트시 데이터 타입
 * 변경 예정
 */
export interface editScenarioDataProps {
  /** 업데이트할 시나리오 아이디, 아이디 값이 없다면 신규 생성함 */
  editId?: string;
  /** 팝업 타이틀 */
  editTitle: string;
  /** 시나리오명 */
  name?: string;
  /** 시나리오 설명 */
  desc?: string;
  /** 시나리오 태그 */
  tags?: [];
  /** 폼 검증 에러 */
  isErrors?: {};
  /** 시나리오 타입 */
  scenarioType?: 'default' | 'welcome' | 'failed' | 'home' | 'module';
  /** 템플릿 타입 */
  templateType?: string;
  /**상담원 분류 */
  conunselor?: {
    use: boolean;
    major: string;
    middle: string;
  };
  /**콜백 시나리오 */
  flowEndScenarioId?: string;
}

/**
 * 시나리오 추가 및 업데이트시 데이터 초기값
 * 변경 예정
 */
export const editScenarioDataOptions: editScenarioDataProps = {
  /** 업데이트할 시나리오 아이디 */
  editId: '',
  /** 팝업 타이틀 */
  editTitle: '시나리오 생성',
  /** 시나리오명 */
  name: '',
  /** 시나리오 설명 */
  desc: '',
  /** 시나리오 태그 */
  tags: [],
  /** 폼 검증 에러 */
  isErrors: {},
  /** 시나리오 타입 */
  scenarioType: 'default',
};

/**
 * 시나리오 데이터 만들기 데이터 타입
 * 변경 예정
 */
export interface createScenarioDataProps {
  /** 봇 아이디 */
  botId: string;
  /** 시나리오명 */
  name: string;
  /** 시나리오 설명 */
  desc: string;
  /** 시나리오 태그 */
  tags: any;
  /** 시나리오 타입 */
  scenarioType: 'default' | 'welcome' | 'failed' | 'home' | 'module';
  /** 템플릿 타입 */
  templateType?: string;
  /** 템플릿 시나리오 아이디 */
  templateScenarioId?: string;
  /**상담원 분류 */
  counselor?: {
    major: string;
    middle: string;
  };
  /**콜백 시나리오 */
  flowEndScenarioId?: string;
}

/**
 * 모듈 히스토리 데이터 타입
 */
export interface HistoryProps {
  /** 모듈명 */
  name: string;
  /** 봇 아이디 */
  botId: string;
  /** 시나리오 아이디 */
  scenarioId: string;

  blockType: string;
}

/***************************************************************
 * 블럭
 ***************************************************************/

/**
 * 블럭 스키마 타입
 */
export interface BlockSchemaOptions {
  /** 액션 네임 */
  action_name: string;
  /** 아이템 네임 */
  item_name: string;
  /** 봇 텍스트 사용 여부 */
  bot_text: boolean;
  /** 봇 미디어 사용 여부 */
  bot_media: boolean;
  /** 정사각 봇 미디어 여부 */
  bot_media_square: boolean;
  /** 버튼 유무 */
  bot_button?: boolean;
  /** 하단 버튼 사용 여부 */
  bot_button_bottom: boolean;
  /** 블럭 생성시 기본으로 추가할 버튼 갯수 */
  bot_button_inst: number;
  /** 봇 버튼 최대 갯수 */
  bot_button_max: number;
  /** 봇 버튼 수정 가능 */
  bot_button_edit_fixed: boolean;
  /** 카드형 블럭 */
  card: boolean;
  /** 캐로셀 블럭 */
  carousel: boolean;
  /** 커머스 블럭 */
  commerce: boolean;
  /** 리스트형 블럭 */
  list: boolean;
  /** 리스트형 블럭 최대 갯수(ms) */
  list_max: number;
  /** 모듈형 블럭 */
  func: boolean;
  /** 고객 답변 여부 */
  user_response: boolean;
  /** 시나리오 리스형 블럭 */
  isAutoScenarioList: boolean;
  /**  핫키 사용여부 */
  isHotKey: boolean;
  /** 연속연결 사용여부 */
  useSubBlock: boolean;
  /** api 사용여부 */
  api: boolean;
  /** 조건블럭 여부 */
  condition: boolean;
  /** 다중조건블럭 여부 */
  multi_condition: boolean; //다중조건
  /** 다중조건블럭 여부 */
  multi_condition_max: number;
  /** 단방향 Next */
  oneDirIsNext?: boolean;
  oneDirType?: string;

  /** 설문지 폼 바로연결 */
  isEndForm?: boolean;

  /** 변수설정 블럭 */
  variablesInit?: boolean;
  variablesMatch?: boolean;

  /** 음성파일 사용 유무 */
  tts?: boolean;
}

/**
 * 봇 미디어 타입
 */
export interface BotContentTypes {
  /** 미디어 주소 */
  image: string | undefined;
  /** 링크 */
  link: string | undefined;
  /** 미디어 타이틀 */
  title: string | undefined;
  /** 미디어 메시지 */
  message: string | undefined;
  /** 프로필 이미지 */
  profile_image: string | undefined;
  /** 프로필 네임 */
  profile_name: string | undefined;
  /** 상품 가격 */
  product_price: string | undefined;
  /** 상품 네임 */
  product_name: string | undefined;
  /** 리스트 타이틀 배경 이미지 */
  //list_image: string | undefined;
  /** 리스트 타이틀 */
  list_title: string | undefined;
  /** 리스트 링크 */
  list_link: string | undefined;

  /**할인 적용을 선택했는지 유무입니다. */
  isDiscount?: boolean;
  /**할인 적용에 대한 선택값입니다.rate(할인율), amount(금액) */
  discountOption?: 'rate' | 'amount';
  /** 제품의 가격에 대한 할인할 금액입니다.	 */
  discount?: string | number | 0;
  /** 제품의 가격에 대한 할인율입니다.	  */
  discountRate?: string | number | 0;
}

/**
 * 유저 초이스 데이터 타입
 * type 이 next_scenario 인경우 => next 에 다음 시나리오의 startBlockId 가 들어감
 * type 이 next_block 인경우 => next 에 다음 블럭 아이디가 들어감
 */
export interface UserChoiceDataOptions {
  // _id: string; // 버튼 아이디
  active: boolean;
  /** 버튼 타입 */
  type: string;
  /** 버튼명 */
  name: string;
  /** 다음대화 */
  next: string;
  /** 미디어 주소 */
  url: string;
  /** 폰 주소 */
  phone: number;
  /** 연결할 시나리오 아이디 */
  next_scenario_id: string;
  /** 버튼 생성자 */
  button_owner: string;
  /** 카카오 오픈빌더 블럭 아이디 */
  kakaoOpenBlockId?: string;

  /** external 문구 */
  externalText?: string;
  /** 버튼 파라미터 배열 */
  btnParams?: { [key: string]: string }[];

  /** 조건버튼 타입 */
  argument?: string;
  conditionKey?: string;
  inequality?: string;
  success?: UserChoiceDataOptions;
  fail?: UserChoiceDataOptions;
  conditionType?: 'success' | 'fail';
  isNonDisplay?: boolean;
  kakaoResponseParam?: string;
  kakaoResponseType?: string;

  counselor?: {
    major?: string;
    middle?: string;
    /**상담 파라미터 */
    custom?: { [key: string]: string }[];
  };
  /**URL 쿼리 파라미터 */
  urlParams?: { [key: string]: string }[];
  /**상담콜 dtmf*/
  dtmf?: string;
  ai?: {
    label?: string;
    desc?: string;
  };
}

/**
 * 모듈 데이터 타입
 */
export interface ModuleDataOptions {
  /** 봇 아이디 */
  // botId: string;
  /** 시나리오 아이디 */
  scenarioId: string;
  /** 모듈 종료시 연결할 블럭 아이디 */
  nextBlockId: string;

  isEdit: boolean;
}

/**
 * 모듈 데이터 타입
 */
export interface ApiDataOptions {
  /** Method */
  method: 'GET' | 'POST';
  /** field name */
  itemFieldName: string;
  /** url */
  apiUrl: string;
  /** api 타입 */
  type: string;
  /** parameter */
  parameter: { key: string; value: string; type: string }[] | undefined;
  /** Hearder */
  header?: { key: string; value: string }[] | undefined;
  /** Auth Mapping */
  mapping?: { key: string; value: string }[] | undefined;
  /** auth use */
  isAuth?: boolean;
  /** session use */
  isKeepSession?: boolean;
  /** XML info */
  isXML?: boolean;
  parent?: string;

  isNonBlocking?: boolean;
}

export interface AuthDataOptions {
  provider: string;
  isKeepSession: boolean;
  authUrl: string;
  isQuickreplice?: boolean;
}

export interface ConditionOptions {
  key: string;
  inequality: string;
  argument: string;
}

export interface AddressDataOptions {
  address: string;
  address2: string;
  zoneCode: string;
}

export interface CounselorData {
  use: boolean;
  major: string;
  middle: string;
}

export interface FormDataOptions {
  title: string;
  desc: string;
  isSingleRes: boolean;
  isHappytalkSend: boolean;
  validTime: {
    start?: Date;
    end?: Date;
  };
  profileId?: {
    use?: boolean;
    value?: string;
  };
}

/**
 * 리스트형 블럭 아이템 타입
 */
export interface ListBlockIdsOptions {
  /** 타이틀 */
  title: string;
  /** 설명 */
  description: string;
  /** 이미지 url */
  image: string;
  /** 링크 */
  link: string;
  /** 활성상태 */
  active: boolean;

  imgUrlCustom?: boolean;
}

/**
 * 리스트형 블럭 아이템 타입 초기값
 */
export const initialListBlockIdsOptions: ListBlockIdsOptions = {
  /** 타이틀 */
  title: '',
  /** 설명 */
  description: '',
  /** 이미지 url */
  image: '',
  /** 링크 */
  link: '',
  /** 활성상태 */
  active: true,
  /** 이미지 URL 토글 */
  imgUrlCustom: true,
};

/**
 * 고객 응답설정 타입
 */
export interface UserResponseOptions {
  /** 연결할 블럭 아이디 */
  next: string;
  parameter: string;
  askMessage: string;
  askImg: string;
  customAskImg: boolean;
  isRegexValidate: boolean;
  regex: string;
  retryAskCount: number;
  isInputImg?: boolean;
  retryAskType: string;
}

/**
 *  변수 설정 블럭 타입
 */
export interface VariablesOptions {
  nextBlockId: string;
  matchList?: { [k in string]: string }[];
  initalExceptList?: string[];
}

export interface CallConnectTypes {
  incommingCode?: string; //상담사번호
  counselorAssign?: {
    counselorType?: string; //N: 최소 상담시간, W:최대 대기시간
    prevCounselor?: boolean;
    dateRange?: string;
  };
}

export interface CallMsgTypes {
  type?: string;
  sms?: {
    type?: string;
    title?: string;
    content?: string;
  };
  alimtalk?: {
    code?: string;
    content?: string;
  };
}

export interface VoiceType {
  speaker?: string;
  speed?: string;
  pitch?: string;
}

export interface TTSType {
  ttsPath?: string;
  messageContent?: string;
  voiceType?: VoiceType;
  updatedAt?: string;
  isTextPlay?: boolean;
}
export interface ArTypes {
  isFlowEnd: boolean;
  isCallEnd: boolean;
  isVoiceSkip?: boolean; // 음성건너뛰기
  tts: TTSType;
  message?: CallMsgTypes;
  callConnect: CallConnectTypes;
  callReservation?: {};
  input?: {
    type?: string;
    endKey?: string;
    inputLength?: number;
  };
  inputVoice: {
    parameter: string;
  };
  option: {
    isCustom: boolean;
    inputWaitingTime: string;
    connectionWaitingTime: string;
    countConnectionAttempts: string;
  };
}

/**
 * 불록 > editorData > blockData 데이터 타입
 */
export interface BlockDataOptions {
  /** 봇 텍스트 */
  // bot_text: string;
  /** 봇 메시지 컨텐츠 */
  messageContent: BotContentTypes;
  /** 고객 선택 버튼 */
  user_choice: UserChoiceDataOptions[];
  /** 리스트 */
  list: ListBlockIdsOptions[];
  /** 고객 응답 */
  user_response: UserResponseOptions;
  /** 데이터 생성오너 */
  data_owner?: string;
  /** 데이터 사용유무(시스템 블럭에서 사용) */
  useBlock?: boolean;
  /** 이미지 URL 토글여부 */
  imgUrlCustom?: boolean;
  /** 버튼 사용 여부 토글 */
  useButton?: boolean;
}

/**
 * 불록 > editorData 데이터 타입
 */
export interface BlockEditorDataOptions {
  /** 좌표 */
  x: any | undefined;
  /** 좌표 */
  y: any | undefined;
  /** 블럭 최소화 여부 */
  isMinimize: boolean | undefined;
  /** 블럭 스키마 */
  blockSchema: BlockSchemaOptions;
  /** 블럭 데이터 */
  blockData: BlockDataOptions[];
  /** 모듈 데이터 */
  moduleData: ModuleDataOptions;
  /** 연속연결 부모 블럭 */
  parentBlockId: string | undefined;
  /** 연속블럭 */
  subBlockId: string | undefined;
  /** api data */
  outApiData: ApiDataOptions;

  authData: AuthDataOptions;

  conditionData: ConditionOptions;
  multiConditionData: ConditionOptions[];

  addressData: AddressDataOptions;

  formData?: FormDataOptions;

  counselorData?: CounselorData;

  variablesData: VariablesOptions;
  /**상담콜 데이터 */
  arData?: ArTypes;
  /** 외부 앱 연동 API */
  integrationApiData?: IntegrationAPIDataType[];
}

/**
 * 블럭 모델 타입
 */
export interface DefaultBlockModelOptions extends BaseModelOptions {
  /** 블럭 서버 아이디 */
  _id: string;
  /** 블럭명 */
  name: string;
  /** 블럭 타입 */
  blockType: string;
  /** block Active index */
  // activeDataIdx: number;
  /** 봇 아이디 */
  botId: string;
  /** 시나리오 아이디 */
  scenarioId: string;
  /** 부모 시나리오 아이디 */
  parentScenarioId?: string;
  /** 에디터 데이터 */
  editorData: BlockEditorDataOptions;
}

export interface ValidatorBlockModel extends DefaultBlockModelOptions {
  memberId: string;
  createdAt: string;
  updatedAt: string;
}

export interface ValidatorScenarioModel {
  totalCount: number;
  items: ValidatorBlockModel[];
  itemCount: number;
}
/** 시나리오 검증 시 사용되는 시나리오 내 블럭 타입 */
export interface BlockValidationTypes extends Omit<DefaultBlockModelOptions, 'parentScenarioId'> {
  createdAt: string;
  memberId: string;
  updatedAt: string;
}

/**
 * BlockData 초기값
 */
export const initialBlockDataOptions: BlockDataOptions = {
  /** 봇 컨텐츠 */
  messageContent: {
    /** 미디어 url */
    image: '',
    /** 링크 */
    link: '',
    /** 타이틀 */
    title: '',
    /** 메시지 */
    message: '',
    /** 프로필 이미지 url */
    profile_image: '',
    /** 프로필명 */
    profile_name: '',
    /** 상품가격 */
    product_price: '',
    /** 상품명 */
    product_name: '',
    /** 리스트 타이틀 */
    list_title: '',
    /** 리스트 썸네일 이미지 url */
    //list_image: '',
    /** 리스트 링크 */
    list_link: '',
  },
  /** 리스트 */
  list: [],
  /** 초이스 버튼 */
  user_choice: [],
  /** 고객 응답 */
  user_response: {
    /** 다음 블럭 아이디 */
    next: '',
    parameter: '',
    askMessage: '',
    askImg: '',
    customAskImg: false,
    regex: '^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$',
    isRegexValidate: false,
    retryAskCount: 3,
    retryAskType: 'all',
  },
  data_owner: 'user',
  imgUrlCustom: true,
  useButton: true,
};

/**
 * API BlockData 초기값
 */
export const initialAPIBlockDataOptions: BlockDataOptions = {
  /** 봇 컨텐츠 */
  messageContent: {
    /** 미디어 url */
    image: '',
    /** 링크 */
    link: '',
    /** 타이틀 */
    title: '',
    /** 메시지 */
    message: 'API 정보를 입력해 주세요',
    /** 프로필 이미지 url */
    profile_image: '',
    /** 프로필명 */
    profile_name: '',
    /** 상품가격 */
    product_price: '',
    /** 상품명 */
    product_name: '',
    /** 리스트 타이틀 */
    list_title: '',
    /** 리스트 썸네일 이미지 url */
    //list_image: '',
    /** 리스트 링크 */
    list_link: '',
  },
  /** 리스트 */
  list: [],
  /** 초이스 버튼 */
  user_choice: [],
  /** 고객 응답 */
  user_response: {
    /** 다음 블럭 아이디 */
    next: '',
    parameter: '',
    askMessage: '',
    askImg: '',
    customAskImg: false,
    isRegexValidate: false,
    regex: '',
    retryAskCount: 0,
    retryAskType: 'all',
  },
  data_owner: 'user',
  useButton: true,
};

/**
 * ModuleData 초기값
 */
export const initialModuleDataOptions: ModuleDataOptions = {
  /** 봇 아이디 */
  // botId: '',
  /** 시나리오 아이디 */
  scenarioId: '',
  /** 모듈 종료시 연결할 블럭 아이디 */
  nextBlockId: '',

  isEdit: false,
};

/**
 * ModuleData 초기값
 */
export const initialApiDataOptions: ApiDataOptions = {
  /** Method */
  method: 'GET',
  /** Fieldname */
  itemFieldName: '',
  /** url */
  apiUrl: '',
  /** api 타입 */
  type: '',
  /** parameter */
  parameter: undefined,
  /** header */
  header: undefined,
  /** auth mapping */
  mapping: undefined,
  /** Auth 사용 */
  isAuth: false,
  /** Session 사용 */
  isKeepSession: true,
};

export const initialAuthDataOptions: AuthDataOptions = {
  provider: '',
  isKeepSession: true,
  authUrl: '',
};

export const initalConditionOptions: ConditionOptions = {
  key: '',
  inequality: '>',
  argument: '',
};

export const initalAddressDataOptions: AddressDataOptions = {
  address: '',
  zoneCode: '',
  address2: '',
};

export const initalVariablesOptions: VariablesOptions = {
  nextBlockId: '',
  matchList: [],
  initalExceptList: [],
};

const share = [
  {
    value: 'share',
    label: '공유하기',
  },
];

const addChannel = [
  {
    value: 'addChannel',
    label: '채널 추가',
  },
];

const nextBlock = [
  {
    value: 'next_block',
    label: '블럭 연결',
  },
];

const nextScenario = [
  {
    value: 'next_scenario',
    label: '시나리오 연결',
  },
];
const defaultButton = [...nextBlock, ...nextScenario];

const phoneUrl = [
  {
    value: 'url',
    label: 'URL 링크',
  },
  {
    value: 'phone',
    label: '전화',
  },
];
const condition = [
  {
    value: 'condition',
    label: '조건 버튼',
  },
];

const endBotButton = [
  {
    value: 'endBot',
    label: '챗봇 종료',
  },
];
const happytalkButton = [
  {
    value: 'counselor',
    label: '상담사 연결',
  },
  {
    value: 'endBot',
    label: '챗봇 종료',
  },
];

const openBuilderPlugin = [
  {
    value: 'kakaoOpenPlugIn',
    label: '오픈빌더 플러그인 실행',
  },
];

const dialogFlowButton = [{ value: 'connectBlock', label: '블럭 연결' }];

const basicButtonOption = [...defaultButton, ...phoneUrl, ...condition, ...share, ...addChannel];

/**
 * 유저 초이스 버튼 > 버튼 기능 메뉴
 */
export const selectOptions = [...basicButtonOption, ...openBuilderPlugin];

//happytalk 유저의 경우 플러그인 제외
export const selectHappytalkOptions = [...basicButtonOption, ...happytalkButton];

/**
 * 유저 초이스 버튼 > 버튼 기능 메뉴 > 조건 버튼
 */
export const selectConditionOptions = [...defaultButton];

/**
 * 단방향 시나리오 설문 종료 버튼
 */
export const selectOneDirOptions = [...defaultButton];
export const selectHappytalkOneDirOptions = [...defaultButton, ...endBotButton];

/**
 * 유저 초이스 버튼 > 핫키 메뉴
 */
export const selectOptionsHotkey = defaultButton;

/**
 * 유저 초이스 버튼 > 핫키 메뉴(해피톡)
 */
export const selectOptionsHappyTalkHotkey = [...defaultButton, ...happytalkButton];

/**
 * 유저 초이스 버튼 > 자동 시나리오 메뉴
 */
export const selectOptionsAutoscenario = [...nextScenario, ...phoneUrl];

/** 자연어 버튼 기능 선택 */
export const selectOptionsDialogFlow = [...dialogFlowButton, ...phoneUrl];

const arOptionBlock = [
  { value: 'next_block_pre', label: '이전단계' },
  { value: 'next_block_home', label: '처음으로' },
  { value: 'listen_again', label: '다시 듣기' },
];

/**상담콜 콜연결 블럭 버튼 기능 선택 */
export const selectArButton = [...nextBlock, ...nextScenario, ...arOptionBlock];
export const selectArDefault = [...defaultButton];

/** 카카오 플러그인 타입선택자 */
export const selectKakaoPlugInType = [
  { value: 'date', label: '@sys.plugin.date' },
  { value: 'datetime', label: '@sys.plugin.datetime' },
  { value: 'secureimage', label: '@sys.plugin.secureimage' },
  { value: 'time', label: '@sys.plugin.time' },
];

export const selectKakaoAuthPlugInType = [
  ...selectKakaoPlugInType,
  { value: 'sync', label: '@sys.plugin.sync' },
  { value: 'profileshare', label: '@sys.plugin.profileshare' },
];
/**
 * 유저 초이스 버튼 초기값
 */
export const initialUserChoiceOptions: UserChoiceDataOptions = {
  // _id: '',
  active: true,
  /** 버튼 타입 */
  type: selectOptions[0].value,
  /** 버튼명 */
  name: '',
  /** 다음 대화 블럭 아이디 */
  next: '',
  /** 미디어 주소 */
  url: '',
  /** 폰넘버 */
  phone: -1,
  /** 연결할 시나리오 아읻; */
  next_scenario_id: '',
  /** 버튼 오너 */
  button_owner: 'user',
  /** 카카오 오픈빌더 블럭 아이디 */
  kakaoOpenBlockId: '',
};

/** 외부 앱 연동 초기값 */
export const integrationAPIDataOptions: IntegrationAPIDataType[] = [
  {
    role: 'CONNECT',
    url: '',
  },
  {
    role: 'DISCONNECT',
    url: '',
  },
  {
    role: 'TARGET',
    url: '',
  },
];

/**
 * 블럭 초기값
 */
export const initialOptions: DefaultBlockModelOptions = {
  /** 블럭 서버 아이디 */
  _id: '-1',
  /** 봇 아이디 */
  botId: '-1',
  /** 시나리오 아이디 */
  scenarioId: '-1',
  /** 블럭명 */
  name: '',
  /** 블럭 타입 */
  blockType: '',
  /** block Active Idx */
  // activeDataIdx: 0,
  /** 블럭 에디터 데이터 */
  editorData: {
    /** 좌표 */
    y: 0,
    /** 좌표 */
    x: 0,
    /** 블럭 최소화 여부 */
    isMinimize: false,
    /** 블럭 스키마 */
    blockSchema: {
      /** 액션명 */
      action_name: '-1',
      /** 아이템명 */
      item_name: '-1',
      /** 봇 텍스트 사용여부 */
      bot_text: false,
      /** 봇 미디어 사용여부 */
      bot_media: false,
      /** 사갹형 봇 미디어 사용여부 */
      bot_media_square: false,
      /** 하단 봇 버튼 사용여부 */
      bot_button_bottom: false,
      /** 기본 버튼 갯수 */
      bot_button_inst: -1,
      /** 최대 버튼 갯수 */
      bot_button_max: -1,
      /** 버튼 수정 */
      bot_button_edit_fixed: false,
      /** 카드형 블럭 여부 */
      card: false,
      /** 캐로셀형 블럭 여부 */
      carousel: false,
      /** 커머스형 블럭 여부 */
      commerce: false,
      /** 리스트형 블럭 여부 */
      list: false,
      /** 리스트형 블럭 최대 갯수 */
      list_max: -1,
      /** 모듈형 블럭 여부 */
      func: false,
      /** 초이스 버튼 사용여부 */
      user_response: false,
      /** 시나리오 리스트형 블럭 */
      isAutoScenarioList: false,
      /** 핫키 사용여부 */
      isHotKey: false,
      /** 연속블럭 사용여부 */
      useSubBlock: true,
      /** Api 호출여부 */
      api: false,
      /** 조건 블럭 호출 여부 */
      condition: false,

      /** 다중 조건 블럭 호출 여부 */
      multi_condition: false,

      /** 다중 조건 최대 갯수 */
      multi_condition_max: -1,
    },
    /** 블럭 데이터 */
    blockData: [initialBlockDataOptions],
    /** 모듈 */
    moduleData: initialModuleDataOptions,
    /** 연속연결 부모 블럭 아이디 */
    parentBlockId: '',
    /** 연속블럭 */
    subBlockId: '',
    outApiData: initialApiDataOptions,
    /** 인증블럭 */
    authData: initialAuthDataOptions,
    /** 조건블럭 */
    conditionData: initalConditionOptions,
    multiConditionData: [initalConditionOptions],

    addressData: initalAddressDataOptions,

    variablesData: initalVariablesOptions,
    /** 외부 앱 연동 블럭 */
    integrationApiData: integrationAPIDataOptions,
  },
};

// /**
//  * 포트 확장 타입(삭제 예정)
//  */
// export interface DefaultBlockModelGenerics {
//     PORT: BlockOutPortModel; // 커스텀 포트
// }

/***************************************************************
 * 멤버
 ***************************************************************/

/**
 * 멤버 데이터 타입
 * 정리중, 현재는 서버에서 들어오는 구조임.
 */
export interface MemberProps {
  [x: string]: any;

  providerData: {
    siteId: string | undefined;
    partnerId: string | undefined;
  };
  _id: string | undefined;
  name: string | undefined;
  email: string | undefined;
  state: string | undefined;
  provider: string | undefined;
  refreshToken: string | undefined;
  isSuperAdmin?: boolean;
  phone: string | undefined;
  country: string | undefined;
  profileThumbnail?: string | undefined;
  config: {
    isRating?: boolean | undefined;
    isSession?: boolean | undefined;
    isScenario?: boolean | undefined;
    isStatistics?: boolean | undefined;
    blockLevel?: any;
    customBlock?: string[];
    isTextCount?: boolean | undefined;
    isOTP?: boolean | undefined;
    isConcurrentUser?: boolean | undefined;
    isAuthEdit?: boolean | undefined;
    isFriend?: boolean | undefined;
    isFriendTalk?: boolean | undefined;
    isDebugging?: boolean | undefined;
    isSync?: boolean | undefined;
    isAnalyze?: boolean | undefined;
    isCustom?: boolean | undefined;
    moduleBlockIds?: any[];
    isBackup?: boolean;
    isDeveloper?: boolean;
    isParticipation?: boolean;
    isXlsx?: boolean;
    isSurvey?: boolean;
    isUseReset?: boolean;
    isDialogFlow?: boolean;
    isScenarioTagInfinite?: boolean;
    isScenarioTagCount?: number;
    isTagLengthInfinity?: boolean;
    isRefreshParticipation?: boolean;
    scenarioLimitCount: number;
    blockLimitCount: number;
    isTest?: boolean;
    isKakaoProfileAuth?: boolean;
  };
  accessKey?: string;
  loginExpire?: string;
  isServiceLock?: boolean;
  socketId?: string;
}

/***************************************************************
 * 기타
 ***************************************************************/

/**
 * 폼 에러 타입
 */
export interface FormErrorOptions {
  [x: string]: any; // 폼 에러타입
}

/** 블럭 에디터 서브 아이템 상태값 */
export interface SelectedSubItemProps {
  /** blockData 인덱스 */
  blockDataIdx: number;
  /** 아이템 인덱스 또는 인자값 */
  itemIdx: number | string;
  /** 아이템 필드 */
  itemFieldName: string;
  /** 아이템 고유아이디 */
  itemUid?: string | undefined;
}

/**
 * 버튼 디자이너
 */
export interface ButtonDesignerProps {
  activeField: any;
  data: any;
  skin: any;
}

/***************************************************************
 * Library ------- gi
 ***************************************************************/
export interface Channel {
  channel: string;
  name: string;
  active: boolean;
}

export interface DataSchema {
  action_name: string;
  item_name: string;
  bot_text: boolean;
  bot_media: boolean;
  bot_media_square: boolean;
  bot_button_bottom: boolean;
  bot_button_inst: number;
  bot_button_max: number;
  bot_button_edit_fixed: boolean;
  card: boolean;
  carousel: boolean;
  commerce: boolean;
  list: boolean;
  func: boolean;
  user_response: boolean;
  isAutoScenarioList: boolean;
  isHotKey: boolean;
  button_numbering: boolean;
  api: boolean;
  useSubBlock: boolean;
  condition: boolean;
  multi_condition: boolean;
}

export interface IModule {
  id: string;
  level: number;
  action_name: string;
  item_name: string;
  active: boolean;
  help: string;
  channels: Channel[];
  data_schema: DataSchema;
}

export interface GroupHeader {
  title: string;
  help: string;
  channels: string[];
}

export interface Item {
  active: boolean;
  menuOpen: boolean;
  groupHeader: GroupHeader;
  items: IModule[];
  name?: string;
}

export interface Library {
  LibraryIconPath: string;
  LibrarylItems: {
    [key: string]: Item;
  };
}

export interface ConsultationType {
  _id: string;
  endUserId: string;
  providerId: string;
  botId: string;
  channel: string;
  createdAt: string;
  updatedAt: string;
  currentBlockId: string;
  currentScenarioId: string;
  prevBlockId: string;
  name?: string;
  isFriend?: boolean;
  isAuth?: boolean;
  plusfriendUserKey?: string;
}

export interface historyType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: historyItemsType[];
}

export interface historyItemsType {
  _id: string;
  bot: {
    _id: string;
    name: string;
    state: string;
  };
  member: {
    _id: string;
    email: string;
    name: string;
  };
  createdAt: string;
  updatedAt: string;
  version: number;
  isCurrentVersion: boolean;
  isBackup: boolean;
  description: string;
  scenarioId?: string;
}

export interface workHistoryType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: workHistoryItemsType[];
}

export interface workHistoryItemsType {
  _id: string;
  memberId: string;
  output: any;
  action: {
    action: string;
    group: string;
  };
  request: any;
  useragent: any;
  createdAt: string;
  updatedAt: string;
}

export interface historyDataType {
  _id: string;
  memberId: string;
  ouput: string;
  action: string;
  group: string;
  request: {
    requestHeaders: any;
    requestMethod: string;
  };
  useragent: string;
  createdAt: string;
  updatedAt: string;
}

export interface authListType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: authListItemsType[];
}

export interface authListItemsType {
  _id: string;
  authProvider: string;
  authUserInfo: any;
  awesomeBotMemberId: string;
  endUserId: string;
  userAccessToken: string;
  botSessionId: string;
  active: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface ratingListType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: ratingListItemsType[];
}

export interface ratingListItemsType {
  points: any;
  botId: string;
  createdAt: string;
  updatedAt: string;
}

export interface messageType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: messageItemType[];
}

export interface messageItemType {
  _id: string;
  botSessionId: string;
  endUserId: string;
  createdAt: string;
  updatedAt: string;
  content: any;
}

export interface botSessionType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: botSessionItemsType[];
}

export interface botSessionItemsType {
  _id: string;
  endUserId: string;
  providerId: string;
  botId: string;
  channel: string;
  createdAt: string;
  updatedAt: string;
  currentBlockId: string;
  name?: string;
  isFriend?: boolean;
  isAuth?: boolean;
  isSync?: boolean;
  plusfriendUserKey?: string;
  currentScenarioId: string;
  prevBlockId: string;
  isActive: boolean;
  variables?: {
    lastMessage?: {
      user?: string;
      bot?: string;
    };
    uEmail?: string;
    userId?: string;
    uPhoneNumber?: string;
  };
  useScenarioList?: any;
}

export interface variablesItemType {
  _id: string;
  botId: string;
  botSessionId: string;
  isUserInput: boolean;
  stack: string[];
  variables: Record<string, any>;
  expireAt: Date;
  currentBlockId: string;
}

export interface participationItemsType {
  _id: string;
  memberId: string;
  botId: string;
  scenarioId: string;
  botSessionId: string;
  plusfriendUserKey: string;
  variables: any;
  isSelect?: boolean;
  isFriend?: boolean;
  lastMessage?: any;
  isSendMessage?: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface participationType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: participationItemsType[];
}

export interface participationConfigType {
  botId: string;
  range?: { start: Date; end: Date };
  isFriend: boolean;
  isSync: boolean;
  channel: string[];
  search: {
    key: string;
    value: string;
  };
  order: {
    key: string;
    flag: string;
  };
}

export interface botSessionConfigType {
  botId: string;
  range: string | { start: Date; end: Date };
  isFriend: boolean;
  isAuth: boolean;
  channel: string[];
  search: {
    key: string;
    value: string;
  };
  order: {
    key: string;
    flag: string;
  };
}

export interface syncListType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  item: syncListItemsType[];
}

export interface syncListItemsType {
  _id: string;
  botId: string;
  botSessionId: string;
  endUserId: string;
  createdAt: string;
  updatedAt: string;
  memberId: string;
  authUserInfo: {
    syncId: string;
    syncNickname: string;
    syncAge: string;
    syncEmail: string;
    syncGender: string;
    syncPhone: string;
    syncName: string;
    fasiteNm: string;
  };
}

export interface statListType {
  botCount: number;
  list: statListItemsType[];
}

export interface statListItemsType {
  day: string;
  bot: string;
  user: number;
  userM: number;
  botM: number;
  total: number;
}

export interface counselorListItemsType {
  id: string;
  pid: string;
  site_id: string;
  name: string;
  use: string;
}

/** variables 타입 */
export interface BotVariablesType {
  dataType: string;
  _id?: string;
  key: string;
  name: string;
  desc: string;
  group: string;
  encryption: boolean;
  sendEncryption?: boolean;
}

export interface ExtendedBotVariablesType extends Omit<BotVariablesType, 'encryption'> {
  default?: boolean;
  isStaticField?: boolean;
}

/**
 * ModuleData 초기값
 */
export const initialARDataOptions = {
  isFlowEnd: false,
  isCallEnd: false,
  isVoiceSkip: true,
  tts: {
    ttsPath: '',
    messageContent: '',
    updatedAt: '',
    voiceType: {
      speaker: 'nara',
      pitch: '0',
      speed: '0',
    },
    isTextPlay: false,
  },
  message: {
    type: 'alimtalk',
    sms: {
      type: 'SMS',
      title: '',
      content: '',
    },
    alimtalk: {
      code: '',
    },
  },
  callConnect: {
    incommingCode: 'default',
    counselorAssign: {
      counselorType: 'R',
      prevCounselor: true,
      dateRange: '1week',
    },
  },
  callReservation: {},
  input: {
    type: '',
    endKey: '#',
    inputLength: 5,
  },
  inputVoice: {
    parameter: '',
  },
  option: {
    isCustom: false,
    inputWaitingTime: '30',
    connectionWaitingTime: '30',
    countConnectionAttempts: '3',
  },
};

export const initialBotVariablesType: BotVariablesType = {
  key: '',
  name: '',
  desc: '',
  dataType: 'string',
  group: '',
  encryption: false,
};

/**
 * 약관
 */
export interface TermsType {
  title: string;
  content: string;
}

/**
 * 분석 데이터 타입
 */
export interface AnalyzationType {
  day: string;
  list: AnalyzationListType[];
}

export interface AnalyzationListType {
  botId: string;
  rejoinUser: number; //오늘 이 봇을 재방문한 유저
  todayUser: number; //오늘 이 봇을 사용한 유저
  todayRoom: number; //오늘 이 봇에서 사용된 방의 갯수
  activeUser: number; //오늘 이 봇에서 활성화된 유저
  newUser: number; //오늘 이 봇을 처음 사용한 유저의 수
  newRoom: number; //오늘 이 봇에서 처음 생성된 방의 수
  totalM: number; //오늘 이 봇에서 발생한 총 메시지 갯수
  botM: number; //오늘 이 봇에서 발생한 봇 메시지의 총 갯수
  userM: number; //오늘 이 봇에서 발생한 유저 메시지의 총 갯수
  totalUser: number; // 봇 생성 이후로, 해당 일자까지 해당 봇을 사용한 총 유저의 수
  totalRoom: number; //봇 생성 이후로, 해당 일자까지 해당 봇에서 생성된 총 방의 갯수
  avgTime: string;
}

export interface TransmissionType {
  itemCount: number;
  lastPage: any;
  totalCount: number;
  items: TransmissionItemType[];
}

export interface TransmissionItemType {
  _id: string;
  uuid: string;
  isError: boolean;
  request: any;
  response: any;
  memberId: string;
  botId: string;
  scenarioId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface regExpType {
  _id: string;
  label: string;
  regex: string;
  botList: string[];
}

export interface AlimTalkTemplateItemType {
  senderKey: string;
  senderKeyType?: string;
  templateCode: string;
  templateName: string;
  templateMessageType: string;
  templateEmphasizeType: string;
  templateContent: string;
  templateExtra?: string;
  templateAd?: string;
  templateTitle?: string;
  templateSubtitle?: string;
  inspectionStatus: string;
  createdAt?: string;
  status: string;
  categoryCode?: string;
  securityFlag?: boolean;
  comments?: any;
  templateImageName?: string;
  templateImageUrl?: string;
  buttons?: any;
  quickReplies?: any;
}

export interface AlimTalkTemplateType {
  items: AlimTalkTemplateItemType[];
}

export interface SurveyFormType {
  _id?: string;
  blockId: string;
  question: string;
  type: string;
  sort: number;
  isNecessary: boolean;
  button: { type: string; comment: string }[] | [];
  checkCount?: {
    signType: string;
    count: number;
  };
  gradeConfig?: {
    min: string;
    max: string;
    count: number;
    start: number;
  };
}

export interface SurveyAnswerType {
  formData: FormDataOptions;
  answer: AnswerType[];
}

export interface surveyResultType {
  count: number;
  value: string;
  percentage: number;
}
export interface AnswerType {
  question: string;
  result: { count: number; value: string; percentage: number; detail?: surveyResultType[] }[];
  surveyFormId: string;
  type: string;
}

export interface surveySessionType {
  sessionId: string;
  sessionName?: string;
  authUserInfo: any;
  plusfriendUserKey: string;
  createdAt: Date;
}

export interface NlpAgentType {
  _id: string;
  botId: string;
  provider: string;
  option: Partial<NlpdialogFlowOption> & Partial<NlphAIOption>;
  active: boolean;
  threshold: number;
  msg: {
    guideMsg: string;
    cardMsg: string;
  };
}

interface NlpdialogFlowOption {
  projectId?: string;
  file?: {
    data: string;
    name: string;
  };
}

interface NlphAIOption {
  projectId?: string;
  apiKey: string;
  secret: string;
  userId: number;
  memberId: number;
  accessToken?: string;
  refreshToken?: string;
  isLink?: boolean;
}
export interface AgentProps {
  _id: string;
  botId: string;
  active: boolean;
  provider: string;
  state: string;
  threshold: number;
  msg: {
    guideMsg: string;
    cardMsg: string;
  };
  option: Partial<NlpdialogFlowOption> & Partial<NlphAIOption>;
}

interface AiDefaultType {
  _id: string;
  botId: string;
  aiAgentId: string;
}

interface AiIntentDefault extends AiDefaultType {
  name: string;
}

export interface NlpVariableType {
  key: string;
  value: string;
}

export interface NlpAiIntent extends AiIntentDefault {
  remoteId: string;
  categoryId?: string;
  learnId?: string;
  grade: number;
  fix: boolean;
  word: string[];
  sentence: string[];
  answerType: string;
  jugment: number;
  variables: NlpVariableType[];
  answer: {
    scenarioId?: string;
    blockId?: string;
    blockData?: object;
    blockSchema?: object;
  };
  createdAt: string;
  updatedAt: string;
  byAiDetectLogId?: string;
}

export interface NlpAiIntentCategory {
  _id: string;
  active: boolean;
  name: string;
  value: string;
  label: string;
}

export interface NlpAiLearnInterface extends AiIntentDefault {
  learnUID: string;
  source: string;
  categoryId: string;
  memo: string;
  sentence: string[];
  answerType: string;
  learnType: string;
  variables: any[];
  buttons: any[];
  useButton: boolean;
  useParameter: boolean;
  useLearn: boolean;
  answer: {
    scenarioId: string;
    blockId: string;
    content: string;
  };
  status: string;
  learnFinishDate: string;
  intentCount: number;
  reLearning: boolean;
  createdAt: Date;
  updatedAt: Date;
}

export interface ExternalLandingProps {
  siteId: string;
  botId: string;
  accessKey: string;
  accessToken: string;
}

export enum BtnTaskTypes {
  'OPEN' = '운영시간',
  'CLOSE' = '운영시간 외',
}

export enum Solution {
  ARS = 'AR',
  CHATBOT = 'CB',
  HEYTHERE = 'HD',
  HAPPYSYNC = 'HS',
  HAPPYTALK = 'HT',
  TOTAL_ADMIN = 'TA',
}

export enum MProvider {
  ARS = 'sdcall',
  HT = 'happytalk',
  ASB = 'awesomebot',
}

export enum NlpPageTypePrefix {
  intent = 'intent',
  learn = 'learn',
  fail = 'fail',
  agent = 'agent',
  message = 'message',
}

export interface AiDetectLog {
  _id: string;
  botId: string;
  aiAgentId: string;
  responseId: string;
  duration: number;
  score?: number;
  learnId?: string;
  aiIntentId?: string;
  aiFailMessageId?: string;
  status: 'success' | 'fail' | 'pending';
  detect: string;
  aiAnswer?: string;
  scenarioId?: string;
  blockId?: string;
  answerType: 'S' | 'N';
  foreignUpdatedAt?: Date;
  createdAt: Date;
}

//탑바에서 사용되는 모달 타입
export type TopbarActionType = 'trial' | 'experience' | 'experienceAlert' | 'deploy';

export interface AiLogQueryInterface {
  status?: 'success' | 'fail';
  answerType?: 'N' | 'S';
  foreignUpdatedAt?: 'true' | 'false';
  detect?: string;
}

export type IntegrationAPIDataRole = 'CONNECT' | 'DISCONNECT' | 'TARGET';

export interface IntegrationAPIDataType {
  role: IntegrationAPIDataRole;
  url: string;
}

export interface DTMFButtonType {
  index: number;
  name: string;
  value: string;
  active: boolean;
}
export interface MessageTypes {
  initMsg: string;
  botIdTitle: string;
  botIdMsg: string;
  hookUrlTitle?: string;
  hookUrlMsg?: string;
  customHappytalkTitle?: string;
  customHappytalkMsg?: string;
  customAwesomebotMsg?: string;
  otherIdTitle?: string;
  otherIdMsg?: string;
  bizMessageGuide?(provider: string): string;
}

export interface AuthSyncConfigType {
  chName: string;
  channelName: string;
  msgType: MessageTypes;
  domainURL?: string;
  domainReURL?: string;
  isUse: boolean;
  message?: string;
  title?: string;
  img?: string;
  syncButtonName?: string;
  startButtonName?: string;
  isUseTagName?: boolean;
}

/** react-select 에서 사용되는 option 값들의 형태 */
export interface OptionType {
  value: string;
  label: string;
}
