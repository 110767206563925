import React from 'react';
import { Button } from 'happytalk-design-guide';

interface InputBoxProps {
  onClick?: (e: any) => void;
  /**api 주소  ex) /kakap/excel */
  data: Blob | null;
  /** 다운로드 파일 이름 ex) `${memberId}.xlsx`, `${email}.json` */
  fileName: string;
  /**버튼 이름 */
  btnName: string;
  /** 버튼 테마 */
  theme?: 'blue' | 'green' | 'red' | 'yellow' | 'grayscale' | 'cadetBlue' | undefined;
  /** 버튼 활성화 */
  disabled?: boolean;
}

// <DownloadBtn api={`/kakaosync/excel`} fileName={`${memberId}.xlsx`} btnName={'다운로드'} />
const DownloadBtn: React.FC<InputBoxProps> = ({ onClick, data, fileName, btnName, theme, disabled }) => {
  React.useEffect(() => {
    if (!data || !fileName) return;
    /**파일 생성 및 다운로드 작업 */
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }, [data, fileName]);

  return (
    <Button onClick={onClick} theme={theme} disabled={disabled}>
      {btnName}
    </Button>
  );
};

export default DownloadBtn;
