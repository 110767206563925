import { TransmissionType, TransmissionItemType } from '../../constants/Types';

const GET_TRANSMISSION_HISTORY = 'transmission/GET_TRANSMISSION_HISTORY' as const;
const DETAIL_TRANSMISSION = 'transission/DETAIL_TRANSMISSION' as const;

interface TransmissionProps {
  lists: TransmissionItemType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
  active: TransmissionItemType | null;
}

const init = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
  active: null,
};

export const getTransmissionHistory = (history: TransmissionType) => ({
  type: GET_TRANSMISSION_HISTORY,
  payload: history,
});

export const detailTransmissionHistory = (history: TransmissionItemType) => ({
  type: DETAIL_TRANSMISSION,
  payload: history,
});

type TransmissionAction =
  | ReturnType<typeof getTransmissionHistory>
  | ReturnType<typeof detailTransmissionHistory>

function Transmission(state: TransmissionProps = init, action: TransmissionAction) {
  switch (action.type) {
    case GET_TRANSMISSION_HISTORY:
      return {
        ...state,
        lists: action.payload.items,
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    case DETAIL_TRANSMISSION:
      return {
        ...state,
        active: action.payload,
      };
    default: {
      return state;
    }
  }
}

export default Transmission;
