import { counselorListItemsType } from '../../constants/Types';

const GET_MAJOR_COUNSELOR = 'counselor/GET_MAJOR_COUNSELOR' as const;
const GET_MIDDLE_COUNSELOR = 'counselor/GET_MIDDLE_COUNSELOR' as const;

/**
 * 액션
 * @param type
 * @param categoryId?
 * */

export const getMagjorCounselor = (counselor: counselorListItemsType[]) => ({
  type: GET_MAJOR_COUNSELOR,
  payload: counselor,
});

export const getMiddleCounselor = (counselor: counselorListItemsType[]) => ({
  type: GET_MIDDLE_COUNSELOR,
  payload: counselor,
});

interface CounselorListProps {
  majorLists: counselorListItemsType[];
  middleLists: counselorListItemsType[];
}

const initialState = {
  majorLists: [],
  middleLists: [],
};

type CounselorListAction = ReturnType<typeof getMagjorCounselor> | ReturnType<typeof getMiddleCounselor>;

function CounselorList(state: CounselorListProps = initialState, action: CounselorListAction) {
  switch (action.type) {
    case GET_MAJOR_COUNSELOR: {
      return {
        ...state,
        majorLists: action.payload.map((item: counselorListItemsType) => {
          return {
            id: item.id,
            pid: item.pid,
            site_id: item.site_id,
            name: item.name,
            use: item.use,
          };
        }),
      };
    }
    case GET_MIDDLE_COUNSELOR: {
      return {
        ...state,
        middleLists: action.payload.map((item: counselorListItemsType) => {
          return {
            id: item.id,
            pid: item.pid,
            site_id: item.site_id,
            name: item.name,
            use: item.use,
          };
        }),
      };
    }
    default: {
      return state;
    }
  }
}

export default CounselorList;
