import { authListType, authListItemsType } from 'constants/Types';

const GET_AUTH_LIST = 'enduser/GET_AUTH_LIST' as const;

/**
 * 액션
 * @param work
 * */

export const getAuthList = (auth: authListType) => ({
  type: GET_AUTH_LIST,
  payload: auth,
});

interface AuthListProps {
  lists: authListItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
};

type AuthListAction = ReturnType<typeof getAuthList>;

function AuthList(state: AuthListProps = initialState, action: AuthListAction) {
  switch (action.type) {
    case GET_AUTH_LIST: {
      return {
        ...state,
        lists: action.payload.items.map((item: authListItemsType) => {
          return {
            ...item,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default AuthList;
