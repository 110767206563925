import styled from 'styled-components';
import React, { ReactNode } from 'react';
import Menubar from './Menubar';
import classNames from 'classnames';

import { useToggleLayer, anchor, useHover } from 'react-laag';

export interface DropdownProps {
  /** 메뉴 목록 */
  items: ItemsProps[];
  /** 사이즈 */
  size?: 'small' | 'middle' | 'large';
  /** 좌측정렬 */
  bottomLeft?: boolean;
  /** 우측정렬 */
  bottomRight?: boolean;
  /** 드롭다운 엘리먼트가 들어갈 부모 엘리먼트 */
  containerClassName?: string;
  /** 메뉴 넓이 */
  width?: number;
  /** 클래스 */
  className?: string;
  /** 호버 사용 */
  hover?: boolean;
  marginTop?: string;
  children: ReactNode;
}

export interface ItemsProps {
  /** 메뉴명 */
  name: string;
  /** 클릭시 */
  onClick: (props: any, name: string, index: number) => void;
  /** 아이콘 */
  id: string;
  /** 사용 */
  used: boolean;

  isCurrent?: boolean;
}

/** 드롭다운 컨테이너 */
const Container = styled.span`
  display: inline-block;
`;

const Dropdown: React.FC<DropdownProps> = ({
  items,
  size,
  marginTop,
  bottomLeft,
  bottomRight,
  containerClassName,
  children,
  width,
  className,
  hover,
}) => {
  /**
   *
   * Dropdown element
   */
  const triggerRef = React.useRef<any | null>(null);
  const [element, toggleLayerProps] = useToggleLayer(
    (props) => {
      if (!props.isOpen) return null;

      return (
        <div {...props.layerProps}>
          <Menubar
            className={className ? className : ''}
            marginTop={marginTop}
            size={size ? size : 'small'}
            width={width ? width : 113}
          >
            {items.map((item: ItemsProps, key: number) => {
              return (
                <button
                  onClick={() => {
                    props.close();
                    item.onClick(props, item.name, key);
                  }}
                  key={key}
                  className={classNames({
                    show: item.used,
                    current: item.isCurrent,
                  })}
                >
                  <span className={item.id} />
                  {item.name}
                </button>
              );
            })}
          </Menubar>
        </div>
      );
    },
    {
      placement: {
        anchor: bottomLeft ? anchor.BOTTOM_LEFT : bottomRight ? anchor.BOTTOM_RIGHT : anchor.BOTTOM_CENTER,
      },
      closeOnOutsideClick: true,
      // closeOnDisappear: 'partial',
      container: containerClassName ? (document.querySelector(containerClassName) as HTMLElement) : undefined,
    },
  );

  const hoverProps = useHover({
    delayEnter: 300,
    delayLeave: 200,
    onShow: () => toggleLayerProps.openFromRef(triggerRef),
    onHide: () => toggleLayerProps.close(),
  });

  return (
    <>
      {hover ? (
        <Container ref={triggerRef} {...hoverProps}>
          {element}
          <div onClick={toggleLayerProps.openFromMouseEvent}>
            <span className="icon">{children}</span>
          </div>
        </Container>
      ) : (
        <Container>
          {element}
          <div onClick={toggleLayerProps.openFromMouseEvent}>
            <span className="icon">{children}</span>
          </div>
        </Container>
      )}
    </>
  );
};

export default Dropdown;
