import styled from 'styled-components';

const Panel = styled.div`
  border-radius: 10px;
  border: solid 1px var(--pale-lilac);
  background-color: #ffffff;
  padding: 30px;
  margin: 15px 30px;
  width: 100%;
  &.continue {
    margin-top: 0px;
  }

  .panel-title {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.5px;
    color: var(--black);
    padding-bottom: 8px;
    vertical-align: middle;

    .count {
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: var(--light-blue);
      vertical-align: middle;
      margin-left: 3px;
    }
  }

  .panel-desc {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    letter-spacing: -0.5px;
    color: var(--brown-grey);
  }

  &.panel-white {
    background-color: var(--white);
  }
`;

export default Panel;
