import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyleProps } from '../../Interfaces/Box';

export const BaseBox = styled.div<StyleProps>`
  width: ${(props) => props.width};
  min-width: ${(props) => props.minWidth};
  max-width: ${(props) => props.maxWidth};
  height: ${(props) => props.height};
  min-height: ${(props) => props.minHeight};
  max-height: ${(props) => props.maxHeight};

  display: ${(props) => props.display};
  flex-wrap: ${(props) => props.flexWrap};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  vertical-align: ${(props) => props.verticalAlign};
  gap: ${(props) => props.gap};
  flex-direction: ${(props) => props.flexDirection};
  align-self: ${(props) => props.alignSelf};

  margin: ${(props) => props.margin};
  margin-top: ${(props) => props.marginTop};
  margin-left: ${(props) => props.marginLeft};
  margin-right: ${(props) => props.marginRight};
  margin-bottom: ${(props) => props.marginBottom};

  padding: ${(props) => props.padding};
  padding-top: ${(props) => props.paddingTop};
  padding-left: ${(props) => props.paddingLeft};
  padding-right: ${(props) => props.paddingRight};
  padding-bottom: ${(props) => props.paddingBottom};

  position: ${(props) => props.position};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  bottom: ${(props) => props.bottom};

  overflow: ${(props) => props.overflow};

  background-color: ${(props) => props.backgroundColor};

  border: ${(props) => props.border};
  border-radius: ${(props) => props.borderRadius};
  border-top: ${(props) => props.borderTop};
  border-bottom: ${(props) => props.borderBottom};
  border-left: ${(props) => props.borderLeft};
  border-right: ${(props) => props.borderRight};

  float: ${(props) => props.float};

  text-align: ${(props) => props.textAlign};
  white-space: ${(props) => props.whiteSpace};
  word-break: ${(props) => props.wordBreak};
`;
