/**
 * 초기값 타입
 */
export interface ChatbotSetupProps {
  _id?: string;
  botId: string;
  counselorGuideMsg?: string;
  fileErrorMessage?: string;
}

/**
 * 초기값
 */
export const initialState: ChatbotSetupProps = {
  botId: '',
  counselorGuideMsg: '',
  fileErrorMessage: '',
};

const GET_BOT_SETUP = 'GET_BOT_SETUP' as const;
const UPDATE_BOT_SETUP = 'UPDATE_BOT_SETUP' as const;

/** action */
export const getChatbotSetup = (data: ChatbotSetupProps) => ({
  type: GET_BOT_SETUP,
  payload: data,
});

export const updateChatbotSetup = (data: any) => ({
  type: UPDATE_BOT_SETUP,
  payload: data,
});

/**
 * 액션 타입 그룹
 */
type BotsAction = ReturnType<typeof getChatbotSetup> | ReturnType<typeof updateChatbotSetup>;

function BotSetup(state: ChatbotSetupProps = initialState, action: BotsAction) {
  switch (action.type) {
    case GET_BOT_SETUP:
      return { ...action.payload };
    case UPDATE_BOT_SETUP:
      return { ...action.payload };
    default:
      return state;
  }
}

export default BotSetup;
