import moment from 'moment';
import { AnalyzationType } from 'constants/Types';

const GET_ANALYZE_LIST = 'analyzation/GET_ANALYZE_LIST' as const;
const SET_ANALYZE_ACTIVE = 'analyzation/SET_ANALYZE_ACTIVE' as const;
const DELETE_ANALYZE = 'analyzation/DELETE_ANALYZE' as const;

export const getAnalyzeList = (report: AnalyzationType[], props: any) => ({
  type: GET_ANALYZE_LIST,
  payload: report,
  body: props,
});

export const setAnalyzeActive = (data: any) => ({
  type: SET_ANALYZE_ACTIVE,
  payload: data,
});

export const deleteAnalyze = (index: number) => ({
  type: DELETE_ANALYZE,
  payload: index,
});

interface AnalyzationProps {
  lists: { data: AnalyzationType[]; req: any; date: string }[] | [];
  active: { data: AnalyzationType[]; req: any; date: string } | null;
}

const initialState = {
  lists: [],
  active: null,
};

type AnalyzeListAction = ReturnType<typeof getAnalyzeList> | ReturnType<typeof setAnalyzeActive> | ReturnType<typeof deleteAnalyze>;

function Analyzation(state: AnalyzationProps = initialState, action: AnalyzeListAction) {
  switch (action.type) {
    case GET_ANALYZE_LIST: {
      return {
        active: { data: action.payload, req: action.body, date: moment(new Date()).format('YYYYMMDDhhmmss') },
        lists: [...state.lists, { data: action.payload, req: action.body, date: moment(new Date()).format('YYYYMMDDhhmmss') }],
      };
    }
    case SET_ANALYZE_ACTIVE: {
      return {
        active: action.payload,
        lists: [...state.lists],
      };
    }
    case DELETE_ANALYZE: {
      state.lists.splice(action.payload, 1);
      return {
        active: state.lists[action.payload] ? state.lists[action.payload] : state.lists[action.payload - 1] ? state.lists[action.payload - 1] : null,
        lists: [...state.lists],
      };
    }
    default: {
      return state;
    }
  }
}

export default Analyzation;
