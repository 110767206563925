import { surveySessionType } from 'constants/Types';

const GET_SESSION_LIST = 'stat/GET_SESSION_LIST' as const;
const GET_SESSION_ANSWERS = 'stat/GET_SESSION_ANSWERS' as const;

interface answerProps {
  sessionName: string;
  answers: [{ key: string; value: any; haveEtc: boolean }];
}
interface surveySessionProps {
  paginator: {
    page: number;
    limit: number;
    totalDocs: number;
    [x: string]: any;
  };
  list: surveySessionType[];
  activeAnswer: answerProps;
}

export const getSurveySessionList = (session: surveySessionProps) => ({
  type: GET_SESSION_LIST,
  payload: session,
});

export const getSurveySessionAnswers = (answer: answerProps) => ({
  type: GET_SESSION_ANSWERS,
  payload: answer,
});

const initialState: surveySessionProps = {
  paginator: {
    page: -1,
    limit: 20,
    totalDocs: -1,
  },
  list: [
    {
      sessionId: '',
      sessionName: '',
      authUserInfo: {},
      plusfriendUserKey: '',
      createdAt: new Date(),
    },
  ],
  activeAnswer: {
    sessionName: '',
    answers: [{ key: '-1', value: '', haveEtc: false }],
  },
};

type SurveySessionListAction = ReturnType<typeof getSurveySessionList> | ReturnType<typeof getSurveySessionAnswers>;

function SurveySessionList(state: surveySessionProps = initialState, action: SurveySessionListAction) {
  switch (action.type) {
    case GET_SESSION_LIST: {
      const newData = action.payload.paginator.page === 1 ? true : false;
      return {
        paginator: action.payload.paginator,
        list: newData ? action.payload.list : [...state.list, ...action.payload.list],
        activeAnswer: state.activeAnswer,
      };
    }
    case GET_SESSION_ANSWERS: {
      return {
        paginator: state.paginator,
        list: state.list,
        activeAnswer: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default SurveySessionList;
