//action's name

const POST_PARAMETER = 'POST_PARAMETER';
const PUT_PARAMETER = 'PUT_PARAMETER';
const DELETE_PARAMTER = 'DELETE_PARAMTER';
const GET_PARAMETER = 'GET_PARAMETER';

//initial types
type PrameterBotType = {
  _id: string;
  name: string;
};

interface PostParameterDataType extends ParameterType {
  parameterName: string;
  parameterValue: string;
  parameterEnt: string;
}

interface GetParameterDataType {}

interface UpdateParameterDataType extends ParameterType {
  _id: string;
  parameterName: string;
  parameterEnt: string;
  parameterValue: string;
  botList: PrameterBotType[];
}

interface DeleteParameterDataType {}

interface ParameterType {
  _id: string;
  parameterName: string;
  parameterEnt: string;
  parameterValue: string;
  botList: PrameterBotType[];
}

interface initialStateType {
  parameter: ParameterType[];
}

const initialState: initialStateType = {
  parameter: [],
};

//actions
export const postParameter = (postParameterData: PostParameterDataType) =>
  ({
    type: POST_PARAMETER,
    data: postParameterData,
  } as const);
export const getParameter = (parameters: ParameterType[]) =>
  ({
    type: GET_PARAMETER,
    data: parameters,
  } as const);
export const putParameter = (updateParameterData: UpdateParameterDataType) =>
  ({
    type: PUT_PARAMETER,
    data: updateParameterData,
  } as const);
export const deleteParameter = (deleteParameterDataType: DeleteParameterDataType) =>
  ({
    type: DELETE_PARAMTER,
    data: deleteParameterDataType,
  } as const);

type ParameterActionType =
  | ReturnType<typeof postParameter>
  | ReturnType<typeof getParameter>
  | ReturnType<typeof putParameter>
  | ReturnType<typeof deleteParameter>;

const parameterReducer = (state = initialState, action: ParameterActionType): initialStateType => {
  switch (action.type) {
    case 'POST_PARAMETER': {
      console.log('---> POST_PARAMETER');
      const ent = action.data.parameterEnt;
      const useEnt = { label: ent, value: ent };
      (action.data.parameterEnt as unknown as { label: string; value: string }) = useEnt;
      return {
        ...state,
        parameter: [...state.parameter, action.data],
      };
    }
    case 'GET_PARAMETER': {
      console.log('---> GET_PARAMETER');
      return {
        ...state,
        parameter: action.data,
      };
    }
    case 'PUT_PARAMETER': {
      console.log('---> PUT_PARAMETER');
      const index = state.parameter.findIndex((param) => param._id === action.data._id);
      const parameter = [...state.parameter];
      const ent = action.data.parameterEnt;
      (action.data.parameterEnt as unknown as { label: string; value: string }) = { label: ent, value: ent };
      parameter.splice(index, 1, action.data);
      return {
        ...state,
        parameter,
      };
    }
    case 'DELETE_PARAMTER': {
      console.log('---> DELETE_PARAMTER');
      const index = state.parameter.findIndex((p) => p._id === action.data);
      state.parameter.splice(index, 1);
      return {
        ...state,
        parameter: [...state.parameter],
      };
    }
    default: {
      return state;
    }
  }
};

export default parameterReducer;
