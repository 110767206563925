/**
 * 시나리오 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import * as Types from '../../constants/Types';

/** 전체시나리오 업데이트 */
const UPDATE_SCENARIO_LIST = 'scenarios/UPDATE_SCENARIO_LIST' as const;
/** 시나리오 추가 */
const ADD_SCENARIO = 'scenarios/ADD_SCENARIO' as const;
/** 시나리오 업데이트 */
const UPDATE_SCENARIO = 'scenarios/UPDATE_SCENARIO' as const;
/** 활성 시나리오 */
const ACTIVE_SCENARIO = 'scenarios/ACTIVE_SCENARIO' as const;
/** 편집할 시나리오 전체 내용 초기화 */
const RESET_EDIT_SCENARIO_DATA = 'scenarios/RESET_EDIT_SCENARIO_DATA' as const;
/** 편집할 시나리오 내용 업데이트 */
const UPDATE_EDIT_SCENARIO_DATA = 'scenarios/UPDATE_EDIT_SCENARIO_DATA' as const;
/** 편집 팝업 오픈 상태 업데이트 */
const UPDATE_EDIT_POPUP_OPEN = 'scenarios/UPDATE_EDIT_POPUP_OPEN' as const;
/**  읽기전용 상태 업데이트 */
const UPDATE_READ_ONLY = 'scenarios/UPDATE_READ_ONLY' as const;
/** 템플릿 수정 상태 업데이트 */
const UPDATE_TEMPLATE_SCENARIO = 'scenarios/UPDATE_TEMPLATE_SCENARIO' as const;

/**
 * 액션
 * @param scenarios
 */
export const updateScenarioList = (scenarios: any) => ({
  type: UPDATE_SCENARIO_LIST,
  payload: scenarios,
});
export const addScenario = (scenario: Types.ScenarioProps) => ({
  type: ADD_SCENARIO,
  payload: scenario,
});
export const updateScenario = (scenario: Types.ScenarioProps) => ({
  type: UPDATE_SCENARIO,
  payload: scenario,
});

export const activeScenario = (scenario: any) => ({
  type: ACTIVE_SCENARIO,
  payload: scenario,
});
export const updateEditScenarioData = (scenario: any) => ({
  type: UPDATE_EDIT_SCENARIO_DATA,
  payload: scenario,
});
export const resetEditScenarioData = () => ({
  type: RESET_EDIT_SCENARIO_DATA,
});

export interface editInfoProps {
  /** 팝업창 open여부 */
  open: boolean;
  /** 신규여부 */
  isNew: boolean;
}
export const updateEditPopupOpen = (editInfo: editInfoProps) => ({
  type: UPDATE_EDIT_POPUP_OPEN,
  payload: editInfo,
});
export const updateReadOnlyScenario = (use: boolean) => ({
  type: UPDATE_READ_ONLY,
  payload: use,
});
export const updateIsTemplate = (use: boolean) => ({
  type: UPDATE_TEMPLATE_SCENARIO,
  payload: use,
});

/**
 * 액션 타입 그룹
 */
type ScenariosAction =
  | ReturnType<typeof updateScenarioList>
  | ReturnType<typeof addScenario>
  | ReturnType<typeof updateScenario>
  | ReturnType<typeof activeScenario>
  | ReturnType<typeof updateEditScenarioData>
  | ReturnType<typeof resetEditScenarioData>
  | ReturnType<typeof updateEditPopupOpen>
  | ReturnType<typeof updateReadOnlyScenario>
  | ReturnType<typeof updateIsTemplate>;

/**
 * 초기값 타입
 */
interface ScenarioState {
  /** 시나리오 리스트 */
  lists: Types.ScenarioProps[] | undefined;
  /** 활성 시나리오 */
  active: any | undefined;
  /** 편집할 시나리오 데이터 */
  editScenarioData: Types.editScenarioDataProps;
  /** 편집 팝업 오픈 상태 */
  isModifyOpen: boolean;
  /** 시나리오 신귝 추가 여부 */
  isNew: boolean;
  /** 읽기전용 */
  isReadOnly: boolean;
  /** 템플릿 블럭 수정 */
  isTemplate: boolean;
}

/**
 * 초기값
 */
const initialState: ScenarioState = {
  /** 시나리오 리스트 */
  lists: undefined,
  /** 활성 시나리오 */
  active: undefined,
  /** 편집할 시나리오 데이터 */
  editScenarioData: Types.editScenarioDataOptions,
  /** 편집 팝업 오픈 상태 */
  isModifyOpen: false,
  /** 시나리오 신규 추가 여부 */
  isNew: false,
  /** 읽기전용 */
  isReadOnly: false,
  /** 템플릿 블럭 수정 */
  isTemplate: false,
};

function Scenarios(state: ScenarioState = initialState, action: ScenariosAction) {
  switch (action.type) {
    /**
     * 전체 시나리오 업데이트
     */
    case UPDATE_SCENARIO_LIST:
      return {
        ...state,
        lists: action.payload ? [...action.payload] : action.payload,
      };

    /**
     * 시나리오 추가
     */
    case ADD_SCENARIO:
      return {
        ...state,
        lists: state.lists ? state.lists.concat(action.payload) : [action.payload],
      };

    /**
     * 시나리오 업데이트
     */
    case UPDATE_SCENARIO:
      return {
        ...state,
        lists: state.lists
          ? state.lists.map((item: any) => {
              if (item._id === action.payload._id) {
                return action.payload;
              }
              return item;
            })
          : state.lists,
      };

    /**
     * 활성 시나리오
     */
    case ACTIVE_SCENARIO:
      /** 활성 시나리오 데이터 로컬스토리지에 저장 */
      // localStorage.setItem('active-scenario', JSON.stringify(action.payload));
      return {
        ...state,
        active: action.payload,
      };

    /**
     * 시나리오 편집 및 생성용 데이터 리셋
     */
    case RESET_EDIT_SCENARIO_DATA:
      return {
        ...state,
        editScenarioData: Types.editScenarioDataOptions,
      };

    /**
     * 시나리오 편집 및 생성용 데이터 업데이트
     */
    case UPDATE_EDIT_SCENARIO_DATA:
      if (action.payload.key === 'setData') {
        return {
          ...state,
          editScenarioData: {
            ...action.payload.value,
            isErrors: {},
          },
        };
      }
      if (action.payload.key === 'isErrors') {
        return {
          ...state,
          editScenarioData: {
            ...state.editScenarioData,
            isErrors: action.payload.value,
          },
        };
      }
      return {
        ...state,
        editScenarioData: {
          ...state.editScenarioData,
          isErrors: {},
          [action.payload.key]: action.payload.value,
        },
      };

    /**
     * 시나리오 편집 팝업 오픈상태 업데이트
     */
    case UPDATE_EDIT_POPUP_OPEN:
      return {
        ...state,
        isModifyOpen: action.payload.open,
        isNew: action.payload.isNew,
      };
    /**
     * 읽기 전용
     */
    case UPDATE_READ_ONLY:
      return {
        ...state,
        isReadOnly: action.payload,
      };
    /**
     * 읽기 전용
     */
    case UPDATE_TEMPLATE_SCENARIO:
      return {
        ...state,
        isTemplate: action.payload,
      };

    default:
      return state;
  }
}

export default Scenarios;
