import styled from 'styled-components';

interface FlexWrapProps {
  top?: number | undefined;
  right?: number | undefined;
  bottom?: number | undefined;
  left?: number | undefined;
  zIndex?: number | undefined;
}

/** 페이지 딤드 */
const Dimmed = styled.div<FlexWrapProps>`
  position: absolute;
  top: ${(props) => props.top || 0}px;
  right: ${(props) => props.right || 0}px;
  bottom: ${(props) => props.bottom || 0}px;
  left: ${(props) => props.left || 0}px;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: ${(props) => props.zIndex || 1000000};
`;

/** 투명 딤드 */
export const LockDimmed = styled(Dimmed)`
  background-color: rgba(0, 0, 0, 0);
`;

export default Dimmed;
