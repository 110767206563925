import { Text } from '../index';
import React from 'react';
import { Props } from '../../Interfaces/Label';
import { BaseLabel } from '../../Styles/Label';

const Label = ({ required, label, size = 'large', className }: Props) => {
  return (
    <BaseLabel size={size} className={className}>
      {required && (
        <Text type={'custom'} fontSize={'14px'} color={'var(--red)'}>
          *
        </Text>
      )}
      <Text type={'body-title'}>{label}</Text>
    </BaseLabel>
  );
};

export default Label;
