import styled from '@emotion/styled';
import { css } from '@emotion/react';

const getColor = (color: string) => {
  switch (color) {
    case 'blue':
      return css`
        background-color: var(--blue-fill);
      `;
    case 'red':
      return css`
        background-color: var(--red-fill);
      `;
    case 'yellow':
      return css`
        background-color: var(--brandFill-syncFill, rgba(243, 194, 27, 0.1));
      `;
    case 'black':
    default:
      return css`
        background-color: var(--tertiary-fill);
      `;
  }
};

export const BaseTip = styled.div<{ color: string }>`
  position: relative;
  transition: all 0.2s;
  padding: 12px 16px;
  overflow: auto;

  align-self: stretch;
  box-shadow: none;

  border-radius: 8px;

  ${(props) => [getColor(props.color)]};

  :hover {
    .hoverLayer {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 8px;
      background: var(--interaction-secondaryInteraction, rgba(0, 0, 0, 0.05));
    }
  }
`;

export const TipContents = styled.div`
  display: flex;

  letter-spacing: -0.28px;
  line-height: 1.42;
  font-weight: 500;
  font-size: 14px;

  gap: 8px;

  white-space: pre-wrap;
`;

export const ImageContents = styled.div``;
export const LinkButtonContents = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 7px 0;

  float: right;

  font-size: 13px;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.18px;
  background: transparent;
`;
