export const ENV = process.env.REACT_APP_ENV;
export const PRO = 'production';
export const STG = 'stg';
export const DEV = 'development';
export const LOCAL = 'local';

export const API_HOST = process.env.REACT_APP_API_HOST;

export const ARS_URL = process.env.REACT_APP_ARS_URL;
export const API_PATH = process.env.REACT_APP_API_PATH;

export const USERFLOW_KEY = process.env.REACT_APP_USERFLOW_KEY;
export const API_V2_PATH = process.env.REACT_APP_API_V2_PATH;
