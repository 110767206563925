import styled from 'styled-components';

const PageTitle = styled.div`
  width: 100%;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: -0.5px;
  color: var(--black);
  padding: 30px 0 3px 40px;

  button {
    position: absolute;
    margin-left: 15px;
    top: 75px;
  }
`;

export default PageTitle;
