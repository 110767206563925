import styled from 'styled-components';
import * as React from 'react';
import { Resizable } from 're-resizable';

const defaultStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
} as const;

export const ResizeWrraper = styled.div`
  .dragging:active {
    height: 100%;
    position: relative;
    right: -3px !important;
    width: 3px !important;
    background: #f77f78;
  }
  .leftDragging:active {
    height: 100%;
    position: relative;
    left: -3px !important;
    width: 3px !important;
    background: #f77f78;
    .dragHandle {
      display: none;
    }
  }
  .rightDragging:active {
    height: 100%;
    position: relative;
    right: -3px !important;
    width: 3px !important;
    background: #f77f78;
    .dragHandle {
      display: none;
    }
  }
`;

interface ResizableProps {
  width: number | string;
  height: number | string;
  setWidth?: React.Dispatch<React.SetStateAction<any>>;
  setHeight?: React.Dispatch<React.SetStateAction<any>>;
  startResize?: (a?: any) => void;
  stopResize?: (a?: any) => void;
  className?: string;
  minWidth?: number | string;
  minHeight?: number | string;
  maxWidth?: number | string;
  maxHeight?: number | string;
  enable?: any;
  customStyle?: { [key: string]: string };
  handleComponent?: any;
  children?: React.ReactNode;
}

const ResizableElement: React.FC<ResizableProps> = ({
  width,
  height,
  setWidth,
  setHeight,
  startResize,
  stopResize,
  className,
  minWidth,
  maxWidth,
  minHeight,
  maxHeight,
  enable,
  customStyle,
  handleComponent,
  children,
}) => {
  return (
    <ResizeWrraper>
      <Resizable
        style={customStyle ? customStyle : defaultStyle}
        size={{ width, height }}
        maxWidth={maxWidth}
        minWidth={minWidth}
        maxHeight={maxHeight}
        minHeight={minHeight}
        className={className}
        enable={enable}
        onResizeStart={startResize}
        onResizeStop={(e, direction, ref, d) => {
          setWidth && setWidth((width as any) + d.width);
          setHeight && setHeight((height as any) + d.height);
          stopResize && stopResize();
        }}
        handleComponent={handleComponent}
        handleClasses={{ top: 'dragging', right: 'rightDragging', left: 'leftDragging', bottom: 'dragging' }}
      >
        {children}
      </Resizable>
    </ResizeWrraper>
  );
};

export default React.memo(ResizableElement);
