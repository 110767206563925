import styled from 'styled-components';

interface FlexWrapProps {
  inline?: boolean;
  margin?: string | undefined;
  flexWrap?: 'wrap' | 'nowrap' | 'inherit';
  nowrap?: boolean;
}

const FlexWrap = styled.div<FlexWrapProps>`
  display: ${(props) => (props.inline ? 'inline-flex' : 'flex')};
  flex-wrap: ${(props) => (props.flexWrap ? props.flexWrap : 'wrap')};
  ${(props) => props.nowrap && 'flex-flow: nowrap;'}
  margin: ${(props) => (props.margin ? props.margin : 0)};
`;

export default FlexWrap;
