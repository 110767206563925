/**
 * 멤버 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import { DefaultBlockLimit } from 'constants/Block';
import { DefaultScenarioLimit } from 'constants/Scenario';
import { MemberProps } from '../../constants/Types';

/** 멤버 추가 */
const INSERT = 'member/INSERT' as const;
/**socketId 추가 */
const SAVE_SOCKET_ID = 'member/SAVE_SOCKET_ID' as const;

/**
 * 멤버추가 액션
 * @param member
 */
export const insertMember = (member: MemberProps) => ({ type: INSERT, payload: member });
export const saveSocketID = (socketId: string) => ({
  type: SAVE_SOCKET_ID,
  payload: socketId,
});

/**action type */
type MemberAction = ReturnType<typeof insertMember> | ReturnType<typeof saveSocketID>;

/**
 * 초기값
 */
const initialState: MemberProps = {
  providerData: {
    siteId: undefined,
    partnerId: undefined,
  },
  _id: undefined,
  name: undefined,
  email: undefined,
  state: undefined,
  provider: undefined,
  refreshToken: undefined,
  isSuperAdmin: false,
  phone: undefined,
  country: undefined,
  profileThumbnail: undefined,
  config: {
    isRating: true,
    scenarioLimitCount: DefaultScenarioLimit,
    blockLimitCount: DefaultBlockLimit,
  },
  socketId: undefined,
};

function member(state: MemberProps = initialState, action: MemberAction) {
  switch (action.type) {
    case INSERT:
      return { ...state, ...action.payload };
    case SAVE_SOCKET_ID:
      return {
        ...state,
        socketId: action.payload,
      };
    default:
      return state;
  }
}

export default member;
