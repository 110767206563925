import { NlpAiLearnInterface, NlpAgentType, NlpAiIntent, NlpAiIntentCategory } from 'constants/Types';

const NLP_AGENT_LIST = 'nlp/GET_AGENT_LIST' as const;
const NLP_AGENT = 'nlp/GET_AGENT' as const;
const NLP_DELETE_AGENT = 'nlp/DELETE_AGENT' as const;
const NLP_INTENT_LIST = 'nlp/GET_INTENT_LIST' as const;
const NLP_INTENT_REFRESH = 'nlp/INTENT_REFRESH' as const;
const NLP_INTENT_CATEGORY_LIST = 'nlp/GET_INTENT_CATEGORY_LIST' as const;
const NLP_DELETE_INTENT = 'nlp/DELETE_INTENT' as const;
const GET_AGENT_INTENT_WORD_LIST = 'nlp/GET_AGENT_INTENT_WORD_LIST' as const;
const GET_AGENT_LEARN_LIST = 'nlp/GET_AGENT_LEARN_LIST' as const;
const GET_AGENT_LEARN = 'nlp/GET_AGENT_LEARN' as const;

export interface NlpProps {
  agentList: NlpAgentType[];
  agent: NlpAgentType | undefined;
  agentIntentWordList: string[];
  intentList: NlpAiIntent[];
  intentCategoryList: NlpAiIntentCategory[];
  intentListRefreshFlag: boolean;
  agentLearnList: NlpAiLearnInterface[];
  agentLearn: NlpAiLearnInterface | undefined;
}
const initialState: NlpProps = {
  agentList: [],
  agent: undefined,
  agentIntentWordList: [],
  intentList: [],
  intentCategoryList: [],
  intentListRefreshFlag: false,
  agentLearnList: [],
  agentLearn: undefined,
};

export const getNlpAgentList = (list: NlpAgentType[]) => ({
  type: NLP_AGENT_LIST,
  payload: list,
});

export const getNlpAgent = (payload: NlpAgentType) => ({
  type: NLP_AGENT,
  payload: payload,
});

export const getNlpIntentList = (list: NlpAiIntent[]) => ({
  type: NLP_INTENT_LIST,
  payload: list,
});

export const getNlpIntentCategoryList = (list: NlpAiIntentCategory[]) => ({
  type: NLP_INTENT_CATEGORY_LIST,
  payload: list,
});

export const deleteAgent = (agentId: string) => ({
  type: NLP_DELETE_AGENT,
  payload: agentId,
});

export const deleteIntent = (intentId: string) => ({
  type: NLP_DELETE_INTENT,
  payload: intentId,
});

export const refreshNlpIntentList = (isRefresh: boolean) => ({
  type: NLP_INTENT_REFRESH,
  payload: isRefresh,
});

export const getNlpAgentIntentWordList = (payload: string[]) => ({
  payload,
  type: GET_AGENT_INTENT_WORD_LIST,
});

export const getAgentLearnList = (payload: NlpAiLearnInterface[]) => ({
  payload,
  type: GET_AGENT_LEARN_LIST,
});

export const getAgentLearn = (payload: NlpAiLearnInterface) => ({
  payload,
  type: GET_AGENT_LEARN,
});

type NlpActionType =
  | typeof getNlpAgentList
  | typeof getNlpAgent
  | typeof deleteAgent
  | typeof getNlpIntentList
  | typeof getNlpIntentCategoryList
  | typeof deleteIntent
  | typeof refreshNlpIntentList
  | typeof getNlpAgentIntentWordList
  | typeof getAgentLearn
  | typeof getAgentLearnList;

function Nlp(state: NlpProps = initialState, action: ReturnType<NlpActionType>) {
  switch (action.type) {
    case NLP_INTENT_LIST:
      return {
        ...state,
        intentList: action.payload,
      };
    case NLP_INTENT_CATEGORY_LIST:
      return {
        ...state,
        intentCategoryList: action.payload,
      };

    case NLP_AGENT_LIST:
      return {
        ...state,
        agentList: action.payload,
      };
    case NLP_AGENT:
      return {
        ...state,
        agent: action.payload,
      };
    case NLP_DELETE_AGENT:
      const agentList = state.agentList.filter(({ _id }) => _id !== action.payload);

      return {
        ...state,
        agentList: agentList,
        agent: undefined,
      };

    case NLP_DELETE_INTENT:
      const intentList = state.intentList.filter(({ _id }) => _id !== action.payload);

      return {
        ...state,
        intentList: intentList,
      };

    case NLP_INTENT_REFRESH:
      return {
        ...state,
        intentListRefreshFlag: action.payload,
      };

    case GET_AGENT_INTENT_WORD_LIST:
      return {
        ...state,
        agentIntentWordList: action.payload,
      };

    case GET_AGENT_LEARN_LIST:
      return {
        ...state,
        agentLearnList: action.payload,
      };

    case GET_AGENT_LEARN:
      return {
        ...state,
        agentLearn: action.payload,
      };

    default: {
      return state;
    }
  }
}

export default Nlp;
