const GET_STAT_DATA = 'statistics/GET_STAT_DATA' as const;
const GET_USER_STAT_DATA = 'statistics/GET_USER_STAT_DATA' as const;
const GET_CHAT_STAT_DATA = 'statistics/GET_CHAT_STAT_DATA' as const;

const UPDATE_STAT_DATA = 'statistics/UPDATE_STAT_DATA' as const;
const UPDATE_USER_STAT_DATA = 'statistics/UPDATE_USER_STAT_DATA' as const;
const UPDATE_CHAT_STAT_DATA = 'statistics/UPDATE_CHAT_STAT_DATA' as const;

export const getStatData = (stat: StatDataProps) => ({
  type: GET_STAT_DATA,
  payload: stat,
});
export const updateStatData = (stat: any, target: string) => ({
  type: UPDATE_STAT_DATA,
  target: target,
  payload: stat,
});

export const getUserStatData = (stat: StatDataProps) => ({
  type: GET_USER_STAT_DATA,
  payload: stat,
});

export const getChatStatData = (stat: StatDataProps) => ({
  type: GET_CHAT_STAT_DATA,
  payload: stat,
});

interface StatDataProps {
  dashboard: any | null;
  endUser: any | null;
  chatting: any | null;
}

const initialState = {
  dashboard: {
    scenario: undefined,
    block: undefined,
    user: undefined,
    msg: undefined,
    channel: undefined,
    hourlyChannel: undefined,
    bot: undefined,
    avg: undefined,
  },
  endUser: undefined,
  chatting: undefined,
};

type StatDataAction =
  | ReturnType<typeof getStatData>
  | ReturnType<typeof updateStatData>
  | ReturnType<typeof getUserStatData>
  | ReturnType<typeof getChatStatData>;

function StatData(state: StatDataProps = initialState, action: StatDataAction) {
  switch (action.type) {
    case GET_STAT_DATA: {
      return {
        ...state,
        dashboard: action.payload,
      };
    }
    case UPDATE_STAT_DATA: {
      if (state.dashboard && action.target in state.dashboard) {
        state.dashboard[action.target] = action.payload[action.target];
        return state;
      } else {
        return {
          ...state,
          dashboard: {
            ...state.dashboard,
            [action.target]: action.payload[action.target],
          },
        };
      }
    }
    case GET_USER_STAT_DATA: {
      return {
        ...state,
        endUser: action.payload,
      };
    }
    case GET_CHAT_STAT_DATA: {
      return {
        ...state,
        chatting: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default StatData;
