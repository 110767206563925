export default {
  /* 로그인, 아이디 찾기, 비밀번호 찾기, 회원가입, 비밀번호 변경 */
  auth: {
    id: {
      guide: '아이디를 입력하세요.',
      find: {
        title: '아이디 찾기',
        msg: '등록된 이름, 휴대전화 번호를 입력 후 [아이디 찾기] 버튼을 클릭하세요.',
      },
    },
    email: {
      title: '이메일',
      guide: '이메일을 입력하세요.',
      error: '이메일 정보가 양식에 맞지 않습니다. 다시 한번 확인하세요.',
    },
    name: {
      title: '이름',
      guide: '이름을 입력하세요.',
    },
    phone: {
      title: '휴대전화',
      guide: '휴대전화 번호를 입력하세요.',
      error: '휴대전화 번호 양식에 맞지 않습니다. 다시 한번 확인하세요.',
    },
    code: {
      title: '인증번호',
      guide: '인증번호를 입력하세요.',
    },
    etc: {
      blank: '공백을 입력하지 마세요.',
    },
    pw: {
      title: '비밀번호',
      guide: '비밀번호를 입력하세요.',
      error: '영문, 숫자, 특수문자를 모두 포함하여 입력하세요. (8~32자)',
      reTitle: '비밀번호 재입력',
      reGuide: '비밀번호를 한번 더 입력하세요',
      reError: '비밀번호가 일치하지 않습니다.',
      origin: {
        title: '기존 비밀번호',
        guide: '기존 비밀번호를 입력하세요.',
        error: '비밀번호를 정확하게 입력하세요.',
      },
      new: {
        title: '새 비밀번호',
        reTitle: '새 비밀번호 확인',
        guide: '새 비밀번호를 한번 더 입력하세요.',
        error: '비밀번호가 새 비밀번호와 일치하지 않습니다.',
      },
      find: {
        title: '비밀번호 찾기',
        msg:
          '등록한 이름과 휴대전화 번호를 입력하시고, [인증] 버튼을 눌러 주세요.' +
          '이메일을 통해 받으신 인증번호를 입력 후, [비밀번호 변경] 버튼을 눌러주세요.',
      },
      change: {
        title: '비밀번호 변경 안내',
        guide: '비밀번호는 최소 8자리 이상 32자리 이하로 만들어야 합니다.\n' + '비밀번호는 영문 + 숫자 + 특수문자를 모두 포함하여 사용해야 합니다.',
        notice:
          '방송통신위원회 고시 [개인정보의 기술적관리적 보호조치 기준]에 따라 \n' +
          '3개월 이상 비밀번호를 변경하지 않은 경우 변경을 권장하고 있습니다.',
        msg: '소중한 개인정보 보호를 위해 비밀번호를 변경해 주세요.',
      },
    },
    btn: {
      login: '로그인',
      change: '변경하기',
      changePw: '비밀번호 변경',
      next: '다음에 변경',
      other: '2주 후 변경',
      cancel: '취소',
      idFind: '아이디 찾기',
      pwFind: '비밀번호 찾기',
      signUp: '회원가입',
    },
    login: {
      title: '로그인',
      error: '이메일 또는 비밀번호를 정확히 입력하세요. \n(5회 이상 입력 오류시, 10분간 로그인 제한)',
      keep: '로그인 유지시간',
      prevent: {
        title: '로그인 제한',
        msg:
          '이메일 또는 비밀번호 입력 5회 이상 오류로 10분 동안 로그인이 제한됩니다.' +
          '10분 후에 다시 시도하세요. (10분 후 자동 해제 및 로그인 페이지로 이동)',
        time: '제한 해제까지 남은 시간',
      },
    },
    agree: {
      all: '모두 동의하기',
      fOne:
        '어썸봇 <a href="agree?type=terms" target="_blank">서비스 이용약관</a> 및 ' +
        '<a href="agree?type=policies" target="_blank">개인정보 처리방침</a>에 동의 합니다. (필수)',
      sOne: '<a href="agree?type=marketing" target="_blank">마케팅 정보 수신</a>에 동의합니다. (선택)',
    },
  },
  /**마이페이지 */
  account: {
    profile: {
      title: '기본 프로필',
      msg: '고객들에게는 프로필 사진과 이름나 노출됩니다.\n이미지를 클릭하여 프로필 사진을 변경하세요.',
      imgTitle: '프로필',
      setUp: '변경 초기화',
      change: '회원정보 변경',
    },

    security: {
      title: '보안',
      msg:
        '어썸봇 로그인 시 사용하는 비밀번호를 변경하거나 안전한 로그인을 위한 2단계 인증 기능을 설정 할 수 있습니다.' +
        '\n비밀번호를 안전하게 보호하세요.',
      /* 이차인증 */
      second: {
        title: '2단계 인증 ',
        google: 'Google OTP',
        toGuide: '2단계 인증 가이드',
        on: '2단계 인증이 설정 되었습니다.',
        off: '2단계 인증이 해제 되었습니다.',
      },
      /* 동시접속 */
      concurrent: {
        dTitle: '동시 접속 확인', //dialog Title
        title: '동시 접속 차단',
        guide: '다른 디바이스에서 동일 아이디의 접속을 차단합니다.',
        on: '동시 접속 차단이 설정되었습니다.',
        off: '동시 접속 차단이 해제 되었습니다.',
        relogin: '다른 기기에서 로그인 중입니다.\n강제 로그아웃 후, 현재 기기에서 로그인 하시겠습니까?',
        logout: '다른 기기에서 로그인 되어 현재 기기에서 로그아웃 되었습니다.',
      },
    },

    info: {
      title: '회원탈퇴',
      msg: '회원탈퇴는 1666-5263 또는 happytalk@happytalk.io 로 문의 주시기 바랍니다.',
    },
  },
  otp: {
    //SecondaryLogin //SecondaryLoginForm
    login: {
      title: '2단계 인증(Google OTP 인증)',
      msg: 'Google OTP 앱에서 생성된 인증코드를 확인 후 입력하세요.',
      subTitle: 'OTP 인증번호',
      error: '인증코드가 정확하지 않습니다.',
    },
    //OTPSecondSecurity
    cert: {
      guide: '인증코드를 입력하세요.',
      error: '인증코드를 정확하게 입력하세요',
      title: '아래 설정 절차를 진행해 주세요.',
      msg: '① Google OTP 앱 설치\n' + '② 앱 실행 후 아래 QR코드 스캔 (또는 설정 코드 입력)\n' + "③ 생성된 인증코드 입력 후 '확인' 버튼 클릭",
    },
    //SecondGuide
    title: '구글 OTP 인증 가이드',
    firstStep: {
      title: '인증 앱 설치하기',
      mainMsg: '휴대전화에 구글 OTP 앱을 설치해 주세요.',
      subMsg: '',
    },
    secondStep: {
      title: '인증 앱 실행하기',
      mainMsg: '휴대전화에 설치한 구글 OTP 앱을 실행해 주세요.',
      subMsg: '우측 하단의 추가하기 버튼을 눌러 주세요.\nQR코드 스캔하기를 선택하고 아래의 QR코드를 촬영해 주세요.',
    },
    thirdStep: {
      title: '인증코드 발급 및 입력하기',
      mainMsg: '구글 OTP 앱에서 등록한 사이트 주소/계정에 해당하는 6자리 인증코드를 확인해 주세요.',
      subMsg: '인증코드를 입력해 주세요.',
    },
  },
};
