// import * as Types from '../../constants/Types';

/** 선택된 블럭 업데이트 */
const UPDATE_SELECTED_BLOCK = 'blockEditor/UPDATE_SELECTED_BLOCK' as const;
const UPDATE_COUNT_BLOCK = 'blockEditor/UPDATE_COUNT_BLOCK' as const;
const UPDATE_TEMPLATE_BLOCK = 'blockEditor/UPDATE_TEMPLATE_BLOCK' as const;
const UPDATE_SCENARIO_BLOCK = 'blockEditor/UPDATE_SCENARIO_BLOCK' as const;
const UPDATE_SEARCH_BLOCK = 'blockEditor/UPDATE_SEARCH_BLOCK' as const;

/**
 * 액션
 */
export const updateSelectedBlock = (block: { scenarioId: string; blockId: string; item: any }) => ({
  type: UPDATE_SELECTED_BLOCK,
  payload: block,
});

export const updateBlockCount = (count: number) => ({
  type: UPDATE_COUNT_BLOCK,
  count: count,
});

export const updateTemplateBlock = (block: any) => ({
  type: UPDATE_TEMPLATE_BLOCK,
  payload: block,
});

export const updateScenarioBlock = (block: any) => ({
  type: UPDATE_SCENARIO_BLOCK,
  payload: block,
});

export const updateSearchBlock = (block: any) => ({
  type: UPDATE_SEARCH_BLOCK,
  payload: block,
});

/**
 * 액션 타입 그룹
 */
type BlockEditorAction =
  | ReturnType<typeof updateSelectedBlock>
  | ReturnType<typeof updateBlockCount>
  | ReturnType<typeof updateTemplateBlock>
  | ReturnType<typeof updateScenarioBlock>
  | ReturnType<typeof updateSearchBlock>;

/**
 * 초기값 타입
 */
interface BlockEditorState {
  selectedCnt: number;
  selectedBlock: any | undefined;
  templateBlock: any | undefined;
  scenarioBlock: any | undefined;
  totalCount: number;
  searchBlock: {
    blockId: string;
    use: boolean;
  };
}

/**
 * 초기값
 */
const initialState: BlockEditorState = {
  selectedCnt: 0,
  selectedBlock: {
    // subItem: undefined,
    scenarioId: '',
    blockId: '',
    item: undefined,
  },
  templateBlock: undefined,
  scenarioBlock: undefined,
  totalCount: 0,
  searchBlock: {
    blockId: '',
    use: false,
  },
};

function blockEditor(state: BlockEditorState = initialState, action: BlockEditorAction) {
  switch (action.type) {
    /**
     * 액티브 블럭 업데이트
     */
    case UPDATE_SELECTED_BLOCK:
      return {
        ...state,
        selectedCnt: state.selectedCnt + 1,
        selectedBlock: {
          // subItem: undefined,
          scenarioId: action.payload.scenarioId,
          blockId: action.payload.blockId,
          item: action.payload.item,
        },
      };

    case UPDATE_COUNT_BLOCK:
      return {
        ...state,
        totalCount: action.count,
      };

    case UPDATE_TEMPLATE_BLOCK:
      return {
        ...state,
        templateBlock: action.payload,
      };

    case UPDATE_SCENARIO_BLOCK:
      return {
        ...state,
        scenarioBlock: action.payload,
      };

    case UPDATE_SEARCH_BLOCK:
      return {
        ...state,
        searchBlock: action.payload,
      };
    default:
      return state;
  }
}

export default blockEditor;
