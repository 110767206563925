import styled from '@emotion/styled';
import { Props, StyleProps } from '../../Interfaces/Tab';
import { css } from '@emotion/react';

const sectionStyle = css`
  margin-top: -8px;
  padding: 8px 0;
  color: var(--secondary-black);
  font-size: 17px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.43px;
  line-height: 1.33;
  cursor: pointer;
  &.active {
    box-shadow: inset 0 -2px 0 0 var(--primary-color-bot);
    color: var(--primary-color-bot);
  }
  .disabled {
    cursor: not-allowed;
    color: var(--tertiary-black);
  }
  :hover {
    background-color: var(--black-5);
  }
`;

const cardStyle = css`
  padding: 4px 8px;
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: -0.23px;
  cursor: pointer;
  line-height: 1.33;
  color: var(--secondary-black);
  &.active {
    color: var(--primary-color-bot);
    text-decoration: underline;
  }
  .disabled {
    cursor: not-allowed;
    color: var(--tertiary-black);
  }
  :hover {
    background-color: var(--black-5);
  }
`;

const tabTypeStyle = ({ type }: StyleProps) => {
  switch (type) {
    case 'card':
      return cardStyle;
    case 'section':
      return sectionStyle;
    case 'page':
  }
};
export const BaseTab = styled.div`
  display: inline-flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  vertical-align: top;
`;

export const BaseTabItem = styled.div<StyleProps>`
  ${tabTypeStyle};
  transition: all 0.2s;
`;
