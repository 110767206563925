import styled from 'styled-components';

export const Container = styled.span<{ width?: number }>`
  display: inline-block;
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  .react-datepicker__header {
    background-color: #fff;
  }
  .react-datepicker-popper {
    z-index: 99 !important;
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom'] {
    border-top: none;
    border-bottom-color: #fff;
    :after {
      border-bottom-color: #fff;
    }
  }

  .react-datepicker--time-only .react-datepicker__triangle {
    left: -36px !important;
  }

  .react-datepicker__day .react-datepicker__day-name {
    width: 2rem;
    line-height: 2rem;
    margin: 0.2rem;
  }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range {
    border-radius: 1rem;
    background-color: #4690dd;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    border-radius: 1rem;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    padding: 8px;
    font-size: 1rem;
  }
  .react-datepicker__navigation {
    top: 10px;
  }
  .react-datepicker__header {
    border-bottom: none;
  }
  .react-datepicker__month-container {
    .react-datepicker__month-text {
      display: inline-block;
      width: 3rem;
      height: 2rem;
      line-height: 2rem;
      margin: 3px;
    }
    .react-datepicker__month--selected,
    .react-datepicker__month-text--keyboard-selected {
      background-color: #4690dd;
      border-radius: 1rem;
    }
  }

  .react-datepicker__time-container
    .react-datepicker__time
    .react-datepicker__time-box
    ul.react-datepicker__time-list
    li.react-datepicker__time-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    &.react-datepicker__time-list-item--selected {
      background-color: #4690dd;
    }
  }

  &.isDisabledNone {
    .react-datepicker__time-list-item--disabled {
      display: none !important;
    }
  }

  .customCal {
    display: flex;
  }
  .withTime {
    &.customCal {
      left: 8px;
    }
  }
`;

export const DateButton = styled.button<{ isOneDir?: boolean; height?: number }>`
  display: flex;
  width: 100%;
  ${(props) => props.height && `height: ${props.height}px;`}

  align-items: center;
  line-height: 20px;
  padding: 0 10px;
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;

  .icon {
    display: flex;
    font-size: 20px;
    cursor: pointer;
    margin-right: 10px;
  }
  .placeholder {
    color: var(--brown-grey);
    font-size: 12px;
  }
  &.isDisabled {
    cursor: not-allowed;
    background: none;
    .icon {
      display: none;
      font-size: 20px;
      cursor: not-allowed;
      margin-right: 10px;
    }
  }
`;
