import React, { useCallback } from 'react';
import { AlertIcon, External, ExclamationMark } from 'constants/Icons';
import { InfoTipProps } from '../../Interfaces/InfoTip';
import { BaseTip, ImageContents, LinkButtonContents, TipContents } from '../../Styles/InfoTip';

const InfoTip = ({
  color = 'black',
  iconType = 'alert',
  useIcon = true,
  iconComponent,
  children,
  linkButton,
}: InfoTipProps) => {
  const getColor = useCallback(() => {
    switch (color) {
      case 'blue': //advice
        return 'var(--blue)';
      case 'red': //error
        return `var(--red)`;
      case 'yellow':
        return `#FFD549`;
      case 'black':
      default:
        // info
        return `var(--primary-black)`;
    }
  }, [iconType]);

  const iconStyle = { width: '16px', height: '16px' };
  const GetIcon = useCallback(() => {
    switch (iconType) {
      case 'alert':
        return <AlertIcon style={iconStyle} fill={getColor()} />;
      case 'exclamation':
        return <ExclamationMark style={iconStyle} fill={getColor()} />;
      case 'custom':
        return iconComponent || <></>;
      case 'none':
      default:
        return <></>;
    }
  }, [iconType, iconComponent]);

  return (
    <BaseTip color={color}>
      {linkButton?.use && <div className={'hoverLayer'} />}
      <TipContents>
        {useIcon && <GetIcon />}

        {children}
      </TipContents>
      <ImageContents />
    </BaseTip>
  );
};

export default InfoTip;
