import styled from 'styled-components';
import * as React from 'react';
import { MdFileDownload } from 'react-icons/md';

import { CSVLink, CSVDownload } from 'react-csv';
import { Button } from 'happytalk-design-guide';

const Container = styled.span`
  display: inline-block;
  .icon {
    line-height: 0;
  }
`;

interface CSVDownloadProps {
  headers?: any[];
  data: any[];
  fileName: string;
  disabled?: boolean;
}

const CSVDownloadWrapper: React.FC<CSVDownloadProps> = ({ headers, data, fileName, disabled }) => {
  const linkRef = React.useRef<any>(null);

  const onLinkClick = (e: any) => {
    if (linkRef.current) {
      linkRef.current.link.click();
    }
  };

  return (
    <Container>
      <Button className="icon" theme={'blue'} onClick={onLinkClick} disabled={disabled ? disabled : false}>
        <MdFileDownload />
      </Button>
      <CSVLink data={data} headers={headers} filename={fileName} ref={linkRef} />
    </Container>
  );
};

export default React.memo(CSVDownloadWrapper);
