import React from 'react';
import { Props } from '../../Interfaces/Flex';
import { BaseFlex } from '../../Styles/Flex';

const Flex = ({ children, size, vertical, justify, align, className }: Props) => {
  return (
    <BaseFlex className={className} size={size} vertical={vertical} justify={justify} align={align}>
      {children}
    </BaseFlex>
  );
};

export default Flex;
