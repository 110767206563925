import { statListType, statListItemsType } from '../../constants/Types';

const GET_STAT_LIST = 'statList/GET_LIST' as const;

/**
 * 액션
 * @param statList
 * */

export const getStatList = (statList: statListType) => ({
  type: GET_STAT_LIST,
  payload: statList,
});

interface StatListProps {
  lists: statListItemsType[];
  bot: {
    botCount: number;
  };
  active: statListItemsType | null;
}

const initialState = {
  lists: [],
  bot: {
    botCount: 0,
  },
  active: null,
};

type StatListAction = ReturnType<typeof getStatList>;

function StatList(state: StatListProps = initialState, action: StatListAction) {
  switch (action.type) {
    case GET_STAT_LIST: {
      return {
        ...state,
        lists: action.payload.list.map((item: statListItemsType) => {
          return {
            day: item.day,
            bot: item.bot,
            user: item.user,
            userM: item.userM,
            botM: item.botM,
            total: item.total,
          };
        }),
        bot: {
          botCount: action.payload.botCount,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default StatList;
