import styled from 'styled-components';

const PageTitle = styled.div`
  width: 100%;
  font-size: 12px;
  letter-spacing: -0.5px;
  color: var(--brown-grey);
  padding: 10px 0 3px 32px;
`;

export default PageTitle;
