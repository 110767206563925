import styled from '@emotion/styled';
import { StyleProps } from '../../Interfaces/Label';
import { css } from '@emotion/react';

const labelSize = ({ size }: StyleProps) => {
  switch (size) {
    case 'small':
      return css`
        width: 30px;
        min-width: auto;
      `;
    case 'medium':
      return css`
        width: 60px;
        min-width: auto;
      `;
    case 'large':
      return css`
        width: 100px;
        min-width: auto;
      `;
    default:
      return;
  }
};

export const BaseLabel = styled.div<StyleProps>`
  //margin-right: 16px;
  text-align: right;
  display: inline-flex;
  gap: 8px;
  vertical-align: top;
  ${labelSize}
`;
