import styled from '@emotion/styled';
import { ChipItemStyleProps, ChipStyleProps } from '../../Interfaces/Chip';

export const BaseChipItem = styled.div<ChipItemStyleProps>`
  padding: 6px 12px;

  display: inline-flex;
  align-items: center;
  gap: 4px;
  flex-shrink: 0;

  border-radius: 100px;

  background-color: var(--tertiary-fill);
  color: var(--primary-black);

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.28px;

  transition-duration: 0.2s;

  cursor: pointer;

  &.active {
    color: var(--primary-color-bot);
  }
`;

export const BaseChipList = styled.div<ChipStyleProps>`
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
`;
