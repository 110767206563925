/**
 * 봇 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import { AgentProps, BotProps } from '../../constants/Types';

/** 전체 봇 업데이트 */
const UPDATE_ALL_BOTS = 'bots/UPDATE_ALL_BOTS' as const;
/** 봇 추가 */
const ADD_BOT = 'bots/ADD_BOT' as const;
/** 봇 액티브 */
const UPDATE_ACTIVE_BOT = 'bots/UPDATE_ACTIVE_BOT' as const;
/** 봇 카테고리 */
const UPDATE_ALL_CATEGORY_LIST = 'bots/UPDATE_ALL_CATEGORY_LIST' as const;
const UPDATE_ACTIVE_AGENT = 'bots/UPDATE_ACTIVE_AGENT' as const;
const UPDATE_BOT_CUSTOM_OPTION_USE = 'bots/UPDATE_BOT_CUSTOM_OPTION_USE' as const;

/**
 * 액션
 * @param bots
 */
export const updateAllBots = (bots: any) => ({
  type: UPDATE_ALL_BOTS,
  payload: bots,
});
export const addBot = (bot: BotProps) => ({
  type: ADD_BOT,
  payload: bot,
});
export const updateActiveBot = (bot: BotProps | null) => ({
  type: UPDATE_ACTIVE_BOT,
  payload: bot,
});
export const updateAllCategoryList = (list: any | null) => ({
  type: UPDATE_ALL_CATEGORY_LIST,
  payload: list,
});
export const updateActiveAgent = (agent: AgentProps) => ({
  type: UPDATE_ACTIVE_AGENT,
  payload: agent,
});
export const updateBotCustomOptionUse = (obj: { key: string; value: boolean }) => ({
  type: UPDATE_BOT_CUSTOM_OPTION_USE,
  payload: obj,
});

/**
 * 액션 타입 그룹
 */
type BotsAction =
  | ReturnType<typeof updateAllBots>
  | ReturnType<typeof addBot>
  | ReturnType<typeof updateActiveBot>
  | ReturnType<typeof updateAllCategoryList>
  | ReturnType<typeof updateActiveAgent>
  | ReturnType<typeof updateBotCustomOptionUse>;

/**
 * 초기값 타입
 */
interface BotsProps {
  lists: BotProps[];
  active: any | undefined;
  activeAgent: AgentProps | undefined;
  category: any | undefined;
}

/**
 * 초기값
 */
const initialState = {
  lists: [],
  active: undefined,
  activeAgent: undefined,
  category: undefined,
};

function Bots(state: BotsProps = initialState, action: BotsAction) {
  switch (action.type) {
    /**
     * 전체 봇 리스트 업데이트
     */
    case UPDATE_ALL_BOTS:
      return {
        ...state,
        lists: action.payload.map((item: BotProps) => {
          return {
            _id: item._id,
            name: item.name,
            use: item.use,
            provider: item.provider,
          };
        }),
      };

    /**
     * 액티브 봇 업데이트
     */
    case UPDATE_ACTIVE_BOT:
      return {
        ...state,
        active: action.payload,
      };

    /**
     * 봇 추가
     */
    case ADD_BOT:
      return {
        ...state,
        lists: state.lists.concat(action.payload),
      };

    /**
     * 전체 카테고리 리스트 업데이트
     */
    case UPDATE_ALL_CATEGORY_LIST:
      return {
        ...state,
        lists: action.payload.map((item: any) => {
          return {
            _id: item._id,
            name: item.name,
          };
        }),
      };
    /**
     * 액티브 봇 AGENT 업데이트
     */
    case UPDATE_ACTIVE_AGENT:
      return {
        ...state,
        activeAgent: action.payload,
      };

    /**
     * 액티브 봇 CustomOption Use 업데이트
     */
    case UPDATE_BOT_CUSTOM_OPTION_USE:
      const { key, value } = action.payload;
      const { active } = state;

      active.customOptions[key] = { ...active.customOptions[key], use: value };

      return { ...state, active };
    default:
      return state;
  }
}

export default Bots;
