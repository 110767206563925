import React, { useState, useEffect } from 'react';
import { Dialog } from 'happytalk-design-guide';

import useMember from 'hooks/useMember';

import useCanvasUI from 'hooks/useCanvasUI';
import { useDispatch } from 'react-redux';
import { setErrorDialog } from 'modules/Reducer/CanvasUI';
import { ERROR_CODE, ERROR_TITLE, ERROR_CONTENT } from 'constants/Error';

import * as S from './styles';

const ErrorDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { errorDialog } = useCanvasUI();
  const [title, setTitle] = useState<string>('');
  const [content, setContent] = useState(<></>);
  const member = useMember();
  /** 멤버 */
  const modealClose = () => {
    dispatch(
      setErrorDialog({
        open: false,
        type: '',
      }),
    );
  };

  useEffect(() => {
    if (!member || !errorDialog.type) return;

    const { type } = errorDialog;
    const { scenarioLimitCount, blockLimitCount } = member.config;

    switch (type) {
      case ERROR_CODE.LIMIT_SCENARIO:
        setTitle(ERROR_TITLE[type]);
        setContent(
          <>
            1개의 봇에 <S.Emphasize>기본 {scenarioLimitCount}개의 시나리오를 등록</S.Emphasize>할 수 있습니다.
            <br />
            추가 등록을 원하실 경우 고객센터로 문의해 주세요.
            <br />
            1666 - 5263 | happytalk@happytalk.io
          </>,
        );
        break;

      case ERROR_CODE.LIMIT_BLOCK:
        setTitle(ERROR_TITLE[type]);
        setContent(
          <>
            시나리오 당 <S.Emphasize>기본 {blockLimitCount}개의 블럭을 등록</S.Emphasize>할 수 있습니다.
            <br />
            추가 등록을 원하실 경우 고객센터로 문의해 주세요.
            <br />
            1666 - 5263 | happytalk@happytalk.io
          </>,
        );
        break;
      default:
        setTitle(ERROR_TITLE[type as keyof typeof ERROR_TITLE]);
        setContent(<>{ERROR_CONTENT[type as keyof typeof ERROR_CONTENT]}</>);
        break;
    }
  }, [member, errorDialog]);

  return (
    <Dialog title={title} isOpen={errorDialog.open} onClose={() => modealClose()}>
      <S.Msg>{content}</S.Msg>
    </Dialog>
  );
};

export default React.memo(ErrorDialog);
