/**
 * 캔버스 UI 스토어 훅
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { useSelector } from 'react-redux';
import { RootState } from '../modules/Reducer';

export default function useCanvasUI() {
  const canvasUI = useSelector((state: RootState) => state.canvasUI);
  return canvasUI;
}
