interface ModalController {
  top: {
    publish: {
      title: string;
      show: boolean;
      preHook: string;
      subType: string;
      callbackFn: Function;
    };
  };
}

const initialState = {
  top: {
    publish: {
      title: '',
      show: false,
      preHook: '',
      subType: '',
      callbackFn: () => {},
    },
  },
};

const SET_TOP_PUBLISH_SHOW = 'stat/SET_TOP_PUBLISH_SHOW' as const;
const SET_TOP_PUBLISH_TITLE = 'stat/SET_TOP_PUBLISH_TITLE' as const;
const SET_TOP_PUBLISH_PRE_HOOK = 'stat/SET_TOP_PUBLISH_PRE_HOOK' as const;
const SET_TOP_PUBLISH_CALLBACK_FN = 'stat/SET_TOP_PUBLISH_CALLBACK_FN' as const;
const SET_TOP_PUBLISH_SUBTYPE = 'stat/SET_TOP_PUBLISH_SUBTYPE' as const;

export const SetTopPublishShow = (value: boolean) => ({
  type: SET_TOP_PUBLISH_SHOW,
  payload: value,
});

export const SetTopPublishTitle = (value: string) => ({
  type: SET_TOP_PUBLISH_TITLE,
  payload: value,
});

export const SetTopPublishPreHook = (value: string) => ({
  type: SET_TOP_PUBLISH_PRE_HOOK,
  payload: value,
});

export const SetTopPublishCallbackFn = (fn: Function) => ({
  type: SET_TOP_PUBLISH_CALLBACK_FN,
  payload: fn,
});

export const SetTopPublishSubType = (value: string) => ({
  type: SET_TOP_PUBLISH_SUBTYPE,
  payload: value,
});

type ModalTypeAction =
  | ReturnType<typeof SetTopPublishShow>
  | ReturnType<typeof SetTopPublishTitle>
  | ReturnType<typeof SetTopPublishPreHook>
  | ReturnType<typeof SetTopPublishSubType>
  | ReturnType<typeof SetTopPublishCallbackFn>;

function modalController(state: ModalController = initialState, action: ModalTypeAction) {
  switch (action.type) {
    case SET_TOP_PUBLISH_SHOW: {
      return {
        ...state,
        top: {
          ...state.top,
          publish: {
            ...state.top.publish,
            show: action.payload,
          },
        },
      };
    }

    case SET_TOP_PUBLISH_TITLE: {
      return {
        ...state,
        top: {
          ...state.top,
          publish: {
            ...state.top.publish,
            title: action.payload,
          },
        },
      };
    }

    case SET_TOP_PUBLISH_PRE_HOOK: {
      return {
        ...state,
        top: {
          ...state.top,
          publish: {
            ...state.top.publish,
            preHook: action.payload,
          },
        },
      };
    }

    case SET_TOP_PUBLISH_CALLBACK_FN: {
      return {
        ...state,
        top: {
          ...state.top,
          publish: {
            ...state.top.publish,
            callbackFn: action.payload,
          },
        },
      };
    }

    case SET_TOP_PUBLISH_SUBTYPE: {
      return {
        ...state,
        top: {
          ...state.top,
          publish: {
            ...state.top.publish,
            subType: action.payload,
          },
        },
      };
    }

    default: {
      return state;
    }
  }
}

export default modalController;
