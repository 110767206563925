import React, { CSSProperties, useCallback, useEffect, useRef, useState } from 'react';
import {
  ColumnDef,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
  Row,
  CellContext,
  HeaderContext,
  ColumnMeta,
  ColumnPinningState,
  Column,
} from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDown, faArrowUp, faHyphen } from '@fortawesome/pro-solid-svg-icons';

import { Columns, Props, TableData } from '../../Interfaces/Table';

import { BaseTable } from '../../Styles/Table';
import Flex from '../Flex';
import { CheckBox } from 'happytalk-design-guide';

const Table = ({ data, columns, onSelected, onRowClick, sorting: initSorting, initialSortColumnId }: Props) => {
  const columnHelper = createColumnHelper<TableData>();
  const tableContainerRef = useRef<HTMLDivElement>(null);

  const [tableColumns, setTableColumns] = useState<ColumnDef<TableData, any>[]>([]);
  const [sorting, setSorting] = useState<SortingState>([]);
  const [rowSelection, setRowSelection] = useState({});
  const [columnPinning, setColumnPinning] = useState<ColumnPinningState>({});

  const fixRowSorting = useCallback(
    (rowA: Row<TableData>, rowB: Row<TableData>, columnId: string): number => {
      if (!!rowB.original.fix) return 0;
      if (rowA.getValue<any>(columnId) === rowB.getValue<any>(columnId) && !!initialSortColumnId) {
        return initialColumnSorting(rowA, rowB);
      }
      return rowA.getValue<any>(columnId) < rowB.getValue<any>(columnId) ? 1 : -1;
    },
    [sorting],
  );

  const initialColumnSorting = useCallback(
    (rowA: Row<TableData>, rowB: Row<TableData>) => {
      if (!initialSortColumnId) return 0;
      const desc = sorting[0]?.desc;
      if (desc) return rowA.getValue<any>(initialSortColumnId) < rowB.getValue<any>(initialSortColumnId) ? 1 : -1;
      return rowA.getValue<any>(initialSortColumnId) > rowB.getValue<any>(initialSortColumnId) ? 1 : -1;
    },
    [initialSortColumnId, sorting],
  );

  const table = useReactTable({
    data,
    columns: tableColumns,
    state: { sorting, rowSelection, columnPinning },
    enableColumnPinning: true,
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    onColumnPinningChange: setColumnPinning,
    sortingFns: {
      fixRowSorting,
    },
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });
  const { rows } = table.getRowModel();
  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 33, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== 'undefined' && navigator.userAgent.indexOf('Firefox') === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 20,
  });
  const settingColumns = () => {
    const columnSetting = columns.map(
      ({
        header,
        accessor,
        sort = false,
        size,
        cell = (props) => String(props.getValue()),
        selectable = false,
        pin = false,
        meta = {},
      }: Columns<TableData>) => {
        if (selectable) {
          header = TableCheckBoxHeader(header as string);
          cell = TableCheckBoxCell;
        }
        if (pin) {
          setColumnPinning((prev) => ({
            ...prev,
            left: [...(prev.left || []), accessor],
          }));
        }

        return columnHelper.accessor(accessor, {
          header,
          enableSorting: sort,
          enablePinning: pin,
          size,
          cell,
          meta,
          sortingFn: 'fixRowSorting' as any,
        });
      },
    );
    setSorting(initSorting ?? []);
    setTableColumns(columnSetting);
  };

  const TableCheckBoxCell = ({ row }: CellContext<TableData, any>) => (
    <CheckBox
      value={''}
      onCheck={row.getToggleSelectedHandler()}
      name={''}
      children={''}
      checked={row.getIsSelected()}
    />
  );
  const TableCheckBoxHeader =
    (header: string) =>
    ({ table }: HeaderContext<TableData, any>) =>
      (
        <CheckBox
          value={''}
          onCheck={table.getToggleAllRowsSelectedHandler()}
          name={''}
          children={header}
          checked={table.getIsAllRowsSelected()}
        />
      );

  const HeaderSortIcon = ({ column }: { column: Column<TableData> }) => {
    if (!column.getCanSort()) return null;

    const sortType = column.getIsSorted();

    switch (sortType) {
      case 'asc':
        return <FontAwesomeIcon icon={faArrowDown} width={18} height={18} />;
      case 'desc':
        return <FontAwesomeIcon icon={faArrowUp} width={18} height={18} />;
      default:
        return <FontAwesomeIcon icon={faHyphen} width={18} height={18} />;
    }
  };

  const getCommonPinningStyles = useCallback((column: Column<TableData>): CSSProperties => {
    const isPinned = column.getIsPinned();

    return {
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
    };
  }, []);

  useEffect(() => {
    if (!columns) return;
    settingColumns();
  }, [columns]);

  useEffect(() => {
    onSelected && onSelected(table.getSelectedRowModel().flatRows.map(({ original }) => original));
  }, [rowSelection]);

  return (
    <div
      ref={tableContainerRef}
      style={{
        overflow: 'auto', //our scrollable table container
        position: 'relative', //needed for sticky header
      }}
    >
      <BaseTable>
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={(header.column.columnDef.meta as ColumnMeta<TableData, any>)?.headerClassName}
                  style={{
                    width: header.getSize(),
                    ...getCommonPinningStyles(header.column),
                  }}
                  colSpan={header.colSpan}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  <Flex size={'xsmall'} align={'center'}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    <HeaderSortIcon column={header.column} />
                  </Flex>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody
          style={{
            height: `${rowVirtualizer.getTotalSize()}px`, //tells scrollbar how big the table is
          }}
        >
          {rowVirtualizer.getVirtualItems().map((virtualRow) => {
            const row = rows[virtualRow.index] as Row<TableData>;
            return (
              <tr
                data-index={virtualRow.index} //needed for dynamic row height measurement
                ref={(node) => {
                  if (!node) return;
                  window.requestAnimationFrame(() => rowVirtualizer.measureElement(node));
                }} //measure dynamic row height
                key={row.id}
                style={{
                  transform: `translateY(${virtualRow.start}px)`, //this should always be a `style` as it changes on scroll
                  cursor: onRowClick ? 'pointer' : 'default',
                }}
                className={row.original?.className || ''}
                onClick={() => onRowClick && onRowClick(row.original)}
              >
                {row.getVisibleCells().map((cell) => {
                  return (
                    <td
                      key={cell.id}
                      style={{
                        width: cell.column.getSize(),
                        ...getCommonPinningStyles(cell.column),
                      }}
                      className={`${(cell.column.columnDef.meta as ColumnMeta<TableData, any>)?.cellClassName || ''} ${
                        cell.getValue()?.className || ''
                      } ${cell.column.getIsPinned() ? 'isPinColumn' : ''}`}
                    >
                      {(cell.column.columnDef.meta as ColumnMeta<TableData, any>)?.prefix}
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      {(cell.column.columnDef.meta as ColumnMeta<TableData, any>)?.suffix}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BaseTable>
    </div>
  );
};

export default Table;
