import styled from 'styled-components';
import Modal, { BaseModalBackground } from 'styled-react-modal';

/** 모달 컨텐츠 */
const DefaultModal = Modal.styled`
    width: 400px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 10px 10px rgba(0,0,0,0.1);
`;

/** 모달 백그라운드 */
export const ModalBackground = styled(BaseModalBackground)`
  z-index: 110000;
`;

/** 모달 해더 */
export const Header = styled.div`
  position: relative;
  border-bottom: 1px solid #e5e6eb;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: -0.5px;
  text-align: center;
  color: #1a1a1a;
`;

/** 컨텐츠 컨테이너 */
export const ContentsContainer = styled.div`
  padding: 15px 0;

  img {
    width: 100%;
    max-width: 340px;
  }

  .required {
    color: #ff0000;
  }
`;

/** 컨텐츠 타이틀 라벨 */
export const TitleLabel = styled.div`
  padding-bottom: 10px;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: -0.5px;
  color: #1a1a1a;

  .required {
    color: #ff0000;
  }
`;

/** 모달 푸터 */
export const Footer = styled.div`
  position: relative;
  text-align: center;
  padding: 0 24px;
  height: 60px;
  line-height: 60px;
  border-top: 1px solid #e5e6eb;

  button {
    margin: 0 6px;
  }
`;

export default DefaultModal;
