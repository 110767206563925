import { BaseChipItem, BaseChipList } from '../../Styles/Chip';
import React, { useEffect } from 'react';
import { ChipProps } from '../../Interfaces/Chip';
import classNames from 'classnames';
import { Close } from '../../../../constants/Icons';

const ChipList = ({ list, onClick, value }: ChipProps) => {
  const itemActive = (key: string) => {
    if (Array.isArray(value)) return value.includes(key);
    return value === key;
  };

  return (
    <BaseChipList>
      {list?.map((item) => {
        const { label = '', key = '', hideClose = false } = item || {};
        const active = itemActive(key);
        return (
          <BaseChipItem
            className={classNames({ active })}
            onClick={() => {
              onClick(key);
            }}
            key={key}
          >
            {label}
            {active && !hideClose && <Close fill={'var(--primary-color-bot)'} />}
          </BaseChipItem>
        );
      })}
    </BaseChipList>
  );
};

export default ChipList;
