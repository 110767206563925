import React from 'react';
import { MdClear } from 'react-icons/md';
import styled from 'styled-components';
import { Dialog } from 'happytalk-design-guide';

interface ExtendDialogProps {
  children: React.ReactNode;
  /** className */
  className?: string;
  /** 다이얼로그 팝업 타이틀 */
  title?: string;
  /** 다이얼로그 width. 100px 단위로 입력 */
  width?: string;
  /** 다이얼로그 출력 여부 */
  isOpen: boolean;
  /** 다이얼로그 닫는 함수 */
  onClose: () => void;
  /** 사용자 정의 콜백 함수 ex) submit 등.. */
  onConfirm?: () => void;
  /** 모달이 위치하고자 하는 선택자 기본은 #root. ex) body, #root */
  selector?: string;

  closeButton?: boolean;
}

/** 창 닫기 버튼 */
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  .close-icon {
    font-size: 40px;
    color: var(--brown-grey);
  }
  .close-icon:hover {
    cursor: pointer;
  }
`;

const ExtendDialog: React.FC<ExtendDialogProps> = ({ ...props }) => {
  return (
    <Dialog
      title={props.title}
      width={props.width}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      className={props.className}
    >
      {props.children}
      {props.closeButton && (
        <CloseButton>
          <span className="close-icon" onClick={() => props.onClose()}>
            <MdClear />
          </span>
        </CloseButton>
      )}
    </Dialog>
  );
};

export default React.memo(ExtendDialog);
