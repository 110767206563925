/* eslint-disable @typescript-eslint/no-unused-vars */
import React from 'react';
import styled from 'styled-components';
import 'react-base-table/styles.css';

const Container = styled.div<{ top?: string; left?: string }>`
  top: ${(props) => props.top || '0px'};
  left: ${(props) => props.left || '0px'};
  height: 0px;
  text-align: center;
  position: relative;

  .loader {
    border-radius: 50%;
    position: relative;
    // margin: 50px;
    display: inline-block;
    height: 0px;
    width: 0px;
    top: -8px;
  }

  .loader span {
    position: absolute;
    display: block;
    background: #ddd;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    top: -20px;
    perspective: 100000px;
  }
  .loader span:nth-child(1) {
    left: 15px;
    // animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation: bounce2 1s cubic-bezier(0.15, 0.51, 0.49, 0.88) infinite;
    animation-delay: 0s;
    background: #6f75ff;
  }
  .loader span:nth-child(2) {
    left: 0px;
    // animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation: bounce2 1s cubic-bezier(0.15, 0.51, 0.49, 0.88) infinite;
    animation-delay: 0.2s;
    background: #ffde6f;
  }
  .loader span:nth-child(3) {
    left: -15px;
    // animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation: bounce2 1s cubic-bezier(0.15, 0.51, 0.49, 0.88) infinite;
    animation-delay: 0.4s;
    background: #01de6f;
  }
  .loader span:nth-child(4) {
    left: -30px;
    // animation: bounce2 1s cubic-bezier(0.04, 0.35, 0, 1) infinite;
    animation: bounce2 1s cubic-bezier(0.15, 0.51, 0.49, 0.88) infinite;
    animation-delay: 0.6s;
    background: #ff756f;
  }

  @keyframes bounce2 {
    0%,
    75%,
    100% {
      transform: translateY(0px);
    }
    25% {
      transform: translateY(-15px);
    }
  }
`;

interface BounceSpinnerProps {
  top?: string;
  left?: string;
}

const BounceSpinner: React.FC<BounceSpinnerProps> = ({ top = '0px', left = '0px' }) => {
  return (
    <Container top={top} left={left}>
      <div className="loader">
        <span />
        <span />
        <span />
        <span />
      </div>
    </Container>
  );
};
export default React.memo(BounceSpinner);
