import React, { useCallback } from 'react';
import { AlertIcon, External, ExclamationMark } from 'constants/Icons';
import classNames from 'classnames';
import { Props, StyleProps } from '../../Interfaces/Tip';
import { BaseTip, ImageContents, LinkButtonContents, TipContents } from '../../Styles/Tip';
import { css } from '@emotion/react';

const Tip = ({
  iconType = 'info',
  useIcon = true,
  iconComponent,
  state = 'info',
  leftLine = true,
  children,
  frame = 'square',
  linkButton,
  image,
}: Props) => {
  const getColor = useCallback(() => {
    switch (iconType) {
      case 'advice':
        return 'var(--blue)';
      case 'denial':
      case 'error':
      case 'warning':
        return `var(--red)`;
      case 'info':
      default:
        return `var(--primary-black)`;
    }
  }, [iconType]);

  const iconStyle = { width: '16px', height: '16px' };
  const GetIcon = useCallback(() => {
    switch (iconType) {
      case 'info':
      case 'advice':
        return <AlertIcon style={iconStyle} fill={getColor()} />;
      case 'error':
      case 'warning':
      case 'denial':
        return <ExclamationMark style={iconStyle} fill={getColor()} />;
      case 'custom':
        return iconComponent || <></>;
      case 'none':
      default:
        return <></>;
    }
  }, [iconType, iconComponent]);

  return (
    <BaseTip
      frame={frame}
      className={classNames({
        leftLine: !leftLine,
      })}
      state={state}
    >
      <TipContents>
        {useIcon && <GetIcon />}

        {children}
      </TipContents>
      <ImageContents />
      {linkButton?.use && (
        <LinkButtonContents onClick={linkButton.onClick}>
          <External style={iconStyle} />
          {linkButton?.label}
        </LinkButtonContents>
      )}
    </BaseTip>
  );
};

export default Tip;
