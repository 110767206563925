import styled from '@emotion/styled';
import { StyleProps } from '../../Interfaces/Badge';
import { css } from '@emotion/react';

const baseStatusStyle = ({ status }: StyleProps) => {
  switch (status) {
    case 'warning':
      return css`
        background-color: var(--red-fill);
        color: var(--red);
      `;
    case 'gray':
      return css`
        background-color: var(--tertiary-fill);
        color: var(--secondary-black);
      `;
    case 'none':
      return css`
        background-color: transparent;
        color: var(--secondary-black);
      `;
    case 'primary':
      return css`
        background-color: var(--blue-fill);
        color: var(--blue);
      `;
    case 'success':
      return css`
        background-color: var(--green-fill);
        color: var(--green);
      `;
  }
};

const balloonStatusStyle = ({ status }: StyleProps) => {
  switch (status) {
    case 'warning':
      return css`
        background-color: var(--red);
        color: var(--white);
      `;
    case 'gray':
      return css`
        background-color: var(--secondary-black);
        color: var(--white);
      `;
    case 'none':
      return css`
        background-color: transparent;
        color: var(--secondary-black);
      `;
    case 'primary':
      return css`
        background-color: var(--blue);
        color: var(--white);
      `;
    case 'success':
      return css`
        background-color: var(--green);
        color: var(--white);
      `;
  }
};

export const BaseBadge = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  gap: 8px;

  padding: 2px 6px;

  border-radius: 4px;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.28px;

  width: max-content;

  ${baseStatusStyle}
`;

export const BalloonBadge = styled.div<StyleProps>`
  display: flex;
  align-items: center;
  gap: 2px;

  padding: 4px 6px;

  border-radius: 4px;

  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.28px;

  ${balloonStatusStyle}
`;
