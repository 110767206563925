import { regExpType } from 'constants/Types';

const GET_REGEXP = 'regexp/GET_REGEXP' as const;

/**
 * 액션
 * @param botId
 * */

export const getRegExpItems = (regExpItems: regExpType[]) => ({
  type: GET_REGEXP,
  payload: regExpItems,
});

type RegExpAction = ReturnType<typeof getRegExpItems>;
function RegExpItems(state: string[] = [], action: RegExpAction) {
  switch (action.type) {
    case GET_REGEXP:
      return action.payload;
    default:
      return state;
  }
}

export default RegExpItems;
