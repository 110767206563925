/**
 * 에러코드네이밍룰
 * 1xx member
 * 2xx bot
 * 3xx sce
 * 4xx block
 * 5xx endUser
 * 6xx botSession
 * 7xx api business error
 * 8xx endUser business error
 * 9xx error
 *
 * ---
 * xx1 already
 * xx2 wrong Id
 * xx3 not found
 * xx4 size of limit
 * x5x token
 */
export enum ERROR_CODE {
  ALREADY_MEMBER = 'CBE1001',
  WRONG_MEMBER_ID = 'CBE1002',
  NOT_FOUND_MEMBER = 'CBE1003',
  ALREADY_BOT = 'CBE2001',
  WRONG_BOT_ID = 'CBE2002',
  NOT_FOUND_BOT = 'CBE2003',
  LIMIT_SCENARIO = 'CBE3004',
  LIMIT_BLOCK = 'CBE4004',
  NOT_FOUND_UUID = 'CBE5003',
  NOT_FOUND_BOT_SESSION = 'CBE6003',
  NOT_PROVIDE_SOLUTION = 'CBE8003',
  MIDDLEWARE_ERROR = 'CBE9001',
  IDLE_TIMEOUT = 'CBE9051',
  TOKEN_EXPIRED = 'CBE9052',
}

export enum ERROR_TITLE {
  'CBE1001' = '',
  'CBE1002' = '',
  'CBE1003' = '',
  'CBE2001' = '',
  'CBE2002' = '',
  'CBE2003' = '',
  'CBE3004' = '기본 제공 수량 이용 완료',
  'CBE4004' = '기본 제공 수량 이용 완료',
  'CBE5003' = '',
  'CBE6003' = '',
  'CBE8003' = '',
  'CBE9001' = '',
  'CBE9051' = '',
  'CBE9052' = '',
}

export enum ERROR_CONTENT {
  'CBE1001' = '',
  'CBE1002' = '',
  'CBE1003' = '',
  'CBE2001' = '',
  'CBE2002' = '',
  'CBE2003' = '',
  'CBE3004' = '',
  'CBE4004' = '',
  'CBE5003' = '',
  'CBE6003' = '',
  'CBE8003' = '',
  'CBE9001' = '',
  'CBE9051' = '장시간 움직이지 않아 창이 닫힙니다.',
  'CBE9052' = '토큰이 만료되었습니다.',
}
