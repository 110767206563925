import styled from '@emotion/styled';
import { StyleProps } from '../../Interfaces/Col';
import { css } from '@emotion/react';

const sizeStyle = ({ size = 'large' }: Partial<StyleProps>) => {
  switch (size) {
    case 'none':
      return css`
        padding: 0;
      `;
    case 'xsmall':
      return css`
        padding: var(--s-1);
      `;
    case 'small':
      return css`
        padding: var(--s-2);
      `;
    case 'medium':
      return css`
        padding: var(--s-3);
      `;
    case 'large':
      return css`
        padding: var(--s-4);
      `;
    case 'xlarge':
      return css`
        padding: var(--s-5);
      `;
  }
};

export const BaseCol = styled.div<Partial<StyleProps>>`
  flex-grow: ${({ span }) => span || 1};
  flex-basis: 0;
  flex-shrink: 1;
  background-color: ${({ transparent }) => (transparent ? 'transparent' : '#fff')};
  border-radius: var(--r-12);
  ${sizeStyle}
`;
