import styled from 'styled-components';

const BotPageContainer = styled.div`
  position: fixed;
  top: 55px;
  left: 55px;
  height: calc(100% - 55px);
  width: calc(100% - 55px);
  overflow-y: auto;
`;

export default BotPageContainer;
