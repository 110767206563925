import styled from 'styled-components';

const BlankContainer = styled.div`
  display: flex;
  &.topbar {
    margin-top: 54px;
    position: absolute;
    width: 100%;
    min-height: calc(100% - 54px);
  }
`;

export default BlankContainer;
