import styled from 'styled-components';

export const Msg = styled.div`
  width: 100%;
  text-align: center;
  color: #1a1a1a;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;

  span {
    color: #2654cb;
    font-size: 14px;
  }
`;

export const Emphasize = styled.span`
  color: #214a79;
`;
