const GET_STAT_CONFIG = 'statistics/GET_STAT_CONFIG' as const;

/**
 * 액션
 * @param stat
 * */

export const getStatConfig = (stat: StatConfigProps, target: string) => ({
  type: GET_STAT_CONFIG,
  target: target,
  payload: stat,
});

const initialState = {
  startDate: undefined,
  endDate: undefined,
  botId: undefined,
};

type StatConfigAction = ReturnType<typeof getStatConfig>;

interface StatConfigProps {
  startDate: Date | undefined;
  endDate: Date | undefined;
  botId: string | undefined;
  target?: string | undefined;
}

function StatConfig(state: StatConfigProps = initialState, action: StatConfigAction) {
  switch (action.type) {
    case GET_STAT_CONFIG: {
      return {
        ...action.payload,
        target: action.target,
      };
    }
    default: {
      return state;
    }
  }
}

export default StatConfig;
