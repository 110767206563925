import { Item, Solution } from '../../constants/Types';

const SET_BLOCK_LIBRARY = 'library/block' as const;
const SET_SCENARIO_LIBRARY = 'library/scenario' as const;
const SET_BLOCK_LIBRARY_DISPLAY = 'library/block/display' as const;

/**
 * 액션
 * @param library
 * @param module
 * */

export const setBlockLibrary = (value: any) => ({
  type: SET_BLOCK_LIBRARY,
  payload: value,
});

export const setBlockLibraryForDisplay = (value: any) => ({
  type: SET_BLOCK_LIBRARY_DISPLAY,
  payload: value,
});

export const setScenarioLibrary = (value: any) => ({
  type: SET_SCENARIO_LIBRARY,
  payload: value,
});

type LibraryProviderGroupType = 'GEN' | Solution.CHATBOT | Solution.ARS;
export type LibraryCategoryType = 'default' | 'carousel' | 'button';
interface ScenarioLibrary {
  active: boolean;
  edit: string;
  group: string;
  label: string;
  templateScenarioId: string;
  type: string;
  value: string;
}

export interface LibraryProps {
  LibraryIconPath: string;
  LibraryItem: {
    block?: Record<LibraryProviderGroupType, Record<LibraryCategoryType, Item>>;
    scenario?: ScenarioLibrary[];
    displayBlock?: Record<LibraryCategoryType, Item>;
  };
}

const initialState = {
  LibraryIconPath: '',
  LibraryItem: {},
};

type LibraryAction = ReturnType<typeof setBlockLibrary> | ReturnType<typeof setScenarioLibrary> | ReturnType<typeof setBlockLibraryForDisplay>;

function Library(state: LibraryProps = initialState, action: LibraryAction) {
  switch (action.type) {
    case SET_BLOCK_LIBRARY: {
      return {
        ...state,
        LibraryItem: {
          ...state.LibraryItem,
          block: action.payload,
        },
      };
    }
    case SET_SCENARIO_LIBRARY: {
      return {
        ...state,
        LibraryItem: {
          ...state.LibraryItem,
          scenario: action.payload,
        },
      };
    }
    case SET_BLOCK_LIBRARY_DISPLAY: {
      return {
        ...state,
        LibraryItem: {
          ...state.LibraryItem,
          displayBlock: action.payload,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default Library;
