import * as React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ko from 'date-fns/locale/ko';
import classNames from 'classnames';
import * as S from './styles';
import { Calendar } from 'constants/Icons';

interface DatePickerProps {
  /** 필수값 */
  selected: Date | null;
  onChange: (e: any) => void;

  /** optional */
  minTime?: Date;
  maxTime?: Date;
  dateFormat?: string;
  customInput?: any;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  startDate?: Date;
  endDate?: Date;
  selectsRange?: boolean;
  shouldCloseOnSelect?: boolean;
  onCalendarOpen?: () => void;
  onCalendarClose?: () => void;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  monthsShown?: number;
  showPreviousMonths?: boolean;
  selectsDisabledDaysInRange?: boolean;
  open?: boolean;
  onInputClick?: () => void;
  onClickOutside?: () => void;
  showMonthYearPicker?: boolean;
  showTimeSelect?: boolean;
  timeFormat?: string;
  timeIntervals?: number;
  timeCaption?: string;
  showTimeSelectOnly?: boolean;
  showWeekNumbers?: boolean;
  placeholderText?: string;
  autoOpen?: boolean;
  portalId?: string;
  isOneDir?: boolean;
  width?: number;
  height?: number;
  includeTimes?: any;
  isDisabledNone?: boolean;
}

const DatePickerWrapper: React.FC<DatePickerProps> = ({ ...props }: DatePickerProps) => {
  const {
    showTimeSelect,
    customInput,
    selected,
    onChange,
    disabled,
    minDate,
    maxDate,
    startDate,
    endDate,
    selectsRange,
    shouldCloseOnSelect,
    onCalendarOpen,
    onCalendarClose,
    selectsStart,
    selectsEnd,
    monthsShown,
    showPreviousMonths,
    selectsDisabledDaysInRange,
    open,
    onInputClick,
    onClickOutside,
    showMonthYearPicker,
    timeFormat,
    timeIntervals,
    timeCaption,
    dateFormat,
    showTimeSelectOnly,
    placeholderText,
    minTime,
    maxTime,
    autoOpen,
    portalId,
    isOneDir,
    width,
    height,
    includeTimes,
    isDisabledNone = false,
  } = props;

  registerLocale('ko', ko);
  const DefaultInput = React.forwardRef(({ onClick, value, placeholder }: any, ref: React.Ref<HTMLButtonElement>) => {
    const Custom = customInput;
    return (
      <S.DateButton
        className={classNames({
          isDisabled: disabled ? true : false,
        })}
        ref={ref}
        height={height}
        isOneDir={isOneDir}
        onClick={onClick}
      >
        {customInput ? (
          <>
            <span className={'icon'} onMouseUp={autoOpen && onClick}>
              <Calendar style={{ width: '16px', height: '16px' }} />
            </span>
            <Custom {...props} />
          </>
        ) : (
          <>
            <span className={'icon'} onMouseUp={autoOpen && onClick}>
              <Calendar style={{ width: '16px', height: '16px' }} />
            </span>
            <span
              className={classNames({
                value,
                placeholder: !value,
              })}
            >
              {value ? value : placeholder}
            </span>
          </>
        )}
      </S.DateButton>
    );
  });

  const datePickerProps: DatePickerProps = {
    showTimeSelect,
    dateFormat: dateFormat || 'yyyy-MM-dd',
    selected: selected,
    onChange: onChange,
    customInput: <DefaultInput />,
    disabled: typeof disabled === 'undefined' ? false : disabled,
    selectsRange: typeof selectsRange === 'undefined' ? false : selectsRange,
    shouldCloseOnSelect: typeof shouldCloseOnSelect === 'undefined' ? true : shouldCloseOnSelect,
    selectsStart: typeof selectsStart === 'undefined' ? false : selectsStart,
    selectsEnd: typeof selectsEnd === 'undefined' ? false : selectsEnd,
    onCalendarOpen: onCalendarOpen,
    onCalendarClose: onCalendarClose,
    onInputClick: onInputClick,
    onClickOutside: onClickOutside,
    monthsShown: monthsShown,
    showPreviousMonths: typeof showPreviousMonths === 'undefined' ? false : showPreviousMonths,
    selectsDisabledDaysInRange: typeof selectsDisabledDaysInRange === 'undefined' ? true : selectsDisabledDaysInRange,
    showMonthYearPicker: typeof showMonthYearPicker === 'undefined' ? false : showMonthYearPicker,
    timeFormat,
    timeIntervals,
    timeCaption,
    showTimeSelectOnly,
    placeholderText,
    minTime,
    maxTime,
    includeTimes,
  };
  if (minDate) datePickerProps.minDate = minDate;
  if (maxDate) datePickerProps.maxDate = maxDate;
  if (startDate) datePickerProps.startDate = startDate;
  if (endDate) datePickerProps.endDate = endDate;
  if (typeof open !== 'undefined') datePickerProps.open = open;
  if (portalId) datePickerProps.portalId = portalId;

  return (
    <S.Container
      className={classNames({ isDisabledNone: isDisabledNone })}
      width={width}
      onWheel={(e) => {
        e.stopPropagation();
      }}
    >
      <DatePicker
        locale={'ko'}
        {...datePickerProps}
        calendarClassName={`customCal ${dateFormat === 'yyyy-MM-dd HH:mm' ? 'withTime' : ''}`}
      />
    </S.Container>
  );
};

export default React.memo(DatePickerWrapper);
