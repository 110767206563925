import styled from 'styled-components';

const BotPageContainer = styled.div`
  position: fixed;
  overflow-y: auto;
  height: 100%;
  width: 100%;
  transition: left 0.3s ease-in-out;

  &.activeAgent {
    top: 54px;
    left: 305px;
    height: calc(100% - 54px);
    width: 100%;
  }
`;

export default BotPageContainer;
