/**
 * 캔버스 UI 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { toString, toNumber } from 'lodash';

/** 새로고침 모달 */
const EDITOR_REFRESH_MODAL = 'canvasUI/EDITOR_REFRESH_MODAL' as const;
/** 시나리오 리스트 토글 */
const TOGGLE_SCENARIO_LIST = 'canvasUI/TOGGLE_SCENARIO_LIST' as const;
/** 시나리오 리스트 열기 */
const OPEN_SCENARIO_LIST = 'canvasUI/OPEN_SCENARIO_LIST' as const;
/** 라이브러리 패널 토글 */
const TOGGLE_LIBRARY_PANEL = 'canvasUI/TOGGLE_LIBRARY_PANEL' as const;
/** 라이브러리 패널 열기 */
const OPEN_LIBRARY_PANEL = 'canvasUI/OPEN_LIBRARY_PANEL' as const;
/** 블럭 에디터 패널 토글 */
const TOGGLE_BLOCKEDITOR_PANEL = 'canvasUI/TOGGLE_BLOCKEDITOR_PANEL' as const;
/** 블럭 에디터 패널 토글 */
const OPEN_BLOCKEDITOR_PANEL = 'canvasUI/OPEN_BLOCKEDITOR_PANEL' as const;
/** 블럭 에디터 패널 닫기 */
const CLOSE_BLOCKEDITOR_PANEL = 'canvasUI/CLOSE_BLOCKEDITOR_PANEL' as const;
/** 블럭 미리보기 패널 토글 */
const TOGGLE_BLOCKPREVIEW_PANEL = 'canvasUI/TOGGLE_BLOCKPREVIEW_PANEL' as const;
/** 블럭 미리보기 패널 열기 */
const OPEN_BLOCKPREVIEW_PANEL = 'canvasUI/OPEN_BLOCKPREVIEW_PANEL' as const;
/** 줌 셋팅 */
const SET_ZOOM_LEVEL = 'canvasUI/SET_ZOOM_LEVEL' as const;
/** 줌 인 */
const SET_ZOOM_IN = 'canvasUI/SET_ZOOM_IN' as const;
/** 줌 아웃 */
const SET_ZOOM_OUT = 'canvasUI/SET_ZOOM_OUT' as const;
/** 딤드 */
const SET_DIMMED = 'canvasUI/SET_DIMMED' as const;
/** 투명 딤드 */
const SET_LOCK_DIMMED = 'canvasUI/SET_LOCK_DIMMED' as const;
/** 사용자 튜툐리얼 완료 여부 */
// const SET_TUTORIAL_DONE = 'canvasUI/SET_TUTORIAL_DONE' as const;

/** 봇 세팅 화면 열기 */
const GET_SETTING_BOT = 'GET_SETTING_BOT' as const;
/** 봇 인증 화면 열기 */
const GET_AUTH_BOT = 'GET_AUTH_BOT' as const;
/** 봇 배포내역 화면 열기 */
const GET_DEPLOY_BOT = 'GET_DEPLOY_BOT' as const;
/** 봇 상담내역 화면 열기 */
const GET_CONSULT_BOT = 'GET_CONSULT_BOT' as const;
/** 봇 통계 화면 열기 */
const GET_STATISTICS_BOT = 'GET_STATISTICS_BOT' as const;
/** 봇 통계 화면 열기 */
const GET_NLP_BOT = 'GET_NLP_BOT' as const;

/** 변수 설정 토글 */
const TOGGLE_VARIABLE_LIST = 'canvasUI/TOGGLE_VARIABLE_LIST' as const;
/** 변수 설정 리스트 열기 */
const OPEN_VARIABLE_LIST = 'canvasUI/OPEN_VARIABLE_LIST' as const;

/** 설문지 폼 패널 넓이 */
const GET_SURVEYFORM_WIDTH = 'canvasUI/GET_SURVEYFORM_WIDTH' as const;
const SET_SURVEYFORM_WIDTH = 'canvasUI/SET_SURVEYFORM_WIDTH' as const;

/** 마켓을 통해 접속 */
const SET_FROM_MARKET = 'canvasUI/SET_FROM_MARKET' as const;

const SET_ERROR_DIALOG = 'canvasUI/SET_ERROR_DIALOG' as const;

/**
 * 액션
 */
export const editorRefreshModal = () => ({ type: EDITOR_REFRESH_MODAL });
export const toggleScenarioList = () => ({ type: TOGGLE_SCENARIO_LIST });
export const openScenarioList = () => ({ type: OPEN_SCENARIO_LIST });
export const toggleVariableList = () => ({ type: TOGGLE_VARIABLE_LIST });
export const openVariableList = () => ({ type: OPEN_VARIABLE_LIST });
export const toggleLibraryPanel = () => ({ type: TOGGLE_LIBRARY_PANEL });
export const openLibraryPanel = () => ({ type: OPEN_LIBRARY_PANEL });
export const toggleBlockEditorPanel = () => ({ type: TOGGLE_BLOCKEDITOR_PANEL });
export const openBlockEditorPanel = () => ({ type: OPEN_BLOCKEDITOR_PANEL });
export const toggleBlockPreviewPanel = () => ({ type: TOGGLE_BLOCKPREVIEW_PANEL });
export const openBlockPreviewPanel = () => ({ type: OPEN_BLOCKPREVIEW_PANEL });
export const closeBlockPreviewPanel = () => ({ type: CLOSE_BLOCKEDITOR_PANEL });
export const setCanvasZoomLevel = (zoomLevel: number) => ({
  type: SET_ZOOM_LEVEL,
  payload: zoomLevel,
});
export const setZoomIn = () => ({ type: SET_ZOOM_IN });
export const setZoomOut = () => ({ type: SET_ZOOM_OUT });
export const setDimmed = (show: boolean) => ({ type: SET_DIMMED, payload: show });
export const setLockDimmed = (show: boolean) => ({ type: SET_LOCK_DIMMED, payload: show });
// export const setTutorialDone = () => ({ type: SET_TUTORIAL_DONE });
export const getSettingBot = () => ({ type: GET_SETTING_BOT });
export const getAuthBot = () => ({ type: GET_AUTH_BOT });
export const getDeployBot = () => ({ type: GET_DEPLOY_BOT });
export const getConsultBot = () => ({ type: GET_CONSULT_BOT });
export const getStatisticsBot = () => ({ type: GET_STATISTICS_BOT });
export const getSurveyFormWidth = () => ({ type: GET_SURVEYFORM_WIDTH });
export const setSurveyFormWidth = (width: number) => ({ type: SET_SURVEYFORM_WIDTH, payload: width });
export const setFromMarket = () => ({ type: SET_FROM_MARKET });
export const getNlpBot = () => ({ type: GET_NLP_BOT });
export const setErrorDialog = (option: { open: boolean; type: string }) => ({
  type: SET_ERROR_DIALOG,
  paylead: option,
});

/**
 * 액션 타입 그룹
 */
type CanvasAction =
  | ReturnType<typeof editorRefreshModal>
  | ReturnType<typeof toggleScenarioList>
  | ReturnType<typeof openScenarioList>
  | ReturnType<typeof toggleVariableList>
  | ReturnType<typeof openVariableList>
  | ReturnType<typeof toggleLibraryPanel>
  | ReturnType<typeof openLibraryPanel>
  | ReturnType<typeof toggleBlockEditorPanel>
  | ReturnType<typeof openBlockEditorPanel>
  | ReturnType<typeof toggleBlockPreviewPanel>
  | ReturnType<typeof openBlockPreviewPanel>
  | ReturnType<typeof closeBlockPreviewPanel>
  | ReturnType<typeof setCanvasZoomLevel>
  | ReturnType<typeof setZoomIn>
  | ReturnType<typeof setZoomOut>
  | ReturnType<typeof setDimmed>
  | ReturnType<typeof setLockDimmed>
  | ReturnType<typeof getSettingBot>
  | ReturnType<typeof getAuthBot>
  | ReturnType<typeof getDeployBot>
  | ReturnType<typeof getConsultBot>
  | ReturnType<typeof getStatisticsBot>
  | ReturnType<typeof getSurveyFormWidth>
  | ReturnType<typeof setSurveyFormWidth>
  | ReturnType<typeof setFromMarket>
  | ReturnType<typeof getNlpBot>
  | ReturnType<typeof setErrorDialog>;
// | ReturnType<typeof setTutorialDone>;
interface CanvasState {
  /** 시나리오 리스트 패널 오픈 */
  openScenarioList: boolean | undefined;
  /** 변수 설정 리스트 패널 오픈 */
  openVariableList: boolean | undefined;
  /** 라이브러리 리스트 패널 오픈 */
  openLibraryPanel: boolean | undefined;
  /** 왼쪽 패널 오픈 */
  openLeftPanel: boolean | undefined;
  /** 블럭 에디터 패널 오픈 */
  openBlockEditorPanel: boolean | undefined;
  /** 미리보기 패널 오픈 */
  openBlockPreviewPanel: boolean | undefined;
  /** 오른쪽 패널 오픈 */
  openRightPanel: boolean | undefined;
  /** 에디터 줌 레벨 */
  zoomLevel: number;
  /** 딤드 */
  isDimmed: boolean | undefined;
  /** 투명 딤드 */
  isLockDimmed: boolean | undefined;
  /** 튜툐리얼 완료 여부 */
  // isTutorialDone: boolean | undefined;
  getSettingBot: boolean | undefined;
  getDeployBot: boolean | undefined;
  getConsultBot: boolean | undefined;
  getStatisticsBot: boolean | undefined;
  surveyFormWidth: number | 700;
  getAuthBot: boolean | undefined;
  isFromMarket: boolean | undefined;
  getNlpSetting: boolean | undefined;
  isEditorRefresh: boolean;
  errorDialog: {
    open: boolean;
    type: string;
  };
}

/**
 * 초기값
 */
const initialState: CanvasState = {
  /** 시나리오 리스트 패널 오픈 */
  openScenarioList: true,
  /** 변수 설정 리스트 팬ㄹ 오픈 */
  openVariableList: false,
  /** 라이브러리 리스트 패널 오픈 */
  openLibraryPanel: false,
  /** 왼쪽 패널 오픈 */
  openLeftPanel: true,
  /** 블럭 에디터 패널 오픈 */
  openBlockEditorPanel: false,
  /** 미리보기 패널 오픈 */
  openBlockPreviewPanel: false,
  /** 오른쪽 패널 오픈 */
  openRightPanel: false,
  /** 에디터 줌 레벨 */
  zoomLevel: localStorage.getItem('zoomLevel') ? toNumber(localStorage.getItem('zoomLevel')) : 100,
  /** 딤드 처리 여부 */
  // isDimmed: localStorage.getItem('isScenarioTutorialDone') ? false : true,
  isDimmed: false,
  /** 투명 딤드 처리 여부 */
  isLockDimmed: false,
  /** 튜툐리얼 완료 여부 */
  // isTutorialDone: localStorage.getItem('isScenarioTutorialDone') ? true : false,
  /** 세팅으로 부터 이동인지 */
  getSettingBot: false,
  getDeployBot: false,
  getConsultBot: false,
  getStatisticsBot: false,
  surveyFormWidth: 700,
  getAuthBot: false,
  isFromMarket: false,
  getNlpSetting: false,
  isEditorRefresh: false,
  errorDialog: {
    open: false,
    type: '',
  },
};

function canvasUI(state: CanvasState = initialState, action: CanvasAction) {
  const openScenario = state.openScenarioList;
  const openVariable = state.openVariableList;
  const openLibrary = state.openLibraryPanel;
  const openBlockEditor = state.openBlockEditorPanel;
  const openBlockPreview = state.openBlockPreviewPanel;
  switch (action.type) {
    /**
     * 에디터 새로고침 모달
     */
    case EDITOR_REFRESH_MODAL:
      return {
        ...state,
        isEditorRefresh: true,
      };

    /**
     * 시나리오 리스트 토글
     */
    case TOGGLE_SCENARIO_LIST:
      return {
        ...state,
        openLibraryPanel: openScenario ? openLibrary : false,
        openVariableList: openScenario ? openVariable : false,
        openScenarioList: !openScenario,
        openLeftPanel: !(!openScenario === false && openLibrary === false),
      };

    /**
     * 시나리오 리스트 오픈
     */
    case OPEN_SCENARIO_LIST:
      return {
        ...state,
        openScenarioList: true,
        openVariableList: false,
        openLibraryPanel: false,
        openLeftPanel: true,

        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
      };

    /**
     * 변수설정 리스트 토글
     */
    case TOGGLE_VARIABLE_LIST:
      return {
        ...state,
        openVariableList: !openVariable,
        openScenarioList: openVariable ? openScenario : false,
        openLibraryPanel: openVariable ? openLibrary : false,

        openLeftPanel: !(!openVariable === false && openLibrary === false),
      };

    /**
     * 변수설정 리스트 오픈
     */
    case OPEN_VARIABLE_LIST:
      return {
        ...state,
        openScenarioList: false,
        openVariableList: true,
        openLibraryPanel: false,
        openLeftPanel: true,

        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
      };

    /**
     * 라이브러리 패널 토글
     */
    case TOGGLE_LIBRARY_PANEL:
      return {
        ...state,
        openScenarioList: openLibrary ? openScenario : false,
        openLibraryPanel: !openLibrary,
        openLeftPanel: !(openScenario === false && !openLibrary === false),
      };

    /**
     * 라이브러리 패널 오픈
     */
    case OPEN_LIBRARY_PANEL:
      return {
        ...state,
        openScenarioList: false,
        openLibraryPanel: true,
        openLeftPanel: true,

        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
      };

    /**
     * 블럭 에디터 패널 토글
     */
    case TOGGLE_BLOCKEDITOR_PANEL:
      return {
        ...state,
        openBlockPreviewPanel: openBlockEditor ? openBlockPreview : false,
        openBlockEditorPanel: !openBlockEditor,
        openRightPanel: !(!openBlockEditor === false && openBlockPreview === false),
      };

    /**
     * 블럭 에디터 패널 열기
     */
    case OPEN_BLOCKEDITOR_PANEL:
      return {
        ...state,
        openBlockEditorPanel: true,
        openBlockPreviewPanel: false,
        openRightPanel: true,
      };

    /**
     * 블럭 에디터 패널 닫기
     */
    case CLOSE_BLOCKEDITOR_PANEL:
      return {
        ...state,
        openBlockPreviewPanel: false,
        openBlockEditorPanel: false,
        openRightPanel: false,
      };

    /**
     * 블럭 미리보기 패널 토글
     */
    case TOGGLE_BLOCKPREVIEW_PANEL:
      return {
        ...state,
        openBlockEditorPanel: openBlockPreview ? openBlockEditor : false,
        openBlockPreviewPanel: !openBlockPreview,
        openRightPanel: !(openBlockEditor === false && !openBlockPreview === false),
      };

    /**
     * 블럭 미리보기 패널 오픈
     */
    case OPEN_BLOCKPREVIEW_PANEL:
      return {
        ...state,
        openBlockEditorPanel: false,
        openBlockPreviewPanel: true,
        openRightPanel: true,
      };

    /**
     * 줌 셋팅
     */
    case SET_ZOOM_LEVEL:
      localStorage.setItem('zoomLevel', toString(action.payload));
      return {
        ...state,
        zoomLevel: action.payload,
      };

    /**
     * 줌 인
     */
    case SET_ZOOM_IN:
      localStorage.setItem('zoomLevel', toString(state.zoomLevel + 10));
      return {
        ...state,
        zoomLevel: state.zoomLevel + 10,
      };

    /**
     * 줌 아웃
     */
    case SET_ZOOM_OUT:
      localStorage.setItem('zoomLevel', toString(state.zoomLevel - 10));
      return {
        ...state,
        zoomLevel: state.zoomLevel - 10,
      };

    /**
     * 화면 딤드처리
     */
    case SET_DIMMED:
      return {
        ...state,
        isDimmed: action.payload,
      };

    /**
     * 화면 투명 딤드처리
     */
    case SET_LOCK_DIMMED:
      return {
        ...state,
        isLockDimmed: action.payload,
      };

    /**
     * 사용자 튜툐리얼 완료 여부
     */
    // case SET_TUTORIAL_DONE:
    //     // 완료값 로컬스토리지 저장
    //     // 로드시 로컬스토리지가 존재하면 완료로 간주함
    //     localStorage.setItem('isScenarioTutorialDone', 'true');
    //     return {
    //         ...state,
    //         isTutorialDone: true,
    //     };

    /**
     * 봇 세팅 화면으로 이동
     */
    case GET_SETTING_BOT:
      return {
        ...state,
        getSettingBot: true,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
        getNlpSetting: false,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };
    /**
     * 봇 배포내역 화면으로 이동
     */
    case GET_DEPLOY_BOT:
      return {
        ...state,
        getSettingBot: false,
        getDeployBot: true,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
        getNlpSetting: false,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };
    /**
     * 봇 상담내역 화면으로 이동
     */
    case GET_CONSULT_BOT:
      return {
        ...state,
        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: true,
        getStatisticsBot: false,
        getAuthBot: false,
        getNlpSetting: false,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };

    /**
     * 봇 상담내역 화면으로 이동
     */
    case GET_AUTH_BOT:
      return {
        ...state,
        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: true,
        getNlpSetting: false,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };

    /**
     * 봇 통계 화면으로 이동
     */
    case GET_STATISTICS_BOT:
      return {
        ...state,
        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: true,
        getAuthBot: false,
        getNlpSetting: false,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };

    /**
     * 자연어 화면으로 이동
     */
    case GET_NLP_BOT:
      return {
        ...state,
        getSettingBot: false,
        getDeployBot: false,
        getConsultBot: false,
        getStatisticsBot: false,
        getAuthBot: false,
        getNlpSetting: true,

        openScenarioList: false,
        openLibraryPanel: false,
        openLeftPanel: false,
        openRightPanel: false,
      };

    case GET_SURVEYFORM_WIDTH:
      return {
        ...state,
        surveyFormWidth: state.surveyFormWidth,
      };
    case SET_SURVEYFORM_WIDTH:
      return {
        ...state,
        surveyFormWidth: action.payload,
      };
    case SET_FROM_MARKET:
      return {
        ...state,
        isFromMarket: true,
      };

    case SET_ERROR_DIALOG:
      return {
        ...state,
        errorDialog: action.paylead,
      };

    default:
      return state;
  }
}

export default canvasUI;
