/**
 * 유저 토큰 훅
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { useCookies } from 'react-cookie';

export default function useAuthToken() {
  const [cookies, setCookie, removeCookie] = useCookies();
  const cookieName = 'awesome_bot_accessToken';
  const localStorageName = 'awesome_bot_refreshToken';
  // const cookieOptions = { domain: 'happytalk.io', path: '/', secure: true };
  const cookieOptions = { domain: process.env.REACT_APP_DOMAIN, path: '/' };
  const getToken = () => cookies.awesome_bot_accessToken || undefined;
  const setToken = (token: string | undefined) => {
    setCookie(cookieName, token, cookieOptions);
  };
  const getLocalToken = () => localStorage.getItem(localStorageName) || undefined;
  const setLocalToken = (token: string | '{}') => {
    //로컬스토리지에 refresh토큰 저장
    localStorage.setItem(localStorageName, token);
  };
  const removeToken = () => {
    removeCookie(cookieName, cookieOptions);
  };
  const logout = () => {
    removeCookie(cookieName, cookieOptions);
    localStorage.removeItem(localStorageName);
    localStorage.removeItem('memberId');
    localStorage.setItem('isIdle', 'none');
    localStorage.removeItem('ar');
    localStorage.removeItem('happytalk');
    // window.location.href = '/';
  };

  return { getToken, setToken, removeToken, getLocalToken, setLocalToken, logout };
}
