import React, { useState } from 'react';
import { Props, TabItemInterface } from '../../Interfaces/Tab';
import classNames from 'classnames';
import { BaseTab, BaseTabItem } from '../../Styles/Tab';

const Tab = ({ items, onChange, type = 'card' }: Props) => {
  const onChangeTab = ({ label, key, active, disabled }: TabItemInterface) => {
    onChange({ label, key, active, disabled });
  };
  return (
    <BaseTab>
      {items.map(({ label, key, active, disabled }) => {
        return (
          <BaseTabItem
            type={type}
            key={key}
            className={classNames({ active, disabled })}
            onClick={() => {
              onChangeTab({ label, key, active, disabled });
            }}
          >
            {label}
          </BaseTabItem>
        );
      })}
    </BaseTab>
  );
};

export default Tab;
