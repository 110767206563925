import { MenuStyleType } from 'constants/CommonType';

const SET_MENU_STYLE = 'commonitem/SET_MENU_STYLE' as const;

export const setMenuStyle = (data: MenuStyleType) => ({
  type: SET_MENU_STYLE,
  payload: data,
});

interface CommonItemProps {
  menuStyle: MenuStyleType[];
}
const initialState = {
  menuStyle: [],
};

type CommonItemAction =
  | ReturnType<typeof setMenuStyle>

function CommonItem(state: CommonItemProps = initialState, action: CommonItemAction) {
  switch (action.type) {
    case SET_MENU_STYLE: {
      return {
        menuStyle: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default CommonItem;
