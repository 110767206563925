import styled from '@emotion/styled';
import { setTypeStyle } from '../Text';

export const BaseTable = styled.table`
  display: grid;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;

  thead {
    display: grid;
    position: sticky;
    top: 0;
    z-index: 1;

    background-color: var(--tertiary-background);

    tr {
      display: flex;
      width: 100%;

      th {
        color: var(--secondary-black);
        ${setTypeStyle({ type: 'body-title' })}
        background-color: var(--tertiary-background);
      }

      text-align: left;
      border-bottom: 1px solid var(--separator);
    }
  }

  tbody {
    display: grid;
    position: relative; //needed for absolute positioning of rows
    background-color: var(--white);

    tr {
      display: flex;
      position: absolute;
      border-bottom: 1px solid var(--separator);
      width: 100%;
      background-color: var(--white);

      td {
        color: var(--primary-black);
        ${setTypeStyle({ type: 'body-text' })}
        background-color: var(--white);

        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        transition-duration: 0.2s;
      }
    }

    tr:hover {
      td {
        background-color: var(--black-5);
      }
    }
  }

  th,
  td {
    padding: 12px;

    flex-grow: 1;
  }

  transition-duration: 200ms;

  .isPinColumn {
    background-color: var(--white) !important;
  }
`;
