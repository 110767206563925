/**
 * axios 설정
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import axios from 'axios';
import cookie from 'react-cookies';

export const pending: any = {};
const CancelToken = axios.CancelToken;
const expiredMsgs = ['jwt expired', 'expire'];

const removePending = async (config: any, f?: any) => {
  const url = config?.url.replace(config.baseURL, '/');
  const flagUrl = url;

  if (flagUrl in pending) {
    if (!f) {
      delete pending[flagUrl];
    }
  } else {
    if (f) {
      pending[flagUrl] = f; // store the cancel function
    }
  }
};

const goLoginPage = () => {
  cookie.remove('awesome_bot_accessToken', { path: '/' });
  window.location.href = '/auth/login';
};

axios.interceptors.request.use(
  async (config: any) => {
    /** 서버에서 쿠키 받기 위한 설정 */
    const idleStorage = localStorage.getItem('isIdle');
    config.headers.idle = idleStorage;

    if (config.headers.Authorization) {
      return Promise.resolve(config);
    }

    let accessToken: any = cookie.load('awesome_bot_accessToken');
    config.headers.Authorization = accessToken;
    config.cancelToken = new CancelToken(async (c) => {
      await removePending(config, c);
    });

    return Promise.resolve(config);
  },
  async (error: any) => {
    return Promise.reject(error);
  },
);

axios.interceptors.response.use(
  (response: any) => {
    const resURL = `${response.config.url.split(':')[0]}://${response.config.url.split('/')[2]}`;
    if (resURL === process.env.REACT_APP_API_USER_HOST) {
      return response;
    }

    if (response.data.data?.others) {
      const logout = 'logout';
      cookie.remove('awesome_bot_accessToken', { path: '/' });
      window.location.href = `/auth/login/${logout}`;
    }

    cookie.save('awesome_bot_accessToken', response.headers.authorization || null, {
      domain: process.env.REACT_APP_DOMAIN,
      path: '/',
    });

    removePending(response.config);
    if (response.data.status === 'error') {
      return Promise.reject({
        response: response,
        message: response.data.message,
      });
    }
    return response;
  },
  (error: any) => {
    removePending(error.config);
    const isLogOutOpen = globalThis.isLogOut ? globalThis.isLogOut() : false;

    if (isLogOutOpen) return Promise.resolve();

    if (!axios.isCancel(error)) {
      if (!error.response) return Promise.reject(error);

      const isAuthError = error.response.status === 401 && error.response.statusText === 'Unauthorized';
      if (!isAuthError) {
        return Promise.reject({
          response: error.response,
          message: error.response.data.message,
        });
      }

      const isNotAwesomebot = localStorage.getItem('happytalk') === 'yes' || localStorage.getItem('ar') === 'yes';
      console.log({
        expredCheck: expiredMsgs.includes(error.response.data.message),
        isNotAwesomebot,
      });
      if (expiredMsgs.includes(error.response.data.message) && isNotAwesomebot) {
        globalThis.externalLogOut();
        return Promise.resolve({ data: { data: 'expired' } });
      }

      goLoginPage();
      return Promise.reject();
    }
  },
);

export default axios;
