import React from 'react';
import { Props } from '../../Interfaces/Box';
import { BaseBox } from '../../Styles/Box';

const Box = ({
  children,
  className,
  display,
  backgroundColor,
  justifyContent,
  margin,
  marginBottom,
  marginLeft,
  marginRight,
  marginTop,
  paddingBottom,
  padding,
  paddingLeft,
  paddingRight,
  paddingTop,
  overflow,
  position,
  top,
  right,
  left,
  bottom,
  flexWrap,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  border,
  borderRadius,
  borderTop,
  borderLeft,
  borderRight,
  borderBottom,
  alignItems,
  float,
  verticalAlign,
  textAlign,
  gap,
  onClick,
  flexDirection,
  whiteSpace,
  wordBreak,
  alignSelf,
}: Props) => {
  return (
    <BaseBox
      width={width}
      minWidth={minWidth}
      maxWidth={maxWidth}
      height={height}
      minHeight={minHeight}
      maxHeight={maxHeight}
      display={display}
      flexWrap={flexWrap}
      justifyContent={justifyContent}
      alignItems={alignItems}
      gap={gap}
      flexDirection={flexDirection}
      alignSelf={alignSelf}
      margin={margin}
      marginTop={marginTop}
      marginLeft={marginLeft}
      marginRight={marginRight}
      marginBottom={marginBottom}
      padding={padding}
      paddingTop={paddingTop}
      paddingLeft={paddingLeft}
      paddingRight={paddingRight}
      paddingBottom={paddingBottom}
      position={position}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      overflow={overflow}
      backgroundColor={backgroundColor}
      border={border}
      borderRadius={borderRadius}
      borderTop={borderTop}
      borderLeft={borderLeft}
      borderRight={borderRight}
      borderBottom={borderBottom}
      float={float}
      verticalAlign={verticalAlign}
      textAlign={textAlign}
      whiteSpace={whiteSpace}
      wordBreak={wordBreak}
      onClick={onClick}
      className={className}
    >
      {children}
    </BaseBox>
  );
};

export default Box;
