export default {
  language_en: "English",
  language_ko: "한국어",
  top: {
    deploy: "배포하기",
    preview: "미리보기",
    botList: "봇 만들기",
    workList: "",
    deployList: "",
    authList: ""
  },
  botList: {
    title: "봇 리스트",
    subTitle: "나의 봇",
    subMsg: "챗봇을 생성하고 편집할 수 있습니다."
  },
}

