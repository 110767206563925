import {
  botSessionType,
  botSessionConfigType,
  participationItemsType, participationType, participationConfigType,
} from '../../constants/Types';

const GET_PARTICIPATION = 'participation/GET_PARTICIPATION' as const;
const UPDATE_PARTICIPATION = 'participation/UPDATE_PARTICIPATION' as const;
const UPDATE_ACTIVE_PARTICIPATION = 'participation/UPDATE_ACTIVE_PARTICIPATION' as const;
const SELECT_PARTICIPATION = 'participation/SELECT_PARTICIPATION' as const;
const SELECT_ALL_PARTICIPATION = 'participation/SELECT_ALL_PARTICIPATION' as const;

/**
 * 액션
 * @param participation
 * */
export const getParticipation = (participation: participationType) => ({
  type: GET_PARTICIPATION,
  payload: participation,
});
/**
 * 액션
 * @param participation
 * */
export const updateParticipation = (participation: participationType) => ({
  type: UPDATE_PARTICIPATION,
  payload: participation,
});
/**
 * 액션
 * @param participationItem
 * */
export const selectParticipation = (participationItem: participationItemsType | null) => ({
  type: SELECT_PARTICIPATION,
  payload: participationItem,
});
export const activeParticipation = (participationItem: participationItemsType | null) => ({
  type: UPDATE_ACTIVE_PARTICIPATION,
  payload: participationItem,
});

/**
 * 액션
 * @param isSelectAll
 * */
export const selectAllParticipation = (isSelectAll: boolean) => ({
  type: SELECT_ALL_PARTICIPATION,
  payload: isSelectAll,
});

interface ParticipationProps {
  lists: participationItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
  active: participationItemsType | undefined;
  config: participationConfigType | undefined;

}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
  active: undefined,
  config: {
    botId: '',
    isFriend: false,
    isSync: false,
    channel: [],
    search: {
      key: '',
      value: '',
    },
    order: {
      key: 'updatedAt',
      flag: '-1',
    },
  },
};

type ParticipationAction =
  | ReturnType<typeof getParticipation>
  | ReturnType<typeof updateParticipation>
  | ReturnType<typeof selectParticipation>
  | ReturnType<typeof selectAllParticipation>
  | ReturnType<typeof activeParticipation>

function Participation(state: ParticipationProps = initialState, action: ParticipationAction) {
  switch (action.type) {
    case GET_PARTICIPATION: {
      return {
        ...state,
        lists: action.payload.items.map((item: participationItemsType) => {
          return {
            isSelect:false,
            ...item,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    case UPDATE_PARTICIPATION: {
      return {
        ...state,
        lists: [
          ...state.lists,
          ...action.payload.items.map((item: participationItemsType) => {
            return {
              isSelect:false,
              ...item,
            };
          })],
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    case SELECT_PARTICIPATION: {
      return {
        ...state,
        lists: [
          ...state.lists.map((item: participationItemsType) => {
            if (item._id === action.payload?._id) {
              return {
                ...item
              };
            } else {
              return {
                ...item
              };
            }
          })],
      };
    }
    case SELECT_ALL_PARTICIPATION: {
      return {
        ...state,
        lists: [
          ...state.lists.map((item: participationItemsType) => {
            return {
              ...item
            };
          })],
      };
    }
    case UPDATE_ACTIVE_PARTICIPATION: {
      return {
        ...state,
        active: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default Participation;
