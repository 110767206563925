import styled from 'styled-components';

const Navbar = styled.div`
  border-bottom: solid 1px var(--pale-lilac);
  background-color: #ffffff;
  padding: 0 30px;
  height: 54px;
  line-height: 54px;
  overflow: hidden;
`;

export default Navbar;
