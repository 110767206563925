import styled from 'styled-components';
import reset from 'styled-reset';

const Panel = styled.div`
  background-color: #ffffff;
  padding: 60px 30px;
  width: 100%;
  &.continue {
    margin-top: 0px;
  }

  .panel-title {
    display: flex;
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.5px;
    color: var(--black);
    padding-bottom: 8px;
    vertical-align: middle;

    .count {
      display: inline-block;
      font-size: 13px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: normal;
      color: var(--light-blue);
      vertical-align: middle;
      margin-left: 3px;
    }
  }

  .panel-desc {
    font-size: 13px;
    font-weight: normal;
    line-height: 1.54;
    letter-spacing: -0.5px;
    color: var(--brown-grey);
  }
  .exceptedGlobal {
    max-width: 1000px;
    margin: 0 auto;
    pre {
      line-height: 22px;
      white-space: break-spaces;
    }
  }
  .exceptedGlobal * {
    ${reset}
    margin-block-start: 6px;
    margin-block-end: 6px;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    min-height: 10px;
    span {
      line-height: 22px;
      min-height: 22px;
    }
    p {
      margin: 0 0 10px;
    }
    table,
    th {
      border: 1px solid #1a1a1a;
      p {
        margin: 0;
      }
    }
    table,
    td {
      border: 1px solid #1a1a1a;
      padding: 0.4em;
      background-clip: padding-box;
      p {
        margin: 0;
      }
    }
    table {
      display: table;
      table-layout: auto !important;
      border: 1px solid #ccc;
      width: 100%;
      max-width: 100%;
      margin: 0 0 10px;
      background-color: transparent;
      border-spacing: 0;
      border-collapse: collapse;
      &.se-table-size-auto {
        width: auto !important;
      }
    }
    p {
      display: block;
      margin: 0 0 10px;
    }
  }
`;

export default Panel;
