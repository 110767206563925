const GET_TAGS = 'tags/GET_TAGS' as const;
const UPDATE_TAG = 'tags/UPDATE_TAG' as const;

/**
 * 액션
 * @param tag
 * */

export const getTagList = (tags: string[]) => ({
  type: GET_TAGS,
  payload: tags,
});

export const updateTagList = (tag: string[]) => ({
  type: UPDATE_TAG,
  payload: tag,
});

type TagsAction = ReturnType<typeof getTagList> | ReturnType<typeof updateTagList>;
function Tags(state: string[] = [], action: TagsAction) {
  switch (action.type) {
    case GET_TAGS:
      return action.payload;
    // case UPDATE_TAG:
    //   return {
    //     ...state,
    //     lists: state.lists
    //       ? state.lists.map((item: TagsType) => {
    //           if (item.id === action.payload.id) {
    //             return {
    //               id: action.payload.id,
    //               desc: action.payload.desc,
    //               name: action.payload.name,
    //               botId: action.payload.botId,
    //               scenarios: action.payload.scenarios,
    //             };
    //           }
    //           return item;
    //         })
    //       : state.lists,
    //   };
    // case ADD_TAG:
    //   return {
    //     ...state,
    //     lists: state.lists.concat({
    //       id: action.payload._id,
    //       desc: action.payload.desc,
    //       name: action.payload.name,
    //       botId: action.payload.botId,
    //       scenarios: action.payload.scenarios,
    //     }),
    //   };
    default:
      return state;
  }
}

export default Tags;
