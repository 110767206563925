import React from 'react';
import { BaseRow } from '../../Styles/Row';
import { Props } from '../../Interfaces/Row';

const Row = ({ children, space, className }: Props) => {
  return (
    <BaseRow className={className} space={space}>
      {children}
    </BaseRow>
  );
};

export default Row;
