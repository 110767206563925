import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyleProps } from '../../Interfaces/Flex';

const sizeStyle = ({ size }: Partial<StyleProps>) => {
  switch (size) {
    case 'none':
      return css`
        gap: 0;
      `;
    case 'xsmall':
      return css`
        gap: var(--s-1);
      `;
    case 'small':
      return css`
        gap: var(--s-2);
      `;
    case 'medium':
      return css`
        gap: var(--s-3);
      `;
    case 'large':
      return css`
        gap: var(--s-4);
      `;
    case 'xlarge':
      return css`
        gap: var(--s-5);
      `;
  }
};
export const BaseFlex = styled.div<Partial<Omit<StyleProps, 'style'>>>`
  display: flex;
  flex-direction: ${({ vertical }) => (vertical ? 'column' : 'row')};
  justify-content: ${({ justify }) => justify};
  align-items: ${({ align }) => align};
  ${sizeStyle}
`;
