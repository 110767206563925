import { Props } from '../../Interfaces/Panel';
import React from 'react';
import { BasePanel } from '../../Styles/Panel';

const Panel = ({ children, section = 'primary', group = 'white' }: Props) => {
  return (
    <BasePanel section={section} group={group}>
      {children}
    </BasePanel>
  );
};

export default Panel;
