import styled from 'styled-components';
import React from 'react';
import { useTooltip, Arrow } from 'react-laag';
import { Info } from 'constants/Icons';

interface TooltipProps {
  content: string;
  children?: any;
  position?:
    | 'TOP_CENTER'
    | 'BOTTOM_LEFT'
    | 'BOTTOM_RIGHT'
    | 'BOTTOM_CENTER'
    | 'TOP_LEFT'
    | 'TOP_RIGHT'
    | 'LEFT_BOTTOM'
    | 'LEFT_TOP'
    | 'LEFT_CENTER'
    | 'RIGHT_BOTTOM'
    | 'RIGHT_TOP'
    | 'RIGHT_CENTER'
    | 'CENTER'
    | undefined;
  width?: number;
  fixed?: boolean;
  isHtml?: boolean;
  margin?: string;
}

const ChildrenContainer = styled.span<{ hasChildren: boolean }>`
  & > {
    display: inline-block;
  }

  ${(props) => !props.hasChildren && 'vertical-align: sub;  display: inline-block;  padding-left: 3px; '}
`;

function Tooltip({ content, position, width, fixed, children, isHtml = false, margin }: TooltipProps) {
  const [element, triggerProps] = useTooltip(
    ({ isOpen, layerProps, layerSide, arrowStyle }) =>
      isOpen && (
        <div
          {...layerProps}
          style={{
            ...layerProps.style,
            zIndex: 9999990,
            backgroundColor: '#1a1a1a',
            color: '#fff',
            padding: '5px 10px',
            fontSize: 13,
            lineHeight: '18px',
            borderRadius: 5,
            border: '1px solid #1a1a1a',
            boxShadow: 'rgba(210, 210, 210, 0.5) 0px 0px 3px 0px',
            width: width ? width : 'auto',
            whiteSpace: 'pre-line',
            margin: margin,
            wordBreak: width ? 'break-all' : undefined,
          }}
        >
          <Arrow
            style={arrowStyle}
            layerSide={layerSide}
            size={6}
            angle={45}
            roundness={1}
            borderWidth={1}
            borderColor="#1a1a1a"
            backgroundColor="#1a1a1a"
          />
          {content}
        </div>
      ),
    {
      delayEnter: 100,
      delayLeave: 100,
      placement: {
        anchor: position || 'TOP_CENTER',
        snapToAnchor: true,
        triggerOffset: 12,
      },
      fixed: fixed ? fixed : false,
    },
  );

  return (
    <>
      {element}
      <ChildrenContainer hasChildren={children ? true : false} {...triggerProps}>
        {children ?? <Info />}
      </ChildrenContainer>
    </>
  );
}

export default React.memo(Tooltip);
