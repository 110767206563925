import styled from 'styled-components';

interface FormGroupOptions {
  /** 에러 */
  isError?: boolean;
}

const FormGroup = styled.div<FormGroupOptions>`
  position: relative;

  .count-langs {
    position: absolute;
    right: 10px;
    bottom: 12px;
    font-size: 11px;
    font-weight: normal;
    line-height: 1.45;
    letter-spacing: -0.55px;
    color: #989898;

    &.isMax {
      color: #2c55dd;
    }
  }

  input {
    ${(props) => props.isError && 'border-color: rgb(255, 0, 0);'}
  }
`;

export default FormGroup;
