interface ActiveTimeListProps {
  label: string;
  value: string;
  dayType: string;
  use: boolean;
  allDay: boolean;
  timeList: {
    startTime: string;
    endTime: string;
  }[];
}

export interface ActiveTimeProps {
  use: boolean;
  activeTimeType: string;
  activeTimeList: ActiveTimeListProps[];
}

export interface InActiveTimeProps {
  use?: boolean;
  timeList?: { settingDay: string; startTime: string; endTime: string }[];
}

export interface CounselorButtonTimeProps {
  use: boolean;
  list: {
    label: string;
    use: boolean;
    allDay: boolean;
    begin: string;
    end: string;
  }[];
}

/**
 * 초기값 타입
 */
export interface CounsultTimeProps {
  _id?: string;
  botId: string;
  activeTime: ActiveTimeProps;
  inActiveTime?: InActiveTimeProps;
  counselorButtonTime: CounselorButtonTimeProps;
}

/**
 * 초기값
 */
export const initialState: CounsultTimeProps = {
  botId: '',
  activeTime: {
    use: true,
    activeTimeType: 'onWeek',
    activeTimeList: [],
  },
  counselorButtonTime: {
    use: true,
    list: [
      { label: '일', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '월', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '화', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '수', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '목', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '금', use: true, allDay: true, begin: '00:00', end: '24:00' },
      { label: '토', use: true, allDay: true, begin: '00:00', end: '24:00' },
    ],
  },
};

const GET_BOT_COUNSULT_TIME = 'GET_BOT_COUNSULT_TIME' as const;
const UPDATE_BOT_COUNSULT_TIME = 'UPDATE_BOT_COUNSULT_TIME' as const;

/** action */
export const getCounsultTime = (data: CounsultTimeProps) => ({
  type: GET_BOT_COUNSULT_TIME,
  payload: data,
});

export const updateCounsultTime = (data: any) => ({
  type: UPDATE_BOT_COUNSULT_TIME,
  payload: data,
});

/**
 * 액션 타입 그룹
 */
type BotsAction = ReturnType<typeof getCounsultTime> | ReturnType<typeof updateCounsultTime>;

function BotCounsultTime(state: CounsultTimeProps = initialState, action: BotsAction) {
  switch (action.type) {
    case GET_BOT_COUNSULT_TIME:
      return { ...action.payload };
    case UPDATE_BOT_COUNSULT_TIME:
      return { ...action.payload };
    default:
      return state;
  }
}

export default BotCounsultTime;
