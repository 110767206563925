/* eslint-disable @typescript-eslint/no-unused-expressions */
import styled from 'styled-components';
import * as React from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import classNames from 'classnames';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const Range = createSliderWithTooltip(Slider.Range);

const Container = styled.div<{ color?: any; zoom?: any }>`
  width: 100%;
  display: flex;
  height: calc(100% - 23px);
  align-items: flex-end;
  .allDay {
    pointer-events: none;
    .rc-slider-handle {
      visibility: hidden;
    }
    .tooltip-0 {
      margin-right: 20px;
    }

    .tooltip-1 {
      margin-left: 20px;
    }
  }

  .rc-slider-rail {
    height: 5px;
  }
  .rc-slider-disabled {
    background-color: #fff;
    .rc-slider-track {
      display: none;
    }
    .rc-slider-handle {
      display: none;
    }
    .rc-slider-dot {
      border-color: #e9e9e9;
    }
  }
  .rc-slider-track {
    background-color: #b3c9ff;
    height: 8px;
    top: 3px;
  }
  .rc-slider-handle {
    border: solid 4px rgb(89, 136, 254);
    width: 15px;
    height: 15px;
  }
  .rc-slider-dot-active {
    border-color: #b3c9ff;
  }
  .rc-slider-handle:hover {
    border-color: #5988fe;
    top: 2px;
    width: 20px;
    height: 20px;
  }
  .rc-slider-handle:active {
    border-color: #5988fe;
    box-shadow: 0 0 5px ${(props) => (props.color.active ? props.color.active : 'blue')};
    cursor: grabbing;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px #d6e1ff;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: #5988fe;
  }
  .rc-slider-tooltip-placement-top {
    padding: 0;
  }
  .rc-slider-tooltip-inner {
    background-color: #fff;
    padding: 2px;
    min-width: 16px;
    height: 16px;
    box-shadow: 0 0 0px #fff;
    color: #000;
    font-size: 13px;
    font-weight: bold;
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: #fff;
    display: none;
  }

  .tooltip-0 {
    margin-right: 40px;
    font-size: ${(props) => (props.zoom === 'tooltip-0' ? '13px' : '13px')};
  }

  .tooltip-1 {
    margin-left: 40px;
    font-size: ${(props) => (props.zoom === 'tooltip-1' ? '13px' : '13px')};
  }

  .rc-slider-mark-text-active {
    font-size: 12px;
    color: #767676;
  }

  .rc-slider-mark-text {
    font-size: 12px;
    color: #d1d1d1;
  }
`;

interface RangeDayProps {
  min: number;
  max: number;
  value: any[];
  step: number;
  disabled?: boolean;
  alldayCheck?: boolean;
  marks?: any;
  color: any;
  onChange: (e: any) => void;
  tooltipFormat?: (e: number) => string;
}

const RangeDay: React.FC<RangeDayProps> = (props) => {
  const { min, max, value, step, disabled, marks, color, alldayCheck, onChange, tooltipFormat } = props;
  const [tooltips, setTooltips] = React.useState<Element[]>([]);
  const [zoom, setZoom] = React.useState<string>('');
  const ref = React.useRef();
  React.useEffect(() => {
    const elems = (ref.current as unknown as HTMLElement).getElementsByClassName('rc-slider-handle');

    Array.from(elems).forEach((elem) => {
      elem.addEventListener('mouseenter', (event: any) => {
        setZoom(event.target.classList.contains('rc-slider-handle-1') ? 'tooltip-0' : 'tooltip-1');
      });
      elem.addEventListener('mouseleave', (event: any) => {
        setZoom('');
      });
    });
  }, []);

  React.useEffect(() => {
    tooltips.forEach((tooltip: Element, idx: number) => {
      tooltip.classList.add(`tooltip-${idx}`);
    });
  }, [tooltips]);

  return (
    <Container color={color} as={Container} ref={ref} zoom={zoom}>
      <Range
        min={min}
        className={classNames({
          allDay: alldayCheck,
        })}
        max={max}
        value={value}
        step={step}
        disabled={disabled}
        onChange={onChange}
        marks={marks}
        tipProps={{ visible: disabled ? false : true }}
        tipFormatter={(value: number) => (tooltipFormat ? tooltipFormat(value) : value)}
      />
    </Container>
  );
};

export default React.memo(RangeDay);
