import { messageType, messageItemType } from '../../constants/Types';

/** 메시지  */
const GET_SESSION_MESSAGE = 'session/GET_MESSAGE' as const;

/**
 * 액션
 * @param message
 * */

export const getMessage = (message: messageType) => ({
  type: GET_SESSION_MESSAGE,
  payload: message,
});

interface MessageProps {
  lists: messageItemType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
};

type MessageAction = ReturnType<typeof getMessage>;

function Message(state: MessageProps = initialState, action: MessageAction) {
  switch (action.type) {
    case GET_SESSION_MESSAGE: {
      return {
        ...state,
        lists: action.payload.items.map((item: messageItemType) => {
          return {
            _id: item._id,
            botSessionId: item.botSessionId,
            endUserId: item.endUserId,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            content: item.content,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default Message;
