import { Props } from '../../Interfaces/Badge';
import { BalloonBadge, BaseBadge } from '../../Styles/Badge';
import React, { useCallback } from 'react';
import { Circle, SpeechBubbleTail } from '../../../../constants/Icons';

const Badge = ({ children, status = 'primary', type = 'default', className }: Props) => {
  const statusFill = () => {
    switch (status) {
      case 'warning':
        return 'var(--red)';
      case 'gray':
        return 'var(--secondary-black)';
      case 'primary':
        return 'var(--blue)';
      case 'none':
        return 'var(--secondary-black)';
      case 'success':
        return 'var(--green)';
    }
  };

  const statusIcon = () => {
    switch (status) {
      case 'warning':
        return 'var(--red)';
      case 'gray':
        return 'var(--secondary-black)';
      case 'primary':
        return 'var(--blue)';
      case 'none':
        return 'var(--secondary-black)';
      case 'success':
        return 'var(--green)';
    }
  };

  const BadgeCase = useCallback(() => {
    switch (type) {
      case 'balloon':
        return (
          <>
            <BalloonBadge status={status} type={type} className={className}>
              {children}
            </BalloonBadge>
            <SpeechBubbleTail fill={statusFill()} />
          </>
        );
      case 'default':
        return (
          <BaseBadge status={status} type={type} className={className}>
            {children}
          </BaseBadge>
        );
      case 'now':
        return (
          <BaseBadge status={status} type={type} className={className}>
            <Circle fill={statusFill()} />
            {children}
          </BaseBadge>
        );
    }
  }, [type, status, children, className]);

  return <BadgeCase />;
};

export default Badge;
