import { SurveyFormType } from 'constants/Types';

const GET_SURVEY_FORM = 'tags/GET_SURVEY_FORM' as const;
const ADD_SURVEY_FORM = 'tags/ADD_SURVEY_FORM' as const;
const SORT_SURVEY_FORM = 'tags/SORT_SURVEY_FORM' as const;

export const getSurveyFormCards = (surveyFormCards: SurveyFormType[]) => ({
  type: GET_SURVEY_FORM,
  payload: surveyFormCards,
});
export const addSurveyFormCards = (surveyFormCard: { active: SurveyFormType; lists: SurveyFormType[] }) => ({
  type: ADD_SURVEY_FORM,
  payload: surveyFormCard,
});
export const sortSurveyFormCards = (surveyFormCard: { active: SurveyFormType; lists: SurveyFormType[] }) => ({
  type: SORT_SURVEY_FORM,
  payload: surveyFormCard,
});

interface SurveyFormProps {
  lists: SurveyFormType[];
  active: SurveyFormType | undefined;
}

const initialState = {
  lists: [],
  active: undefined,
};

type SurveyFormAction = ReturnType<typeof getSurveyFormCards> | ReturnType<typeof addSurveyFormCards> | ReturnType<typeof sortSurveyFormCards>;
function SurveyFormCards(state: SurveyFormProps = initialState, action: SurveyFormAction) {
  switch (action.type) {
    case GET_SURVEY_FORM:
      return { lists: action.payload };
    case ADD_SURVEY_FORM:
      return { active: action.payload.active, lists: action.payload.lists };
    case SORT_SURVEY_FORM:
      return { active: action.payload.active, lists: action.payload.lists };
    default:
      return state;
  }
}

export default SurveyFormCards;
