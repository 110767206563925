import styled from 'styled-components';

/** 블럭 에디터 서브 타이틀 */
const BlockEditorSubTitle = styled.div<{ isOneDir?: boolean; top?: string }>`
  padding-bottom: 10px;
  font-size: ${(props) => (props.isOneDir ? '' : '12px')};
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: -0.5px;
  color: ${(props) => (props.isOneDir ? '#000000' : '#676767')};
  .required {
    color: #ff0000;
  }
  padding-top: ${(props) => (props.top ? props.top : 'none')};

  button {
    margin-left: 5px;
    border-radius: 14px;
    height: 20px;
    width: 75px;
    font-size: 11px;
  }
`;

export default BlockEditorSubTitle;
