import { syncListType, syncListItemsType } from '../../constants/Types';

const GET_SYNC_LIST = 'syncList/GET_LIST' as const;
const GET_INFO_BY_SESSION = 'syncList/GET_INFO_BY_SESSION' as const;

/**
 * 액션
 * @param syncList
 * */

export const getSyncList = (syncList: syncListType) => ({
  type: GET_SYNC_LIST,
  payload: syncList,
});

export const getSyncInfoBySession = (syncInfo: syncListItemsType) => ({
  type: GET_INFO_BY_SESSION,
  payload: syncInfo,
});

interface SyncListProps {
  lists: syncListItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
  active: syncListItemsType | null;
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
  active: null,
};

type SyncListAction = ReturnType<typeof getSyncList> | ReturnType<typeof getSyncInfoBySession>;

function SyncList(state: SyncListProps = initialState, action: SyncListAction) {
  switch (action.type) {
    case GET_SYNC_LIST: {
      return {
        ...state,
        lists: action.payload.item.map((item: syncListItemsType) => {
          return {
            _id: item._id,
            endUserId: item.endUserId,
            botId: item.botId,
            memberId: item.memberId,
            botSessionId: item.botSessionId,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            syncId: item.authUserInfo.syncId,
            syncNickname: item.authUserInfo.syncNickname,
            syncAge: item.authUserInfo.syncAge,
            syncEmail: item.authUserInfo.syncEmail,
            syncGender: item.authUserInfo.syncGender,
            syncPhone: item.authUserInfo.syncPhone,
            syncName: item.authUserInfo.syncName,
            fasiteNm: item.authUserInfo.fasiteNm,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    case GET_INFO_BY_SESSION: {
      return {
        ...state,
        active: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default SyncList;
