import React from 'react';
import { Props } from '../../Interfaces/Grid';
import { BaseGrid } from '../../Styles/Grid';

const Grid = ({ children, type = 'feature', width }: Props) => {
  return (
    <BaseGrid type={type} width={width}>
      {children}
    </BaseGrid>
  );
};

export default Grid;
