import {
  LinkModel,
  PortModel,
  // PortModelAlignment,
  PortModelGenerics,
  PortModelOptions,
} from '@projectstorm/react-diagrams-core';
import { DefaultBlockLinkModel } from '../../links/Default/DefaultBlockLinkModel';
import { AbstractModelFactory, DeserializeEvent } from '@projectstorm/react-canvas-core';
import * as Types from '../../../../constants/Types';

export interface DefaultPortModelOptions extends PortModelOptions {
  label?: string;
  in?: boolean;
  user_choice?: Types.UserChoiceDataOptions;
  isSubBlock?: boolean;
}

export interface DefaultPortModelGenerics extends PortModelGenerics {
  OPTIONS: DefaultPortModelOptions;
}

export class DefaultBlockPortModel extends PortModel<DefaultPortModelGenerics> {
  constructor(
    isIn: boolean,
    name?: string,
    label?: string,
    user_choice?: Types.UserChoiceDataOptions,
    isSubBlock?: boolean,
  );
  constructor(options: DefaultPortModelOptions);
  constructor(
    options: DefaultPortModelOptions | boolean,
    name?: string,
    label?: string,
    user_choice?: Types.UserChoiceDataOptions,
    isSubBlock?: boolean,
  ) {
    if (!!name) {
      options = {
        in: !!options,
        name: name,
        label: label,
        user_choice: user_choice,
        isSubBlock: isSubBlock,
      };
    }
    options = options as DefaultPortModelOptions;
    super({
      label: options.label || options.name,
      type: 'default',
      ...options,
    });
  }

  /** 버튼 정보 */
  getOptions() {
    return this.options;
  }

  getUserChoice() {
    return this.options.user_choice;
  }

  setUserChoice(data: any) {
    this.options.user_choice = data;
  }

  setUserChoiceNext(next: string) {
    if (this.options.user_choice) {
      this.options.user_choice.next = next;
    }

    return this.options.user_choice;
  }

  setUserChoiceName(name: string) {
    if (this.options.user_choice) {
      this.options.user_choice.name = name;
    }

    return this.options.user_choice;
  }

  setConditionUserChoiceNext(category: 'success' | 'fail', next: string) {
    if (this.options.user_choice)
      if (this.options.user_choice) {
        if (this.options.user_choice[category] && (this.options.user_choice[category] as Types.UserChoiceDataOptions)) {
          this.options.user_choice[category]!.next = next;
        }
      }
    return this.options.user_choice;
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.options.in = event.data.in;
    this.options.label = event.data.label;
    this.options.user_choice = event.data.user_choice;
    this.options.isSubBlock = event.data.isSubBlock;
  }

  serialize() {
    return {
      ...super.serialize(),
      in: this.options.in,
      label: this.options.label,
      user_choice: this.options.user_choice,
      isSubBlock: this.options.isSubBlock,
    };
  }

  link<T extends LinkModel>(port: PortModel, factory?: AbstractModelFactory<T>): T {
    let link = this.createLinkModel(factory);
    link.setSourcePort(this);
    link.setTargetPort(port);
    return link as T;
  }

  canLinkToPort(port: PortModel): boolean {
    if (port instanceof DefaultBlockPortModel) {
      return this.options.in !== port.getOptions().in;
    }
    return true;
  }

  createLinkModel(factory?: AbstractModelFactory<LinkModel>): LinkModel {
    let link = super.createLinkModel();
    if (!link && factory) {
      return factory.generateModel({});
    }
    return link || new DefaultBlockLinkModel();
  }
}
