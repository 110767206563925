import { ratingListType, ratingListItemsType } from 'constants/Types';

const GET_RATING_LIST = 'enduser/GET_RATING_LIST' as const;

/**
 * 액션
 * @param work
 * */

export const getRatingList = (rating: ratingListType) => ({
  type: GET_RATING_LIST,
  payload: rating,
});

interface ratingListProps {
  lists: ratingListItemsType[];
}

const initialState = {
  lists: [],
};

type RatingListAction = ReturnType<typeof getRatingList>;

function RatingList(state: ratingListProps = initialState, action: RatingListAction) {
  switch (action.type) {
    case GET_RATING_LIST: {
      return {
        ...state,
        lists: action.payload.items.map((item: ratingListItemsType) => {
          return {
            ...item,
          };
        }),
      };
    }
    default: {
      return state;
    }
  }
}

export default RatingList;
