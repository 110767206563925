import styled from 'styled-components';

export const CloseButtonContainer = styled.div<{ theme: string }>`
  margin: 15px 30px;
  display: flex;
  flex-direction: row;
`;

export const CloseContentContainer = styled.div<{ theme: string }>`
  padding: 50px 0px;
  border-bottom: 1px solid #e5e6eb;
  width: 100%;
  text-align: center;
`;
