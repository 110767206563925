import styled from 'styled-components';
import { CSSProperties } from 'react';

interface BoxProps {
  height?: CSSProperties['height'] | number | undefined;
  width?: CSSProperties['width'] | number | undefined;
  display?: CSSProperties['display'] | undefined;
  margin?: CSSProperties['margin'] | string | number | undefined;
  marginTop?: CSSProperties['marginTop'] | number | undefined;
  marginRight?: CSSProperties['marginRight'] | number | undefined;
  marginBottom?: CSSProperties['marginBottom'] | number | undefined;
  marginLeft?: CSSProperties['marginLeft'] | number | undefined;
  padding?: CSSProperties['padding'] | number | undefined;
  paddingTop?: CSSProperties['paddingTop'] | number | undefined;
  paddingRight?: CSSProperties['paddingRight'] | number | undefined;
  paddingBottom?: CSSProperties['paddingBottom'] | number | undefined;
  paddingLeft?: CSSProperties['paddingLeft'] | number | undefined;
  fontSize?: CSSProperties['fontSize'] | number | undefined;
  textAlign?: CSSProperties['textAlign'] | undefined;
  color?: CSSProperties['color'] | undefined;
  lineHeight?: CSSProperties['lineHeight'] | number | undefined;
  backgroundColor?: CSSProperties['backgroundColor'] | undefined;
  justifyContent?: CSSProperties['justifyContent'] | undefined;

  button?: string | undefined;
  p?: string | undefined;
  div?: string | undefined;
  input?: string | undefined;
}

/** Number 에 px 붙히기 */
const checkType = (value: any) => (typeof value === 'number' ? `${value}px;` : `${value};`);

/** Box */
const Box = styled.div<BoxProps>`
  ${(props) => props.height && 'height:' + checkType(props.height)}
  ${(props) => props.width && 'width:' + checkType(props.width)}
    ${(props) => props.display && 'display:' + checkType(props.display)}
    ${(props) => props.margin && 'margin:' + checkType(props.margin)}
    ${(props) => props.marginTop && 'margin-top:' + checkType(props.marginTop)}
    ${(props) => props.marginRight && 'margin-right:' + checkType(props.marginRight)}
    ${(props) => props.marginBottom && 'margin-bottom:' + checkType(props.marginBottom)}
    ${(props) => props.marginLeft && 'margin-left:' + checkType(props.marginLeft)}
    ${(props) => props.padding && 'padding:' + checkType(props.padding)}
    ${(props) => props.paddingTop && 'padding-top:' + checkType(props.paddingTop)}
    ${(props) => props.paddingRight && 'padding-right:' + checkType(props.paddingRight)}
    ${(props) => props.paddingBottom && 'padding-bottom:' + checkType(props.paddingBottom)}
    ${(props) => props.paddingLeft && 'padding-left:' + checkType(props.paddingLeft)}
    ${(props) => props.fontSize && 'font-size:' + checkType(props.fontSize)}
    ${(props) => props.textAlign && 'text-align:' + props.textAlign}
    ${(props) => props.justifyContent && 'justify-content:' + props.justifyContent}
    ${(props) => props.color && `color: ${props.color}`}
    ${(props) => props.lineHeight && 'line-height:' + checkType(props.lineHeight)}
    ${(props) => props.backgroundColor && `background-color: ${props.backgroundColor}`}
    

    /** Childs.. */
    ${(props) => props.button && `button {${props.button}}`}
    ${(props) => props.p && `p {${props.p}}`}
    ${(props) => props.button && `div {${props.div}}`}
    ${(props) => props.button && `input {${props.input}}`}
`;
const a = styled.div`
  display: initial;
`;
export default Box;
