import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import { kr, en } from '../Locales'

i18n
  // .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: { kr, en },
    lng: 'ko', //초기 언어 세팅 
    fallbackLng: ['kr', 'en'],
    debug: true,
    // defaultNS: "translations",
    // keySeparator: false,
    // ns: "translations",
    interpolation: {
      escapeValue: false
    },
    detection: { order: ['path', 'navigator'] }

    // react: {
    // 	bindI18n: 'languageChanged',
    // 	bindI18nStore: '',
    // 	transEmptyNodeValue: '',
    // 	transSupportBasicHtmlNodes: true,
    // 	transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    // 	useSuspense: true,
    // }
  }, (err, t) => {
    console.log('translations >> ', err)
  })

export default i18n;

export const languages = ['en', 'ko'] as const;

export type Languages = typeof languages[number];