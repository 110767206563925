import { botSessionType, botSessionItemsType, botSessionConfigType, variablesItemType } from '../../constants/Types';

const GET_BOT_SESSION = 'botSession/GET_LIST' as const;
const UPDATE_BOT_SESSION = 'botSession/UPDATE_LIST' as const;
/** 봇세션 액티브 */
const UPDATA_ACTIVE_BOT_SESSION = 'botSession/UPDATA_ACTIVE_BOT_SESSION' as const;

const UPDATE_BOT_SESSION_CONFIG = 'botSession/UPDATE_BOT_SESSION_CONFIG' as const;

const UPDATE_ACTIVE_VARIABLE_SESSION = 'botSession/UPDATE_ACTIVE_VARIABLE_SESSION' as const;

/**
 * 액션
 * @param botSession
 * */

export const getBotSession = (botSession: botSessionType) => ({
  type: GET_BOT_SESSION,
  payload: botSession,
});
export const updateBotSession = (botSession: botSessionType) => ({
  type: UPDATE_BOT_SESSION,
  payload: botSession,
});
export const updateActiveBotSession = (botSession: botSessionItemsType | null) => ({
  type: UPDATA_ACTIVE_BOT_SESSION,
  payload: botSession,
});

export const updateBotSessionConfig = (botSessionConfig: botSessionConfigType | null) => ({
  type: UPDATE_BOT_SESSION_CONFIG,
  payload: botSessionConfig,
});

export const updateActiveVariableSession = (variable: variablesItemType | null) => ({
  type: UPDATE_ACTIVE_VARIABLE_SESSION,
  payload: variable,
});

interface BotSessionProps {
  lists: botSessionItemsType[];
  paging: {
    size: number;
    last: number;
    totalCount: number;
  };
  active: botSessionItemsType | undefined;
  config: botSessionConfigType | undefined;
  activeVariables: variablesItemType | undefined;
}

const initialState = {
  lists: [],
  paging: {
    size: -1,
    last: -1,
    totalCount: -1,
  },
  active: undefined,
  activeVariables: undefined,
  config: {
    botId: '',
    range: 'all',
    isFriend: false,
    isAuth: false,
    channel: [],
    search: {
      key: '',
      value: '',
    },
    order: {
      key: 'updatedAt',
      flag: '-1',
    },
  },
};

type BotSessionAction =
  | ReturnType<typeof getBotSession>
  | ReturnType<typeof updateBotSession>
  | ReturnType<typeof updateActiveBotSession>
  | ReturnType<typeof updateBotSessionConfig>
  | ReturnType<typeof updateActiveVariableSession>;

function BotSession(state: BotSessionProps = initialState, action: BotSessionAction) {
  switch (action.type) {
    case GET_BOT_SESSION: {
      return {
        ...state,
        lists: action.payload.items.map((item: botSessionItemsType) => {
          return {
            _id: item._id,
            endUserId: item.endUserId,
            providerId: item.providerId,
            botId: item.botId,
            channel: item.channel,
            createdAt: item.createdAt,
            updatedAt: item.updatedAt,
            currentBlockId: item.currentBlockId,
            currentScenarioId: item.currentScenarioId,
            prevBlockId: item.prevBlockId,
            name: item.name,
            isFriend: item.isFriend,
            isAuth: item.isAuth,
            isSync: item.isSync,
            plusfriendUserKey: item.plusfriendUserKey,
            variables: item.variables,
          };
        }),
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    case UPDATE_BOT_SESSION: {
      return {
        ...state,
        lists: [
          ...state.lists,
          ...action.payload.items.map((item: botSessionItemsType) => {
            return {
              _id: item._id,
              endUserId: item.endUserId,
              providerId: item.providerId,
              botId: item.botId,
              channel: item.channel,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              currentBlockId: item.currentBlockId,
              currentScenarioId: item.currentScenarioId,
              prevBlockId: item.prevBlockId,
              name: item.name,
              isFriend: item.isFriend,
              isAuth: item.isAuth,
              isSync: item.isSync,
              plusfriendUserKey: item.plusfriendUserKey,
              variables: item.variables,
            };
          }),
        ],
        paging: {
          size: action.payload.itemCount,
          last: action.payload.lastPage,
          totalCount: action.payload.totalCount,
        },
      };
    }
    case UPDATA_ACTIVE_BOT_SESSION: {
      return {
        ...state,
        active: action.payload,
      };
    }
    case UPDATE_BOT_SESSION_CONFIG: {
      return {
        ...state,
        config: action.payload,
      };
    }
    case UPDATE_ACTIVE_VARIABLE_SESSION: {
      return {
        ...state,
        activeVariables: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default BotSession;
