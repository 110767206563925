/* eslint-disable array-callback-return */
/**
 * PrivateRoute
 */

import React, { useCallback, useEffect, useState } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import useAuthToken from 'hooks/useAuthToken';
import useMember from 'hooks/useMember';

import { Dialog } from 'happytalk-design-guide';
import { Box } from 'modules/Styled';

import { pending } from 'modules/Http';
import ErrorDialog from '../ErrorDialog';
import IdleComponent from '../Idle';
import ReactGA from 'react-ga4';
import { getIsTemplateEditor } from 'modules/Common';

interface PrivateRouteProps {
  component: any;
  exact: any;
  path: string;
  exceptionPayment?: boolean;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ component: Component, ...rest }) => {
  const token = useAuthToken(); // 토큰 사용
  const member = useMember(); // 멤버 상태값
  const history = useHistory();
  const [isIdle, setIsIdle] = useState(false);

  const [isPaymentExpired, setIsPaymentExpired] = useState(false);

  const BrowserTitle = useCallback(() => {
    switch (member?.provider) {
      case 'happytalk':
        return (
          <Helmet>
            <title>happyBot New</title>
          </Helmet>
        );
      case 'sdcall':
        return (
          <Helmet>
            <title>ARS Flow Builder</title>
            <link rel="icon" type="image/png" href="/favicon-ar.ico" sizes="16x16" />
          </Helmet>
        );
      default:
        return (
          <Helmet>
            <title>AwesomeBot</title>
          </Helmet>
        );
    }
  }, [member]);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID as string);
  }, []);

  // /** pending 된 axios 요청 cancel */
  useEffect(() => {
    const isTemplate = getIsTemplateEditor(history);

    if (!isTemplate) {
      setIsIdle(true);
    }

    return history.listen((location: { pathname: any }) => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.send('pageview');
      Object.keys(pending).map((e) => {
        if (pending[e]) {
          pending[e]();
          delete pending[e];
        }
      });
    });
  }, [history]);

  useEffect(() => {
    if (member?.isServiceLock && member.payment?.isShutdown) {
      setIsPaymentExpired(!rest.exceptionPayment && true);
    }
    if (!member?.isServiceLock || !member.payment?.isShutdown) {
      setIsPaymentExpired(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [member]);

  return (
    <>
      {isIdle && <IdleComponent />}
      {token.getToken() && (
        <>
          <BrowserTitle />
          <Route
            exact={rest.exact}
            path={rest.path}
            render={(props) => (
              <>
                <Component {...rest} {...props} />
                <ErrorDialog />
              </>
            )}
          />
          {isPaymentExpired && (
            <Dialog
              width={`400px`}
              title={'미납 안내'}
              isOpen={isPaymentExpired}
              onClose={() => {
                window.location.replace(`/payment/dashboard`);
              }}
            >
              <Box height={10} />
              <span>서비스 요금이 미납되어 이용이 중지된 상태입니다.</span>
              <Box height={10} />
              <span>결제 완료 후 정상 이용이 가능합니다.</span>
            </Dialog>
          )}
        </>
      )}
    </>
  );
};

export default PrivateRoute;
