import styled from '@emotion/styled';
import { Props } from '../../Interfaces/Panel';
import { css } from '@emotion/react';

const sectionTypeStyle = ({ section }: Props) => {
  const marginSection = 8;
  switch (section) {
    case 'primary':
      return css`
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 0;
        border-radius: 8px;
      `;
    case 'secondary':
      return css`
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 ${marginSection}px;
        border-radius: 8px;
      `;
    case 'tertiary':
      return css`
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 ${marginSection * 2}px;
        border-radius: 8px;
      `;
    case 'quaternary':
      return css`
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 ${marginSection * 3}px;
        border-radius: 8px;
      `;
    default:
      return css`
        padding-left: 10px;
        padding-right: 10px;
        margin: 0 0;
        border-radius: 8px;
      `;
  }
};

const groupTypeStyle = ({ group }: Props) => {
  switch (group) {
    case 'white':
      return css`
        background-color: var(--white-group-background);
      `;
    case 'gray':
      return css`
        padding-top: 10px;
        padding-bottom: 10px;
        background-color: var(--gray-group-background);
      `;
    default:
      return css``;
  }
};

export const BasePanel = styled.div<Props>`
  ${sectionTypeStyle}
  ${groupTypeStyle}
`;
