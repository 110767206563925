import styled from 'styled-components';
import React from 'react';

export const WrapperDiv = styled.div`
  margin-bottom: 10px;
`;

interface MultiConditionTitle {
  index: number;
  isSetting: boolean;
}

const getMultiConditionTitle = ({ index, isSetting }: MultiConditionTitle) => {
  if (index === undefined) return '';
  const currentConditionNum = index + 1;
  const keyword = isSetting ? '조건 설정' : '조건';
  return index === 0 ? `${keyword} ${currentConditionNum}` : `조건 ${index} - No → ${keyword} ${currentConditionNum}`;
};

function MultiConditionTitle(data: MultiConditionTitle) {
  return <WrapperDiv>{getMultiConditionTitle(data)}</WrapperDiv>;
}

export default React.memo(MultiConditionTitle);
