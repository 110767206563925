import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { StyleProps } from '../../Interfaces/Tip';

const getColor = ({ state }: StyleProps) => {
  switch (state) {
    case 'advice':
      return css`
        background-color: var(--blue-fill);
        color: var(--blue);
        box-shadow: inset 1px 0 var(--blue);
      `;
    case 'denial':
    case 'error':
    case 'warning':
      return css`
        background-color: var(--red-fill);
        color: var(--red);
        box-shadow: inset 1px 0 var(--red);
      `;
    case 'info':
    default:
      return css`
        background-color: var(--tertiary-fill);
        color: var(--primary-black);
        box-shadow: inset 1px 0 var(--primary-black);
      `;
  }
};

const getFrame = ({ frame }: StyleProps) => {
  switch (frame) {
    case 'round':
      return css`
        border-radius: 8px;
      `;
    case 'square':
    default:
      return css`
        border-radius: 0;
      `;
  }
};

export const BaseTip = styled.div<StyleProps>`
  transition: all 0.2s;
  padding: 12px 16px;
  overflow: auto;

  align-self: stretch;

  &.leftLine {
    box-shadow: none;
  }

  ${(props) => [getFrame(props), getColor(props)]};
`;

export const TipContents = styled.div`
  display: flex;

  letter-spacing: -0.28px;
  line-height: 1.42;
  font-weight: 500;
  font-size: 14px;

  gap: 8px;

  white-space: pre-wrap;
`;

export const ImageContents = styled.div``;
export const LinkButtonContents = styled.button`
  border: 0;
  display: flex;
  align-items: center;
  gap: 4px;

  padding: 7px 0;

  float: right;

  font-size: 13px;
  font-weight: 700;
  line-height: 1.38;
  letter-spacing: -0.18px;
  background: transparent;
`;
