import styled from '@emotion/styled';
import { StyleProps } from '../../Interfaces/Input';

export const BaseInput = styled.input<StyleProps>`
  width: ${({ width }) => width || '284px'};
  height: ${({ height }) => height || '38px'};
  border-radius: 8px;
  border: 1px solid var(--quaternary-black);
  background: var(--white);
  padding: 0px 8px 0px 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1 0 0;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 1.42;
  letter-spacing: -0.28px;

  color: var(--primary-black);
  caret-color: var(--primary-color-bot);

  ::placeholder {
    color: var(--secondary-black);
  }

  :hover {
    border: 1px solid var(--primary-black);
  }

  :focus {
    outline: 3px solid var(--brand-interaction-focus-bot-focus);
    border: 1px solid var(--primary-color-bot);
  }

  transition: all 0.2s;
`;
