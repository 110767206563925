/**
 * Diagram link 추가
 * 기본 링크 모델
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { DefaultLabelModel, DefaultLinkModelListener, DefaultLinkModelOptions } from '@projectstorm/react-diagrams';
import { LabelModel, LinkModel, LinkModelGenerics, PortModel, PortModelAlignment } from '@projectstorm/react-diagrams-core';
import { BezierCurve } from '@projectstorm/geometry';
import { DeserializeEvent } from '@projectstorm/react-canvas-core';

export interface DefaultLinkModelGenerics extends LinkModelGenerics {
  LISTENER: DefaultLinkModelListener;
  OPTIONS: DefaultLinkModelOptions;
}

export class DefaultBlockLinkModel extends LinkModel<DefaultLinkModelGenerics> {
  constructor(options: DefaultLinkModelOptions = {}) {
    super({
      type: 'default',
      width: options.width || 2,
      color: options.color || '#ccc',
      selectedColor: options.selectedColor || '#2c55dd',
      curvyness: 50,
      ...options,
    });
  }

  calculateControlOffset(port: PortModel): [number, number] {
    const curvyness = this.options.curvyness || 50;

    if (port.getOptions().alignment === PortModelAlignment.RIGHT) {
      return [curvyness, 0];
    } else if (port.getOptions().alignment === PortModelAlignment.LEFT) {
      return [-curvyness, 0];
    } else if (port.getOptions().alignment === PortModelAlignment.TOP) {
      return [0, -curvyness];
    }
    return [0, this.options.curvyness || 50];
  }

  getSVGPath(): any | string {
    if (this.points.length === 2) {
      const curve = new BezierCurve();
      curve.setSource(this.getFirstPoint().getPosition());
      curve.setTarget(this.getLastPoint().getPosition());
      curve.setSourceControl(this.getFirstPoint().getPosition().clone());
      curve.setTargetControl(this.getLastPoint().getPosition().clone());

      if (this.sourcePort) {
        curve.getSourceControl().translate(...this.calculateControlOffset(this.getSourcePort()));
      }

      if (this.targetPort) {
        curve.getTargetControl().translate(...this.calculateControlOffset(this.getTargetPort()));
      }
      return curve.getSVGCurve();
    }
  }

  setCurvyness(curvyness: number) {
    this.options.curvyness = curvyness;
  }

  serialize() {
    return {
      ...super.serialize(),
      width: this.options.width,
      color: this.options.color,
      curvyness: this.options.curvyness,
      selectedColor: this.options.selectedColor,
    };
  }

  deserialize(event: DeserializeEvent<this>) {
    super.deserialize(event);
    this.options.color = event.data.color;
    this.options.width = event.data.width;
    this.options.curvyness = event.data.curvyness;
    this.options.selectedColor = event.data.selectedColor;
  }

  addLabel(label: LabelModel | string) {
    if (label instanceof LabelModel) {
      return super.addLabel(label);
    }
    let labelOb = new DefaultLabelModel();
    labelOb.setLabel(label);
    return super.addLabel(labelOb);
  }

  setWidth(width: number) {
    this.options.width = width;
    this.fireEvent({ width }, 'widthChanged');
  }

  setColor(color: string) {
    this.options.color = color;
    this.fireEvent({ color }, 'colorChanged');
  }
}
