/**
 * 템플릿 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import { TemplateProps } from '../../constants/Types';

/** 전체 템플릿 업데이트 */
const UPDATE_ALL_TEMPLATE = 'template/UPDATE_ALL_TEMPLATE' as const;

/**
 * 액션
 * @param template
 */
export const updateAllTemplate = (template: any) => ({
  type: UPDATE_ALL_TEMPLATE,
  payload: template,
});

/**
 * 액션 타입 그룹
 */
type TemplatesAction = ReturnType<typeof updateAllTemplate>;
/**
 * 초기값 타입
 */
interface TemplatesProps {
  lists: TemplateProps[];
  active: any | undefined;
}

/**
 * 초기값
 */
const initialState = {
  lists: [],
  active: undefined,
};

function Template(state: TemplatesProps = initialState, action: TemplatesAction) {
  switch (action.type) {
    /**
     * 전체 봇 리스트 업데이트
     */
    case UPDATE_ALL_TEMPLATE:
      return {
        ...state,
        lists: action.payload.map((item: TemplateProps) => {
          return {
            _id: item._id,
            name: item.name,
            categoryId: item.categoryId,
            categoryName: item.categoryName,
            poster: item.poster,
            price: item.price,
          };
        }),
      };

    default:
      return state;
  }
}

export default Template;
