import { Props } from '../../Interfaces/Text';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const setTypeStyle = (props: Partial<Props>) => {
  switch (props.type) {
    case 'body-title':
      return css`
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.18px;
        line-height: 20px;
      `;
    case 'body-text':
      return css`
        font-size: 14px;
        font-weight: normal;
        letter-spacing: -0.28px;
        line-height: 18px;
      `;
    case 'body-action13':
      return css`
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.18px;
        line-height: 18px;
      `;
    case 'sub-title':
      return css`
        font-size: 13px;
        font-weight: bold;
        letter-spacing: -0.28px;
        line-height: 18px;
      `;
    case 'sub-text':
      return css`
        font-size: 12px;
        font-weight: normal;
        letter-spacing: -0.28px;
        line-height: 16px;
      `;
    case 'title1':
      return css`
        font-size: 20px;
        font-weight: bold;
        letter-spacing: -0.45px;
        line-height: 24px;
      `;
    case 'title2':
      return css`
        font-size: 17px;
        font-weight: bold;
        letter-spacing: -0.43px;
        line-height: 22px;
      `;
    case 'title3':
      return css`
        font-size: 15px;
        font-weight: bold;
        letter-spacing: -0.23px;
        line-height: 20px;
      `;
    case 'title4':
      return css`
        font-size: 14px;
        font-weight: bold;
        letter-spacing: -0.23px;
        line-height: 20px;
      `;
    case 'dashboard-number':
      return css`
        font-size: 24px;
        font-weight: bold;
        letter-spacing: -0.45px;
        line-height: 28px;
      `;
    case 'custom':
      return css`
        font-size: ${props.fontSize || '13px'};
        font-weight: ${props.fontWeight || 'normal'};
        letter-spacing: ${props.letterSpacing || 'normal'};
        line-height: ${props.lineHeight};
      `;

    case 'inherit':
      return css`
        font-size: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        line-height: inherit;
      `;
    default:
      return css`
        color: #38373e;
        font-size: 13px;
        font-weight: normal;
      `;
  }
};

export const BaseText = styled.span<Props>`
  font-family: Pretendard, serif;
  display: inline;
  transition-duration: 0.2s;
  color: ${(props) => props.color || 'var(--primary-black)'};
  text-decoration: ${(props) => (props.onClick ? 'underline' : '')};
  cursor: ${(props) => (props.onClick ? 'pointer' : '')};
  white-space: ${(props) => (props.whiteSpace ? props.whiteSpace : 'nowrap')};
  ${setTypeStyle}
`;
