/**
 * 튜툐리얼 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

/** 튜툐리얼 스탭 설정 */
const SET_TUTORIAL_STEP = 'tutorial/SET_TUTORIAL_STEP' as const;

/**
 * 액션
 */
export const setTutorialStep = (step: number) => ({
  type: SET_TUTORIAL_STEP,
  payload: step,
});

/**
 * 액션 타입 그룹
 */
type TutorialAction = ReturnType<typeof setTutorialStep>;

/**
 * 초기값 타입
 */
interface TutorialState {
  step: number;
}

/**
 * 초기값
 */
const initialState: TutorialState = {
  // step: localStorage.getItem('isScenarioTutorialDone') ? -1 : 1,
  step: 1,
};

function Tutorial(state: TutorialState = initialState, action: TutorialAction) {
  switch (action.type) {
    /**
     * 튜툐리얼 스탭 설정
     */
    case SET_TUTORIAL_STEP:
      return {
        ...state,
        step: action.payload,
      };
    default:
      return state;
  }
}

export default Tutorial;
