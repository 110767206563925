import { SurveyAnswerType } from 'constants/Types';

const GET_ANSWER_LIST = 'stat/GET_ANSWER_LIST' as const;

export const getSurveyAnswerList = (answer: SurveyAnswerType) => ({
  type: GET_ANSWER_LIST,
  payload: answer,
});

const initialState = {
  formData: {
    title: '',
    desc: '',
    isSingleRes: false,
    isHappytalkSend: false,
    validTime: {},
  },
  answer: [
    {
      question: '',
      result: [],
      surveyFormId: '',
      type: '',
    },
  ],
};

type SurveyAnswerListAction = ReturnType<typeof getSurveyAnswerList>;

function SurveyAnswerList(state: SurveyAnswerType = initialState, action: SurveyAnswerListAction) {
  switch (action.type) {
    case GET_ANSWER_LIST: {
      return action.payload;
    }
    default: {
      return state;
    }
  }
}

export default SurveyAnswerList;
