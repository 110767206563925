/**
 * 로딩 컴포넌트
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import React, { useState, useEffect } from 'react';
import * as S from './styles';

interface LoadingProps {}

let timeout: number;

const Loading: React.FC<LoadingProps> = () => {
  /** 로딩바 노출 상태값 */
  const [isShowLoading, setIsShowLoading] = useState(false);

  /** 로딩바 노출 */
  useEffect(() => {
    clearTimeout(timeout);
    timeout = window.window.setTimeout(() => {
      setIsShowLoading(true);
    }, 800);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      <S.Container>
        {/* 페이크 상단바 */}
        {/* {window.location.pathname !== '/' && <div className="topbar" />} */}

        {/* 깜밖임 방지 30ms 초 후 로딩중 표시함 */}
        {isShowLoading && <div className="loading-text">LOADING...</div>}
      </S.Container>
    </>
  );
};

export default Loading;
