import styled from '@emotion/styled';
import { StyleProps } from '../../Interfaces/Grid';
import { css } from '@emotion/react';

const GridType = ({ type }: StyleProps) => {
  switch (type) {
    case 'feature':
      return css`
        margin-bottom: 15px;
      `;
    case 'group':
      return css`
        margin-bottom: 30px;
      `;
    case 'none':
      return css``;
  }
};

export const BaseGrid = styled.div`
  width: ${({ width }) => width};
  ${GridType}
  :last-child {
    margin-bottom: 0;
  }
`;
