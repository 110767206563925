import styled from 'styled-components';
import { MdAddCircle } from 'react-icons/md';
import React, { useEffect, useState } from 'react';

interface NoImageContainerProps {
  /** 프로필 이미지용 컨테이너인경우 true */
  isProfile?: boolean;
}

interface UseImageContainerProps {
  /** 프로필 이미지용 컨테이너인경우 true */
  isProfile?: boolean;
}

interface ImageContainerProps {
  [x: string]: any;
  /** 이미지 주소 */
  url?: string;
  /** 권장 사이즈, 예) 800*400px */
  size?: string;
  /** 프로필 이미지용 컨테이너인경우 true */
  isProfile?: boolean;
}

/** 이미지 컨테이너 */
const Container = styled.div`
  cursor: pointer;
`;

/** no image */
const NoImageContainer = styled.div<NoImageContainerProps>`
  margin: 0;
  padding: 18px 14px;
  border-radius: 3px;
  color: #777;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 5px;
  border: dashed 1px #5ea2f9;
  background-color: #eff7fe;
  min-height: 100px;

  ${(props) =>
    props.isProfile &&
    `
        width: 60px;
        height: 60px;
        min-height: 60px;
        padding: 0;
    `}
`;

const UseImageContainer = styled.div<UseImageContainerProps>`
  display: flex;
  flex-direction: column;
  background: #e9eaea;
  border-radius: 6px;
  img {
    max-width: 100%;
  }

  ${(props) =>
    props.isProfile &&
    `
        width: 60px;
        height: 60px;
        padding: 0;

        img {
            width: 60px;
            height: 60px;
            border-radius: 5px;
        }
    `}
`;

/** 안내문구 */
const Label = styled.div`
  padding-top: 16px;
  font-size: 13px;
  font-weight: normal;
  line-height: 1.38;
  letter-spacing: -0.5px;
  color: #387dfe;
  vertical-align: middle;

  svg {
    position: relative;
    top: -2px;
    vertical-align: middle;
  }
`;

/** 권장 사이즈 텍스트 */
const Size = styled.div`
  padding-top: 4px;
  font-size: 11px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: -0.5px;
  color: #676767;
`;

const ImageContainer: React.FC<ImageContainerProps> = ({ url, size, isProfile, ...props }) => {
  /** 이미지 유효성 체크 */
  const [isValid, setIsValid] = useState(true);
  useEffect(() => {
    setIsValid(true);
  }, [url]);

  return (
    <Container>
      {!url && size && (
        <NoImageContainer {...props} isProfile={isProfile}>
          <Label>
            <MdAddCircle /> {!isProfile && `이미지 업로드`}
          </Label>
          {!isProfile && <Size>({size} 권장)</Size>}
          {isProfile && <Size>{size}</Size>}
        </NoImageContainer>
      )}

      {url && size && isValid && (
        <UseImageContainer {...props} isProfile={isProfile}>
          <img src={url} onError={() => setIsValid(false)} alt="" />
        </UseImageContainer>
      )}

      {url && size && !isValid && (
        <NoImageContainer {...props} isProfile={isProfile}>
          <Label>
            <MdAddCircle /> {!isProfile && `이미지 업로드`}
          </Label>
          {!isProfile && <Size>({size} 권장)</Size>}
          {isProfile && <Size>{size}</Size>}
        </NoImageContainer>
      )}
    </Container>
  );
};

export default React.memo(ImageContainer);
