/**
 * Diagram 기본 링크 생성
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */
import { PointModel, PortModel, DefaultLinkModel } from '@projectstorm/react-diagrams';
import { DefaultBlockLinkModel } from '../../links/Default/DefaultBlockLinkModel';
// import { CustomLabelModel } from '../../labels/Custom/CustomLabelModel';

/**
 * 기본 링크 생성
 * @param sourcePort: PortModel
 * @param targetPort: PortModel
 */
export const CreateLink = (sourcePort: PortModel, targetPort: PortModel): DefaultLinkModel => {
  const link = new DefaultBlockLinkModel();

  /** 소스 포트에 있는 기존 링크 전부 삭제 */
  if (Object.keys(sourcePort?.links)?.length > 0) {
    Object.values(sourcePort.links).forEach((slink) => slink.remove());
  }

  /** 소스 포트와 타겟 포트 재 등록 */
  link.setSourcePort(sourcePort);
  link.setTargetPort(targetPort);

  /** 포인트 좌표 찾기 */
  const sourcePoint = sourcePort.getCenter();
  const targetPoint = targetPort.getPosition();
  /** 포인트 생성 */
  const sourceNewPoint = new PointModel({ link });
  const targetNewPoint = new PointModel({ link });

  /** 소스 포트와 타겟 포인트 재 등록 */
  sourceNewPoint.setPosition(sourcePoint);
  targetNewPoint.setPosition(targetPoint);

  /** 포인트 셋팅 */
  link.setPoints([sourceNewPoint, targetNewPoint]);

  /** 커스텀 라벨 테스트 */
  // link.addLabel(
  //     new CustomLabelModel({
  //         offsetY: 0,
  //         offsetX: 0,
  //     })
  // );

  return link as DefaultLinkModel;
};

export default CreateLink;
