import styled from 'styled-components';
import * as Icons from '../../constants/Icons';

interface MenubarProps {
  /** 가로 사이즈(기본 113px) */
  width?: number | undefined;
  /** 사이즈 */
  size?: 'small' | 'middle' | 'large';
  marginTop?: string;
}

const Menubar = styled.div<MenubarProps>`
  position: relative;
  z-index: 14;
  width: ${(props) => (props.width ? props.width : 113)}px;
  box-shadow: 5px 5px 10px 0 rgba(212, 212, 212, 0.5);
  border: solid 1px var(--pale-lilac);
  background-color: #ffffff;

  &.profile {
    padding: 5px 0;
    margin-top: ${(props) => props.marginTop ? props.marginTop : '-15px'};
    border-radius: 5px;
  }

  button {
    display: none;
    width: 100%;
    border: 0;
    background-color: #fff;
    text-align: left;
    height: 30px;
    padding: 0 9px;
    font-size: 12px;
    font-weight: normal;
    line-height: 1.33;
    letter-spacing: -0.5px;
    color: var(--block);
    &.show {
      display: block;
    }
    &.current {
      background: var(--pale-lilac);
      pointer-events: none;
      :hover {
        background: var(--form-border);
      }
    }

    ${(props) =>
    props.size &&
    props.size === 'small' &&
    `
            height: 30px;
            padding: 0 9px;
            font-size: 12px;
            font-weight: normal;
        `}

    ${(props) =>
    props.size &&
    props.size === 'middle' &&
    `
            height: 32px;
            padding: 0 12px;
            font-size: 14px;
            font-weight: normal;
        `}

        ${(props) =>
    props.size &&
    props.size === 'large' &&
    `
            height: 36px;
            padding: 3px 14px;
            font-size: 16px;
            font-weight: normal;
        `}

        &:hover {
      background-color: #e9f1fe;
    }

    span {
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: middle;
      margin-right: 5px;
      margin-bottom: 5px;
      &.edit {
        background-image: url(${Icons.BlockEditorIcon});
        background-image: image-set(url(${Icons.BlockEditorIcon}) 1x, url(${Icons.BlockEditorIcon2x}) 2x, url(${Icons.BlockEditorIcon3x}) 3x);
      }
      &.preview {
        background-image: url(${Icons.BlockPreviewIcon});
        background-image: image-set(url(${Icons.BlockPreviewIcon}) 1x, url(${Icons.BlockPreviewIcon2x}) 2x, url(${Icons.BlockPreviewIcon3x}) 3x);
      }
      &.copy {
        background-image: url(${Icons.CopyIcon});
        background-image: image-set(url(${Icons.CopyIcon}) 1x, url(${Icons.CopyIcon2x}) 2x, url(${Icons.CopyIcon3x}) 3x);
      }
      &.setting {
        background-image: url(${Icons.BotMenuSetting});
        background-image: image-set(url(${Icons.BotMenuSetting}) 1x, url(${Icons.BotMenuSetting2x}) 2x, url(${Icons.BotMenuSetting3x}) 3x);
      }
      &.remove {
        background-image: url(${Icons.TrashIcon});
        background-image: image-set(url(${Icons.TrashIcon}) 1x, url(${Icons.TrashIcon2x}) 2x, url(${Icons.TrashIcon3x}) 3x);
      }
      &.logout {
        background-image: url(${Icons.LogoutIcon});
        background-image: image-set(url(${Icons.LogoutIcon}) 1x, url(${Icons.LogoutIcon2x}) 2x, url(${Icons.LogoutIcon3x}) 3x);
      }
      &.home {
        background-image: url(${Icons.HomeIcon});
        background-image: image-set(url(${Icons.HomeIcon}) 1x, url(${Icons.HomeIcon2x}) 2x, url(${Icons.HomeIcon3x}) 3x);
        background-position: center center;
      }
      &.publish {
        background-image: url(${Icons.IconPublishV2});
        background-image: image-set(url(${Icons.IconPublishV2}) 1x, url(${Icons.IconPublishV22x}) 2x, url(${Icons.IconPublishV23x}) 3x);
        background-position: center center;
      }
      &.api {
        background-image: url(${Icons.GuideIconGray});
        background-image: image-set(url(${Icons.GuideIconGray}) 1x, url(${Icons.GuideIconGray2x}) 2x, url(${Icons.GuideIconGray3x}) 3x);
        background-size: 80% 100%;
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
`;

export default Menubar;
