import { AxiosError } from 'axios';
import dayjs from 'dayjs';
import { toast, ToastOptions } from 'react-toastify';

import { DefaultLinkModel, DiagramEngine } from '@projectstorm/react-diagrams';
import { DefaultBlockModel } from 'modules/Diagram/blocks/Default/DefaultBlockModel';

import { DefaultBlockPortModel } from '../Diagram/ports/Default/DefaultBlockPortModel';
import CreateLink from '../Diagram/extends/link/CreateLink';
import * as Types from '../../constants/Types';
import { BlockActionNames } from 'constants/Block';
import { editorCanvasZoomFunc } from 'constants/Func';
import { notify } from '../Renewal/Components';

/**
 * 1
 * @param time
 * @returns
 */
export const getPrevOneMonthPeriod = (time: string | Date) => {
  if (!time) return '';
  const startDate = dayjs(time).subtract(1, 'month').format('YYYY-MM-DD');
  const endDate = dayjs(time).subtract(1, 'day').format('YYYY-MM-DD');

  return `${startDate} ~ ${endDate}`;
};

/**
 * 팝업 가운데 좌표 설정
 * @param width
 * @param height
 * @returns
 */
export const getPopupCoordinate = (width: number, height: number) => {
  const winWidth = document.body.clientWidth;
  const winHeight = document.body.clientHeight;
  const winX = window.screenX || window.screenLeft || 0;
  const winY = window.screenY || window.screenTop || 0;
  const left = winX + (winWidth - width) / 2;
  const top = winY + (winHeight - height) / 2;
  return { left, top };
};

export const objectConvertQueryString = (query: { [index: string]: any }) => {
  return !Object.keys(query).length ? '' : '?' + new URLSearchParams(query).toString();
};

export const toastOption: ToastOptions = {
  autoClose: 2000,
  hideProgressBar: false,
  closeOnClick: true,
};

export const handleToastSuccess = (response: any, isData: boolean = false) => {
  const data = response?.data?.data;
  notify({ status: 'primary', type: 'success', message: isData ? data : data?.message });
  return data;
};

export const handleToastError = (error: unknown) => {
  const err = error as AxiosError; // type assertion으로 error 타입을 확실하게 정해준다.
  if (err?.response?.status === 409 || err?.response?.data?.status === 'error') {
    notify({ status: 'primary', type: 'error', message: err.message });
  }
};

export const handleToast = (status: string, msg: string) => {
  if (status === 'error') {
    notify({ status: 'primary', type: 'error', message: msg });
  } else {
    notify({ status: 'primary', type: 'success', message: msg });
  }
};

export const defaultBlockRepaintCanvas = (block: DefaultBlockModel, engine: DiagramEngine) => {
  /**
   * 고객 초이스 버튼별 링크 생성
   */
  block.getAllBlockDatas().forEach((data: Types.BlockDataOptions, dataKey: number) => {
    /** 봇 버튼 사용시 링크 생성 */
    if (data.useButton) {
      data.user_choice.forEach((choice: Types.UserChoiceDataOptions, index: number) => {
        if (
          !engine ||
          choice === null ||
          choice.next === null ||
          (choice.type !== 'next_block' && choice.type !== 'kakaoOpenPlugIn') ||
          (block.getBlockSchema().api && block.getApiInfoData().isNonBlocking && index === 1)
        )
          return;

        /** 다음 블럭 */
        const nextBlock = engine.getModel().getNode(choice.next + '');
        if (nextBlock) {
          /** 포트 */
          const sourcePortName = block.makeChoiceName(dataKey, index);
          const sourcePort = block.getPort(sourcePortName);
          const targetPort = nextBlock.getPort('in');

          /** 링크 생성 */
          if (sourcePort instanceof DefaultBlockPortModel && targetPort instanceof DefaultBlockPortModel) {
            const link: DefaultLinkModel = CreateLink(sourcePort, targetPort);
            engine.getModel().addLink(link);
          }
        }
      });
    }

    /**
     * 고객 응답 사용시 링크 생성 하기
     */
    if (block.getBlockSchema().user_response && block.editorData.blockData[dataKey].user_response.next) {
      if (!engine) return;

      /** 대상 블럭 */
      const targetBlock = engine.getModel().getNode(block.editorData.blockData[dataKey].user_response.next + '');

      if (targetBlock) {
        /** 포트 */
        const sourcePort = block.getPort(`user-response`);
        const targetPort = targetBlock.getPort('in');

        /** 링크 생성 */
        if (sourcePort instanceof DefaultBlockPortModel && targetPort instanceof DefaultBlockPortModel) {
          const link: DefaultLinkModel = CreateLink(sourcePort, targetPort);
          engine.getModel().addLink(link);
        }
      }
    }
  });

  /**
   * 연속 블럭 라인 생성 하기
   * 상담콜봇 안내 블럭의 경우 userResponse가 true일때도 subblock을 사용한다.
   */
  if (
    ((block.getBlockSchema().user_response && block.getBlockSchema().action_name === BlockActionNames.AR_BUTTON) ||
      !block.getBlockSchema().user_response) &&
    block.getSubBlockId()
  ) {
    /** 타겟 블럭 찾기 */
    const targetBlock = engine.getModel().getNode(block.getSubBlockId() + '');

    if (targetBlock) {
      /** 포트 */
      const sourcePort = block.getPort('sub-block');
      const targetPort = targetBlock.getPort('sub-block-in');

      /** 링크 생성 */
      if (sourcePort instanceof DefaultBlockPortModel && targetPort instanceof DefaultBlockPortModel) {
        const link: DefaultLinkModel = CreateLink(sourcePort, targetPort);
        engine.getModel().addLink(link);
      }
    }
  }

  /**
   * 모듈 블럭 라인 연결
   */
  if (['module', 'oneDirection'].includes(block.getBlockType()) && block.editorData.moduleData.nextBlockId !== '') {
    /** 연결할 블럭 */
    const nextBlock = engine.getModel().getNode(block.editorData.moduleData.nextBlockId);

    if (nextBlock) {
      /** 포트 */
      const sourcePort = block.getPort('module-out');
      const targetPort = nextBlock.getPort('in');

      /** 링크 생성 */
      if (sourcePort instanceof DefaultBlockPortModel && targetPort instanceof DefaultBlockPortModel) {
        const link: DefaultLinkModel = CreateLink(sourcePort, targetPort);
        engine.getModel().addLink(link);
      }
    }
  }

  if (
    (block.getBlockSchema().variablesInit || block.getBlockSchema().variablesMatch) &&
    block.editorData.variablesData?.nextBlockId
  ) {
    const nextBlock = engine.getModel().getNode(block.editorData.variablesData?.nextBlockId);
    if (nextBlock) {
      const sourcePort = block.getPort('variables-out');
      const targetPort = nextBlock.getPort('in');

      /** 링크 생성 */
      if (sourcePort instanceof DefaultBlockPortModel && targetPort instanceof DefaultBlockPortModel) {
        const link: DefaultLinkModel = CreateLink(sourcePort, targetPort);
        engine.getModel().addLink(link);
      }
    }
  }
};

export const textOverLengthSubString = (text: string, length: number = 30) => {
  return text.substring(0, length) + (text.length > length ? '...' : '');
};

export const sortItemTypeOfString = (a: string, b: string, order: 'desc' | 'asc') => {
  switch (order) {
    case 'asc':
      return a.localeCompare(b);
    case 'desc':
      return b.localeCompare(a);
  }
};

export const sortItemTypeOfNumber = (a: number, b: number, order: 'desc' | 'asc') => {
  switch (order) {
    case 'asc':
      return a - b;
    case 'desc':
      return b - a;
  }
};

export const editorCanvasZoomIN = ({ engine, setZoom }: editorCanvasZoomFunc) => {
  const zoomLevel = engine.getModel().getZoomLevel();
  if (zoomLevel === 150) return;

  const model = engine.getModel();
  const level = zoomLevel + 10;
  setZoom(level);
  model.setOffset(model.getOffsetX() - level, model.getOffsetY() - level);
};

export const editorCanvasZoomOut = ({ engine, setZoom }: editorCanvasZoomFunc) => {
  const zoomLevel = engine.getModel().getZoomLevel();
  if (zoomLevel === 10) return;

  const model = engine.getModel();
  const level = zoomLevel - 10;
  setZoom(level);
  model.setOffset(model.getOffsetX() + level, model.getOffsetY() + level);
};

export const addAndSpliceArrayByOneDepth = (array: string[], value: string) => {
  const idx = array.indexOf(value);
  if (idx > -1) {
    array.splice(idx, 1);
    return [...array];
  } else {
    return [...array, value];
  }
};
export const replaceEmoji = (str: string) => {
  //이모지 코가 계속 추 되는것 같아서 유니코드로 범위를 잡기 까로워서 반대로 로직을 구현
  // 문자 + 특수문자 +스페이스
  const characterRegex = /[^\s0-9a-zA-Z~!@#$%^&*()_+|<>?:{}ㄱ-ㅎㅏ-ㅣ가-힣]/g;
  return str.replace(characterRegex, '');
};

export const getIsTemplateEditor = (history: any) => {
  return (
    /^\/readonly|tempeditor\//.test(history.location.pathname) && history.location.search.includes('type=template')
  );
};
