import { AlimTalkTemplateType,AlimTalkTemplateItemType } from '../../constants/Types';

const GET_ALIMTALK_TEMPLATE_LIST = 'transmission/GET_ALIMTALK_TEMPLATE_LIST' as const;

interface AlimTalkTemplateProps {
  lists: AlimTalkTemplateItemType[];
  active: AlimTalkTemplateItemType | null;
}

const init = {
  lists: [],
  active: null,
};

export const getAlimTalkTemplateList = (history: AlimTalkTemplateType) => ({
  type: GET_ALIMTALK_TEMPLATE_LIST,
  payload: history,
});

type BizMsgAction =
  | ReturnType<typeof getAlimTalkTemplateList>

function BizMsg(state: AlimTalkTemplateProps = init, action: BizMsgAction) {
  switch (action.type) {
    case GET_ALIMTALK_TEMPLATE_LIST:
      return {
        ...state,
        lists: action.payload.items,
      };
    default: {
      return state;
    }
  }
}

export default BizMsg;
