import React, { useState, useEffect, useMemo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router-dom';
import { Button } from 'happytalk-design-guide';

import * as S from './styles';

import useAuthToken from 'hooks/useAuthToken';
import useMember from 'hooks/useMember';

import useMemberActions from 'hooks/useMemberActions';
import useCanvasUIActions from 'hooks/useCanvasUIActions';

import { ERROR_CODE, ERROR_CONTENT } from 'constants/Error';

import { ExtendDialog } from 'modules/Styled';
import dayjs from 'dayjs';

const IdleComponent: React.FC = () => {
  const token = useAuthToken(); // 토큰 사용
  const member = useMember(); // 멤버 상태값
  const history = useHistory();

  const memberActions = useMemberActions(); // 멤버 상태값 컨트롤
  const canvasUIActions = useCanvasUIActions();

  const [isIdle, setIsIdle] = useState(false);
  const [idleStop, setIdleStop] = useState(false);
  const [idleTimeout, setIdleTimeout] = useState(10000);
  const [activeTimeSec, setActiveTimeSec] = useState(0);
  const [remaining, setRemaining] = useState(idleTimeout);
  const [showRemaining, setShowRemaining] = useState(false);
  const [sessionModal, setSessionModal] = useState({
    open: false,
    message: ERROR_CONTENT[ERROR_CODE.IDLE_TIMEOUT],
  });

  const oneMinute = useMemo(() => 60000, []);
  const isAwesomebot = useMemo(() => member.provider === 'awesomebot', [member]);

  const number = Number(member.loginExpire?.slice(0, -1));
  const unit = Number(
    member.loginExpire?.slice(-1) === 'h' ? 60 * oneMinute : member.loginExpire?.slice(-1) === 'm' ? oneMinute : 1000,
  );

  const windowClose = () => {
    token.logout();
    if (isAwesomebot) {
      history.push('/auth/login');
      return;
    }

    window.opener = null;
    window.open('', '_self');
    window.close();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePause = () => pause();

  const handleOnActive = () => {
    if (isIdle) return;

    setIsIdle(false);
  };

  const handleOnIdle = () => {
    if (getRemainingTime() !== 0) return;

    setIsIdle(true);
  };

  const handleOnEvents = async () => {
    setActiveTimeSec(0);

    if (idleStop) return;

    // 10초 이상 움직임 없으면 member 정보 가져오기
    if (activeTimeSec >= 10) {
      const result = await memberActions.loadMember();
      if (result) return;

      setIdleStop(true);
      setSessionModal((sessionModal) => ({ ...sessionModal, open: true }));

      return;
    }
    if (showRemaining) {
      setShowRemaining(false);
    }
  };

  const openSessionModal = () => {
    setSessionModal({
      open: true,
      message: ERROR_CONTENT[ERROR_CODE.TOKEN_EXPIRED],
    });
  };

  const getIsLogOutValue = () => {
    return sessionModal.open;
  };

  const { pause, getRemainingTime } = useIdleTimer({
    timeout: idleTimeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
    onAction: handleOnEvents,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
    ],
  });

  useEffect(() => {
    /** 마켓일때 fromMarket 상태값 변경 */
    if (sessionStorage.getItem('isFromMarket') === 'true') {
      canvasUIActions.onSetFromMarket();
    }

    globalThis.externalLogOut = openSessionModal;
    globalThis.isLogOut = getIsLogOutValue;
    globalThis.setErrorModal = (type: string) => {
      canvasUIActions.setErrorDialogDispatch({ open: true, type });
    };

    if (!member || showRemaining) return;

    setRemaining(getRemainingTime());
    const timer = setInterval(() => {
      const remainingTime = getRemainingTime();

      setActiveTimeSec((activeTimeSec) => activeTimeSec + 1);

      //20초 이하면 상단위에 표시
      if (remainingTime < 20000) {
        setShowRemaining(true);
        setRemaining(getRemainingTime());
      }

      if (remainingTime === 0) {
        const isNotAwesomebot = localStorage.getItem('happytalk') === 'yes' || localStorage.getItem('ar') === 'yes';
        clearInterval(timer);

        token.logout();
        if (isNotAwesomebot) {
          setSessionModal((sessionModal) => ({ ...sessionModal, open: true }));
        } else {
          history.push('/auth/login');
        }
      }
    }, 1000);

    // 토큰이 있고 멤버 정보가 없다면 로드
    if (token.getToken() && !member._id) {
      memberActions.loadMember();
    }

    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!member || showRemaining) return;

    //로컬스토리지는 도메인으로 계속 남아 있기 때문에 초기화를 시킨다.
    //로컬스토리지를 쓰는 이유는 http 모듈에서 저장소에 접근할수 없기 때문이다.
    //하지만 이부분은 jwtToken 으로 해결이 가능할듯하다?
    //토큰에 provider 만 넣으면 해결 될듯하다
    localStorage.removeItem('ar');
    localStorage.removeItem('happytalk');

    if (member.provider === 'sdcall') {
      localStorage.setItem('ar', 'yes');
    } else {
      localStorage.setItem('happytalk', member.provider === 'happytalk' ? 'yes' : 'no');
    }

    const awesomebotIdleTime = number * unit;
    const externalIdleTime = 120 * oneMinute;

    setIdleTimeout(isAwesomebot ? awesomebotIdleTime : externalIdleTime);
  }, [member]);

  useEffect(() => {
    if (isIdle) {
      handlePause();
      localStorage.setItem('isIdle', 'idle');
    } else {
      localStorage.setItem('isIdle', 'none');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isIdle]);

  return (
    <>
      {showRemaining && (
        <div
          style={{
            zIndex: 999999,
            fontSize: '10px',
            position: 'absolute',
            top: '5px',
            right: '20px',
            color: 'red',
          }}
        >
          {remaining > 0 ? `세션만료: ${Math.round(remaining / 1000)}초 후 로그아웃..` : '세션만료'}
        </div>
      )}

      {sessionModal.open && (
        <ExtendDialog
          width={`400px`}
          title={`안내`}
          className={'hide-close-button-dialog'}
          isOpen={sessionModal.open}
          onClose={() => {}}
        >
          <S.CloseContentContainer children={sessionModal.message} />
          <S.CloseButtonContainer
            children={<Button theme={'blue'} onClick={windowClose} children={'닫기'} size={'medium'} />}
          />
        </ExtendDialog>
      )}
    </>
  );
};

export default React.memo(IdleComponent);
