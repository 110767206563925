/**
 * 모듈 히스토리 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import { remove } from 'lodash';
import { HistoryProps } from '../../constants/Types';

/** 전체 모듈 히스토리 업데이트 */
const UPDATE_ALL_HISTORY = 'module/history/UPDATE_ALL_HISTORY' as const;
/** 모듈 히스토리 추가 */
const ADD_HISTORY = 'module/history/ADD_HISTORY' as const;
/** 모듈 히스토리 삭제 */
const REMOVE_HISTORY = 'module/history/REMOVE_HISTORY' as const;
/** 액티브 모듈 */
const ACTIVE_HISTORY = 'module/history/ACTIVE_HISTORY' as const;

/**
 * 액션
 * @param history
 */
export const updateAllHistorys = (historys: any) => ({
  type: UPDATE_ALL_HISTORY,
  payload: historys,
});
export const addHistory = (history: HistoryProps) => ({
  type: ADD_HISTORY,
  payload: history,
});
export const removeHistory = (scenarioId: string) => ({
  type: REMOVE_HISTORY,
  payload: scenarioId,
});
export const activeHistory = (history: any) => ({
  type: ACTIVE_HISTORY,
  payload: history,
});

/**
 * 액션 타입 그룹
 */
type HistorysAction =
  | ReturnType<typeof updateAllHistorys>
  | ReturnType<typeof addHistory>
  | ReturnType<typeof removeHistory>
  | ReturnType<typeof activeHistory>;

/**
 * 초기값 타입
 */
interface HistoryState {
  /** 모듈 리스트 */
  lists: HistoryProps[];
  /** 액티브 모듈 아이디 */
  activeModuleId: {
    scenarioId: string | undefined;
    blockType: string | undefined;
  };
}

/**
 * 초기값
 */
const initialState: HistoryState = {
  /** 모듈 리스트 */
  lists: [],
  /** 액티브 모듈 */
  activeModuleId: {
    scenarioId: undefined,
    blockType: undefined,
  },
};

function moduleHistory(state: HistoryState = initialState, action: HistorysAction) {
  switch (action.type) {
    /**
     * 전체 모듈 히스토리 리스트 업데이트
     */
    case UPDATE_ALL_HISTORY:
      return {
        ...state,
        lists: action.payload.map((item: HistoryProps) => {
          return {
            name: item.name,
            botId: item.botId,
            scenarioId: item.scenarioId,
            blockType: item.blockType,
          };
        }),
      };

    /**
     * 모듈 히스토리 추가
     */
    case ADD_HISTORY:
      const lists = state.lists.filter((list) => list.botId === action.payload.botId && list.scenarioId === action.payload.scenarioId);

      if (lists.length === 0) {
        return {
          ...state,
          lists: state.lists.concat(action.payload),
        };
      } else {
        return state;
      }

    case REMOVE_HISTORY:
      remove(state.lists, (list) => list.scenarioId === action.payload);
      return {
        ...state,
        lists: state.lists,
      };

    /**
     * 액티브 모듈
     */
    case ACTIVE_HISTORY:
      return {
        ...state,
        activeModuleId: action.payload,
      };
    default:
      return state;
  }
}

export default moduleHistory;
