import styled from 'styled-components';
import React from 'react';
import { MdArrowDropDown } from 'react-icons/md';
import { OptionTypeBase, components } from 'react-select';
import CreatableSelect from 'react-select/creatable';

/** 셀렉트 박스 컨테이너 */
const Container = styled.div``;

/** react-select 커스텀 스타일 */
const customStyles = {
  control: (styles: any, state: any) => ({
    ...styles,
    boxShadow: 'none',
    borderRadius: state.menuIsOpen ? '4px 4px 0 0' : '4px',
    minHeight: 33,
    height: '100%',
    borderColor: '#DDD',
    ':active': {
      border: '1px solid rgb(229,230,235)',
    },
  }),

  valueContainer: (styles: any, state: any) => ({
    ...styles,
    height: '100%',
    overflow: 'none',
  }),
  indicatorSeparator: (styles: any, state: any) => ({
    ...styles,
    display: 'none',
  }),
  dropdownIndicator: (styles: any, state: any) => ({
    ...styles,
    padding: '0 5px',
    fontSize: 21,
  }),
  clearIndicator: (styles: any, state: any) => ({
    ...styles,
    svg: {
      width: 15,
      height: 15,
    },
  }),
  menu: (styles: any, state: any) => ({
    ...styles,
    margin: '0 1px 1px',
    borderRadius: '0 0 4px 4px',
    boxShadow: '0 0 0 1px rgb(55, 125, 255)',
    width: 'calc(100% - 2px)',
    padding: 0,
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    color: state.isDisabled ? '#989898' : 'var(--black)',
    backgroundColor: state.isDisabled ? '#f2f2f2' : '#fff',
    cursor: state.isDisabled ? 'not-allowed' : 'default',
    padding: '0 0 0 15px',
    fontSize: 13,
    height: 30,
    lineHeight: '30px',
    ':hover': {
      backgroundColor: 'rgb(233, 241, 254)',
    },
    overflow: state.data.isOverflow ? 'none' : 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  noOptionsMessage: (styles: any, state: any) => ({
    ...styles,
    padding: 0,
  }),
  multiValue: (styles: any, state: any) => ({
    ...styles,
    backgroundColor: '#e9f1fe',
    borderRadius: '8px',
  }),
  multiValueLabel: (styles: any, state: any) => ({
    ...styles,
    color: '#6e9ffe',
  }),
  multiValueRemove: (styles: any, state: any) => ({
    ...styles,
    color: '#6e9ffe',
    paddingTop: '1px',
    ':hover': {
      backgroundColor: '#ffe8ea',
      color: '#ff3b3b',
      cursor: 'pointer',
      borderRadius: '8px',
      paddingTop: '1px',
    },
  }),
};

/** Arrow 아이콘 */
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <MdArrowDropDown />
    </components.DropdownIndicator>
  );
};

const Input = (props: any) => {
  return (
    <>
      {props.selectProps.customInput ? (
        props.selectProps.customInput === 'under' ? (
          <div style={{ height: '100%' }}>
            <components.Input {...props} placeholder="+태그" className="customInput" />
          </div>
        ) : (
          <div style={{ display: 'none' }} />
        )
      ) : (
        <components.Input {...props} />
      )}
    </>
  );
};

const SelectWrapper: React.FC<OptionTypeBase> = (options) => {
  const Menu = (props: any) => {
    const max = options.maxItems || 1000000;
    const optionSelectedLength = props.getValue().length || 0;
    return (
      <components.Menu {...props}>
        {optionSelectedLength < max ? (
          props.children
        ) : (
          <div style={{ color: 'red', margin: 15 }}>{max}개 이상 추가 불가 합니다.</div>
        )}
      </components.Menu>
    );
  };

  const handleKeyDown = (e: any, options: any) => {
    if (options.value.length >= (options.maxItems || 1000000)) {
      switch (e.key) {
        case 'Enter':
        case 'Tab':
        case ' ':
          e.preventDefault();
      }
    }
  };

  return (
    <Container>
      <CreatableSelect
        {...options}
        styles={options.style ? options.style : customStyles}
        closeMenuOnSelect={false}
        components={{ DropdownIndicator, Menu, Input }}
        isMulti
        backspaceRemovesValue={options.backspace}
        onChange={options.onChange}
        isDisabled={options.disabled}
        onKeyDown={(e: any) => handleKeyDown(e, options)}
        formatCreateLabel={(label: any) => `"${label}" 추가`}
      />
    </Container>
  );
};

export default React.memo(SelectWrapper);
