/**
 * 버튼 디자이너 스토어
 * @version : 1.0
 * @author  : UX (gilhyun@gmail.com)
 */

import { ButtonDesignerProps } from '../../constants/Types';

/** 업데이트 */
const UPDATE_STORE = 'buttondesigner/UPDATE_STORE' as const;
/** 액티브 필드 */
const ACTIVE_Field = 'buttondesigner/ACTIVE_Field' as const;
/** 업데이트 스킨 */
const UPDATE_SKIN = 'buttondesigner/UPDATE_SKIN' as const;

/** 업데이트 액션 */
export const updateStore = (data: ButtonDesignerProps) => ({
  type: UPDATE_STORE,
  payload: data,
});
/** 액티브 액션 */
export const updateActive = (groupName: string, fieldName: string) => ({
  type: ACTIVE_Field,
  payload: {
    groupName: groupName,
    fieldName: fieldName,
  },
});
/** 업데이트 스킨 */
export const updateSkin = (fieldName: string, value: string) => ({
  type: UPDATE_SKIN,
  payload: {
    fieldName: fieldName,
    value: value,
  },
});

/**
 * 액션 타입 그룹
 */
type ButtonDesignerAction = ReturnType<typeof updateStore> | ReturnType<typeof updateActive> | ReturnType<typeof updateSkin>;

/**
 * 초기값
 */
const initialState: ButtonDesignerProps = {
  activeField: {
    groupNmae: '',
    fieldName: '',
  },
  skin: {
    colors: {
      light: {
        backgroundColor: '#fff',
        color: '#000',
      },
      dark: {
        backgroundColor: '#2e2e35',
        color: '#fff',
      },
    },
    color: 'light',
    textAlign: 'left',
  },
  data: {
    header: {
      CompanyNmae: {
        isEditable: true,
        html: `COMPANY`,
        use: true,
      },
      HomeTitle: {
        isEditable: true,
        html: `👋
안녕하세요, 무엇을 도와드릴까요?`,
        use: true,
      },
    },
    start: {
      StartTitle: {
        isEditable: true,
        html: `궁금하신점이 있으신가요?`,
        use: true,
      },
      StartDesc: {
        isEditable: true,
        html: `지금 바로 채팅상담을 시작해보세요`,
        use: true,
      },
      StartButton: {
        isEditable: false,
        html: `👉🏻채팅 상담 시작하기`,
        use: true,
      },
    },
    channel: {
      ChannelTitle: {
        isEditable: true,
        html: `이용중인 메신져로 상담받고 싶다면?`,
        use: true,
      },
      Channels: {
        isEditable: false,
        html: ``,
        use: true,
      },
    },
    ars: {
      ArsTitle: {
        isEditable: true,
        html: `상담원과 통화하기 (대기 36)`,
        use: true,
      },
      Ars: {
        isEditable: true,
        html: `📞 1666-5263`,
        use: true,
      },
    },
    faq: {
      FaqTitle: {
        isEditable: true,
        html: `자주 묻는질문`,
        use: true,
      },
      FaqList: {
        isEditable: false,
        html: ``,
        use: true,
      },
    },
  },
};

function buttonDesigner(state: ButtonDesignerProps = initialState, action: ButtonDesignerAction) {
  switch (action.type) {
    case UPDATE_STORE:
      return action.payload;
    case UPDATE_SKIN:
      return {
        ...state,
        skin: {
          ...state.skin,
          [action.payload.fieldName]: action.payload.value,
        },
      };
    case ACTIVE_Field:
      return {
        ...state,
        activeField: {
          groupName: action.payload.groupName,
          fieldName: action.payload.fieldName,
        },
      };
    default:
      return state;
  }
}

export default buttonDesigner;
