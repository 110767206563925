/* eslint-disable @typescript-eslint/no-unused-expressions */
import styled from 'styled-components';
import * as React from 'react';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

const createSliderWithTooltip = Slider.createSliderWithTooltip;
const DaySlider = createSliderWithTooltip(Slider);

const Container = styled.div<{ color?: any; zoom?: any; tooltipDisable?: boolean }>`
  width: 100%;
  margin: 10px;
  .rc-slider-rail {
    height: 5px;
  }
  .rc-slider-disabled {
    background-color: #fff;
    .rc-slider-track {
      display: none;
    }
    .rc-slider-handle {
      display: none;
    }
    .rc-slider-dot {
      border-color: #e9e9e9;
    }
  }
  .rc-slider-track {
    background-color: #b3c9ff;
    height: 8px;
    top: 3px;
  }
  .rc-slider-handle {
    border: solid 4px ${(props) => (props.color.activeHandle ? props.color.activeHandle : 'rgb(89, 136, 254)')};
    width: 15px;
    height: 15px;
    background-color: ${(props) => (props.color.activeHandle ? props.color.activeHandle : '#fff')};
  }
  .rc-slider-dot-active {
    border-color: #b3c9ff;
  }
  .rc-slider-handle:hover {
    border-color: ${(props) => (props.color.activeHandle ? props.color.activeHandle : '#5988fe')};
    top: 2px;
    width: 20px;
    height: 20px;
  }
  .rc-slider-handle:active {
    border-color: ${(props) => (props.color.activeHandle ? props.color.activeHandle : '#5988fe')};
    box-shadow: 0 0 5px ${(props) => (props.color.active ? props.color.active : 'blue')};
    cursor: grabbing;
  }
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px #d6e1ff;
  }
  .rc-slider-handle-click-focused:focus {
    border-color: ${(props) => (props.color.activeHandle ? props.color.activeHandle : '#5988fe')};
  }
  .rc-slider-tooltip-placement-top {
    padding: 0;
  }
  .rc-slider-tooltip-inner {
    background-color: #fff;
    padding: 2px;
    min-width: 16px;
    height: 16px;
    box-shadow: 0 0 0px #fff;
    color: var(--black);
    font-size: 12px;
    font-weight: bold;
    display: ${(props) => (props.color.noTooltip ? 'none' : 'block')};
  }
  .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
    border-top-color: #fff;
    display: none;
  }

  .tooltip-0 {
    margin-right: 40px;
    font-size: ${(props) => (props.zoom === 'tooltip-0' ? '13px' : '13px')};
  }

  .tooltip-1 {
    margin-left: 40px;
    font-size: ${(props) => (props.zoom === 'tooltip-1' ? '13px' : '13px')};
  }

  .rc-slider-mark-text-active {
    font-size: 12px;
    color: #767676;
  }

  .rc-slider-mark-text {
    font-size: 12px;
    color: #d1d1d1;
    min-width: ${(props) => props.color.minWidth && props.color.minWidth};
  }
`;

interface RangeDayProps {
  min: number;
  max: number;
  value: number;
  step: number;
  disabled?: boolean;
  alldayCheck?: boolean;
  marks?: any;
  included?: boolean;
  color: any;
  onChange: (e: any) => void;
  tooltipFormat?: (e: number) => string;
}

const RangeDay: React.FC<RangeDayProps> = (props) => {
  const { min, max, value, step, disabled, marks, included, color, alldayCheck, onChange, tooltipFormat } = props;
  const [tooltips, setTooltips] = React.useState<Element[]>([]);
  const [zoom, setZoom] = React.useState<string>('');
  const ref = React.useRef();
  React.useEffect(() => {
    const elems = (ref.current as unknown as HTMLElement).getElementsByClassName('rc-slider-handle');
    Array.from(elems).forEach((elem) => {
      elem.addEventListener('mouseenter', (event: any) => {
        setZoom(event.target.classList.contains('rc-slider-handle-1') ? 'tooltip-0' : 'tooltip-1');
      });
      elem.addEventListener('mouseleave', (event: any) => {
        setZoom('');
      });
    });
  }, []);

  React.useEffect(() => {
    tooltips.forEach((tooltip: Element, idx: number) => {
      tooltip.classList.add(`tooltip-${idx}`);
    });
  }, [tooltips]);

  return (
    <Container color={color} as={Container} ref={ref} zoom={zoom}>
      <DaySlider
        min={min}
        max={max}
        value={value}
        step={step}
        disabled={disabled}
        included={!included ? included : true}
        onChange={onChange}
        marks={marks}
        tipProps={{ visible: disabled ? false : true }}
        tipFormatter={(value: number) => (tooltipFormat ? tooltipFormat(value) : value)}
      />
    </Container>
  );
};

export default React.memo(RangeDay);
