import styled from 'styled-components';

/** 블럭 에디터 타이틀 */
const BlockEditorTitle = styled.div`
  width: 100%;
  margin-bottom: 10px;

  .required {
    color: #ff0000;
  }

  .optional {
    color: #de9e00;
    font-size: 15px;
  }
`;

export default BlockEditorTitle;
