import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { State } from '../../Interfaces/Toast';

const toastErrorStatus = ({ status }: Pick<State, 'status'>) => {
  switch (status) {
    case 'primary':
      return css`
        color: var(--white);
        background-color: var(--red);
      `;
    case 'secondary':
      return css`
        color: var(--red);
        background-color: var(--red-fill);
      `;
  }
};
const toastSuccessStatus = ({ status }: Pick<State, 'status'>) => {
  switch (status) {
    case 'primary':
      return css`
        color: var(--white);
        background-color: var(--green);
      `;
    case 'secondary':
      return css`
        color: var(--green);
        background-color: var(--green-fill);
      `;
  }
};
const toastNormalStatus = ({ status }: Pick<State, 'status'>) => {
  switch (status) {
    case 'primary':
      return css`
        color: var(--white);
        background-color: var(--primary-black);
      `;
    case 'secondary':
      return css`
        color: var(--primary-black);
        background-color: var(--tertiary-fill);
      `;
  }
};

const toastInfoStatus = ({ status }: Pick<State, 'status'>) => {
  switch (status) {
    case 'primary':
      return css`
        color: var(--white);
        background-color: var(--blue);
      `;
    case 'secondary':
      return css`
        color: var(--blue);
        background-color: var(--blue-fill);
      `;
  }
};
const toastType = ({ type, status }: State) => {
  switch (type) {
    case 'error':
      return toastErrorStatus({ status });
    case 'success':
      return toastSuccessStatus({ status });
    case 'normal':
      return toastNormalStatus({ status });
    case 'info':
      return toastInfoStatus({ status });
  }
};

export const BaseToast = styled.div`
  padding: 12px 16px;
  border-radius: var(--r-8);
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.23px;
  line-height: 20px;
  ${toastType}
`;
