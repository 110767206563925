interface ErrorData {
  _id: string;
  name: string;
  error: string[];
  warn: string[];
}

export interface BlockError extends ErrorData {
}

export interface ScenarioError extends Omit<ErrorData, 'warn'> {
  scenarioType: string;
  templateType: string;
  sortOrder: number;
  block: BlockError[];
}

export interface BotError extends Omit<ErrorData, 'warn'> {
  scenario: ScenarioError[];
}

export interface EditorErrorTabCount {
  setting: number;
  error: number;
  warn: number;
}

interface EditorErrorState {
  /**
   * 상단 버튼 display 값
   */
  btnShow: boolean;
  /**
   * 플로팅 레이어 display 값
   */
  listShow: boolean;
  /**
   * 플로팅 레이어 오류 리스트
   */
  editorError: BotError | null;
  /**
   * 봇을 제외한 에러 및 경고 카운트
   */
  tab: {
    useTab: string;
    count: EditorErrorTabCount;
  };
}

// 액션 타입
const UPDATE_BTN_SHOW = 'editorError/UPDATE_BTN_SHOW' as const;
const UPDATE_LIST_SHOW = 'editorError/UPDATE_LIST_SHOW' as const;
const UPDATE_ERROR_LIST = 'editorError/UPDATE_ERROR_LIST' as const;
const UPDATE_ERROR_TAB_COUNT = 'editorError/UPDATE_ERROR_TAB_COUNT' as const;
const UPDATE_RESET = 'editorError/UPDATE_RESET' as const;
const UPDATE_EDITOR_TAB = 'editorError/UPDATE_EDITOR_TAB' as const;

/**
 * 저장소 초기화
 * @returns {Object}
 */
export const reset = () => ({
  type: UPDATE_RESET,
});

/**
 * 에디터 에러 리스트 버튼의 display 상태값 변경함수
 * @param {boolean} value - On/Off.
 * @returns {Object}
 */
export const updateBtnShow = (value: boolean) => ({
  type: UPDATE_BTN_SHOW,
  payload: value,
});

/**
 * 에디터 에러 리스트 display 상태값 변경함수
 * @param {boolean} value - On/Off.
 * @returns {Object}
 */
export const updateListShow = (value: boolean) => ({
  type: UPDATE_LIST_SHOW,
  payload: value,
});

/**
 * 에디터 에러 리스트의 데이터 변경함수
 * @param {BotError[]} list - 에러리스트 데이터.
 * @returns {Object}
 */
export const updateEditorError = (data: BotError) => ({
  type: UPDATE_ERROR_LIST,
  payload: data,
});

/**
 * 에디터 에러 리스트의 tab 변경함수
 * @param {EditorErrorTabCount} count - 에러리스트 tab Count.
 * @returns {Object}
 */
export const updateEditorErrorTab = (count: EditorErrorTabCount) => ({
  type: UPDATE_ERROR_TAB_COUNT,
  payload: count,
});

export const updateEditorTab = (tab: string) => ({
  type: UPDATE_EDITOR_TAB,
  payload: tab,
});

/**
 * 액션 타입 그룹
 */
type EditorErrorAction =
  | ReturnType<typeof reset>
  | ReturnType<typeof updateBtnShow>
  | ReturnType<typeof updateListShow>
  | ReturnType<typeof updateEditorError>
  | ReturnType<typeof updateEditorErrorTab>
  | ReturnType<typeof updateEditorTab>;

const initialState: EditorErrorState = {
  btnShow: false,
  listShow: false,
  editorError: null,
  tab: {
    useTab: 'error',
    count: {
      setting: 0,
      error: 0,
      warn: 0,
    },
  },
};

export const calculateEditorErrorTabCount = (botError: BotError) => {
  let errorCount = 0;
  let warnCount = 0;

  for (const scenarioError of botError.scenario) {
    errorCount += scenarioError.error.length;

    for (const blockError of scenarioError.block) {
      errorCount += blockError.error.length;
      warnCount += blockError.warn.length;
    }
  }

  return {
    setting: botError.error.length,
    error: errorCount,
    warn: warnCount,
  };
};

function editorError(state: EditorErrorState = initialState, action: EditorErrorAction) {
  switch (action.type) {
    case UPDATE_RESET:
      return {
        btnShow: false,
        listShow: false,
        editorError: null,
        tab: {
          useTab: 'error',
          count: {
            setting: 0,
            error: 0,
            warn: 0,
          },
        },
      };
    case UPDATE_BTN_SHOW:
      return {
        ...state,
        btnShow: action.payload,
      };
    case UPDATE_LIST_SHOW:
      return {
        ...state,
        listShow: action.payload,
      };
    case UPDATE_ERROR_LIST:
      return {
        ...state,
        editorError: action.payload,
      };
    case UPDATE_ERROR_TAB_COUNT:
      return {
        ...state,
        tab: {
          ...state.tab,
          count: action.payload,
        },
      };
    case UPDATE_EDITOR_TAB:
      return {
        ...state,
        tab: {
          ...state.tab,
          useTab: action.payload,
        },
      };
    default:
      return state;
  }
}

export default editorError;
