import React from 'react';
import { BaseCol } from '../../Styles/Col';
import { Props } from '../../Interfaces/Col';

const Col = ({ children, span, size, className }: Props) => {
  return (
    <BaseCol className={className} span={span} size={size}>
      {children}
    </BaseCol>
  );
};

export default Col;
